import { makeAutoObservable } from "mobx";
import { get, post } from "../axios/axios";
import { login } from "../Official/components/Login/Login";
import qs from "qs";
import { message } from "antd";
class Login {
  userInfo = {};
  hasLogin = false;
  notLoginInfo = {};
  inviteCode = "";
  hasOpen = false;
  constructor() {
    makeAutoObservable(this);
  }

  open() {
    if (this.hasOpen) return;
    this.hasOpen = true;

    return new Promise((resolve, reject) => {
      login()
        .then((res) => {
          this.setLoginInfo(res);
          this.getUserInfoFromServer();
          console.log("login success", res);
          window.location.reload();
        })
        .catch((err) => {
          console.log("login fail", err);
        })
        .finally(() => {
          this.hasOpen = false;
        });
    });
  }

  checkLogin() {
    return new Promise((resolve, reject) => {
      if (!this.hasLogin) {
        return this.open();
      }
    });
  }

  async getUserInfoFromServer(url = "/member/info", options = {}) {
    try {
      const { data } = await get(url, {}, { checkAuth: true, ...options });
      this.hasLogin = true;
      if (!this.userInfo.user_info) {
        this.userInfo.user_info = {};
      }
      const info = data || {};

      this.userInfo.user_info = { ...this.userInfo.user_info, ...info };
      this.setLoginInfo(this.userInfo);
    } catch (err) {
      this.hasLogin = false;
      this.getMemberNotLoginInfo();
    }
  }

  setLoginInfo(res) {
    this.userInfo = res || {};
    window.localStorage.setItem("userInfo", JSON.stringify(res));
  }

  getUserInfoFromStorage() {
    const info = window.localStorage.getItem("userInfo");
    if (info) {
      this.userInfo = JSON.parse(info);
    }
  }

  getHeaders() {
    this.getUserInfoFromStorage();
    if (this.userInfo) {
      return "Bearer " + this.userInfo.access_token;
    }
    return null;
  }
  async getMemberNotLoginInfo() {
    try {
      const { data } = await get("/member/info2", { slient: true });
      this.notLoginInfo = data;
    } catch (err) {
      console.error(err);
    }
  }
}

const loginInstance = new Login();
export { loginInstance };
