import React, { useEffect, useRef, useState } from 'react'
import { Button, message, Modal, Upload } from 'antd'
import {
  CheckOutlined,
  ExclamationCircleFilled,
  UploadOutlined
} from '@ant-design/icons'
import pptUpload from '../../../assets/pptUpload.svg'
import pptNotUpload from '../../../assets/pptNotUpload.svg'
import { baseURL, get } from '../../../axios/axios'
import { useNavigate } from 'react-router-dom'
import styles from '../../PPTPrePare/index.module.scss'

const PPTUploadModal = ({ visible, onCancel, onUploadWord, onWithoutWord }) => {
  const nav = useNavigate()
  const uid = useRef('')
  const [file, setFile] = useState()
  const [btnLoading, setBtnLoading] = useState(false)

  const { Dragger } = Upload

  useEffect(() => {
    getUid()
  }, [])

  const props = {
    accept: '.pdf,.doc,.docx',
    name: 'file',
    multiple: false,
    maxCount: 1,
    action: baseURL + '/ai/file/upload',
    data () {
      return {
        uid: uid.current
      }
    },
    onChange (info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
        setFile(info.file)
      }
      if (status === 'done') {
      } else if (status === 'error') {
        message.error(`${info.file.response.msg}`)
      }
    },
    beforeUpload () {
      return new Promise((resolve, reject) => {
        Modal.confirm({
          title: '确认上传文档',
          icon: <ExclamationCircleFilled/>,
          okButtonProps: {
            className: 'bg-[#00CE72]  text-white'
          },
          content:
            '解析此文档可能需要访问您的文档内容，我们将严格保障每一个用户文件的安全性，绝不会泄露、篡改或滥用您的文件，您确定要授权此操作吗？',
          onOk () {
            resolve()
          },
          onCancel () {
            resolve(Upload.LIST_IGNORE)
          }
        })
      })
    },
    onDrop (e) {
      console.log('Dropped files', e.dataTransfer.files)
    }
  }

  async function getUid () {
    const res = await get('/ai/ai/ppt/cre/ppt', {}, { checkAuth: true })

    uid.current = res.data
  }

  async function toCreate (title = '') {
    setBtnLoading(true)
    if (!uid.current) {
      await getUid().finally(() => {
        setBtnLoading(false)
      })
    }
    nav(`/ppt?id=${uid.current}&title=${title}`)
  }

  return (
    <Modal
      title={<div className={'text-[#3d3d3d] text-[18px]'}>生成PPT</div>}
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={654}
      closeIcon={<span
        className="text-gray-400 hover:text-gray-600 text-[24px]">&times;</span>}
      className="max-w-2xl"
    >
      {
        !file ? (
          <div className="mt-4 flex flex-col gap-4">
            {/* 上传Word选项 */}
              <Dragger {...props} className={styles.uploadDoc}>
                <div
                  className="p-3 border border-[#00CE72] rounded-[12px] bg-[#E5FAF1] cursor-pointer transition-colors duration-200"
                >
                  <div className="flex gap-2 ">

                    <div className={'h-24'}>
                      <img src={pptNotUpload} alt=""/>
                    </div>
                    <div className="flex-1">
                      <h3 className="text-start text-lg font-medium mb-1">上传word</h3>
                      <p className="text-[#999] text-sm leading-relaxed">
                        如果您正在准备开题答辩，您可以上传包含您研究计划和初步内容的开题报告Word文档；
                        如果您处于论文的结题阶段，您可以上传您的完整论文Word文档。我们的系统将根据您上传的文档内容，自动为您生成PPT,助您答辩更加精彩。
                      </p>
                    </div>
                  </div>
                </div>
              </Dragger>


            {/* 不上传Word选项 */}
            <div
              onClick={() => toCreate()}
              className="p-3 rounded-[12px] bg-[#F5F8F9]   cursor-pointer transition-colors duration-200"
            >
              <div className="flex gap-2">
                <div className={'h-24'}>
                  <img src={pptUpload} alt=""/>
                </div>
                <div className="flex-1">
                  <h3 className="text-lg font-medium mb-1">不上传word</h3>
                  <p className="text-[#999] text-sm leading-relaxed">
                    无论您是急需一份答辩文稿，还是希望快速搭建一个PPT的基本框架，这里都是理想之选。
                    我们的系统会提供多种预设的模板和布局，让您能够迅速选取并定制，以适应各种场合和主题。
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <div className={styles.uploadDocTitle}>{file.name}</div>
              <div className={styles.uploadDocDesc}>
                <CheckOutlined
                  style={{ marginRight: '4px', color: '#F7941D' }}
                />
                <span className={styles.mr10}>上传成功</span>
                <span className={styles.mr10}>
                    文件大小：{Math.floor(file.size / 1024)}KB
                  </span>
              </div>
              <div className={styles.uploadFooter}>
                <Button
                  loading={btnLoading}
                  type="primary"
                  className={styles.mr20}
                  onClick={() => toCreate(file.name)}
                >
                  确认提交
                </Button>
                <Button
                  onClick={() => {
                    setFile(null)
                  }}
                >
                  重新上传
                </Button>
              </div>
            </div>
          </>
        )
      }
    </Modal>
  )
}

export default PPTUploadModal
