import { Button, Upload, message, Modal, Table } from "antd";
import {
  LeftOutlined,
  ExclamationCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import styles from "./index.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { get, baseURL } from "../../axios/axios";
const { Dragger } = Upload;
const { confirm } = Modal;

export function PPTPrepare() {
  const nav = useNavigate();
  const uid = useRef("");
  const [file, setFile] = useState();

  useEffect(() => {
    getUid();
  }, []);

  const props = {
    accept: ".pdf,.doc,.docx",
    name: "file",
    multiple: false,
    maxCount: 1,
    action: baseURL + "/ai/file/upload",
    data() {
      return {
        uid: uid.current,
      };
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
        setFile(info.file);
      }
      if (status === "done") {
      } else if (status === "error") {
        message.error(`${info.file.response.msg}`);
      }
    },
    beforeUpload() {
      return new Promise((resolve, reject) => {
        confirm({
          className: styles.uploadConfirm,
          title: "确认上传文档",
          icon: <ExclamationCircleFilled />,
          content:
            "解析此文档可能需要访问您的文档内容，我们将严格保障每一个用户文件的安全性，绝不会泄露、篡改或滥用您的文件，您确定要授权此操作吗？",
          onOk() {
            resolve();
          },
          onCancel() {
            resolve(Upload.LIST_IGNORE);
          },
        });
      });
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  async function getUid() {
    const res = await get("/ai/ai/ppt/cre/ppt", {}, { checkAuth: true });

    uid.current = res.data;
  }

  async function toCreate(title = "") {
    if (!uid.current) {
      await getUid();
    }
    nav(`/ppt?id=${uid.current}&title=${title}`);
  }
  return (
    <div className={styles.ppt}>
      <div className={styles.header}>
        <div
          onClick={() => {
            nav("/", { replace: true });
          }}
        >
          青秧灵感
        </div>
      </div>
      <div className={styles.pptBox}>
        <div className={styles.title}>五分钟快速生成答辩PPT</div>
        <div className={styles.card}>
          {!file ? (
            <>
              <div>
                <div className={styles.cardTitle}>上传Word</div>
                <div className={styles.cardDesc}>
                  <div>
                    如果您正在准备开题答辩，您可以上传包含您研究计划和初步内容的开题报告Word文档;
                  </div>
                  <div>
                    如果您处于论文的结题阶段，您可以上传您的完整论文Word文档。
                  </div>
                  <div>
                    我们的系统将根据您上传的文档内容，自动为您生成PPT，助您答辩更加精彩。
                  </div>
                </div>
              </div>
              <Dragger {...props} className={styles.uploadDoc}></Dragger>
            </>
          ) : (
            <>
              <div>
                <div className={styles.uploadDocTitle}>{file.name}</div>
                <div className={styles.uploadDocDesc}>
                  <CheckOutlined
                    style={{ marginRight: "4px", color: "#F7941D" }}
                  />
                  <span className={styles.mr10}>上传成功</span>
                  <span className={styles.mr10}>
                    文件大小：{Math.floor(file.size / 1024)}KB
                  </span>
                </div>
                <div className={styles.uploadFooter}>
                  <Button
                    type="primary"
                    className={styles.mr20}
                    onClick={() => toCreate(file.name)}
                  >
                    确认提交
                  </Button>
                  <Button
                    onClick={() => {
                      setFile(null);
                    }}
                  >
                    重新上传
                  </Button>
                </div>
              </div>
            </>
          )}
          {/* <img src={yellow} className={styles.cardImg} /> */}
        </div>

        <div
          className={styles.card}
          style={{
            background:
              "linear-gradient(152deg,rgba(85,195,106,.1) 0%,rgba(242,255,244,.1) 100%)",
          }}
          onClick={() => toCreate()}
        >
          <div>
            <div className={styles.cardTitle}>不上传Word</div>
            <div className={styles.cardDesc}>
              <div>
                无论您是急需一份答辩文稿，还是希望快速搭建一个PPT的基本框架，这里都是理想之选。
              </div>
              <div>
                我们的系统会提供多种预设的模板和布局，让您能够迅速选取并定制，以适应各种场合和主题。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
