import { Button, Form, Input, Select } from "antd";
import { useState } from "react";

export function RegisterForm() {
  const [step, setStep] = useState(2);
  return (
    <>
      {step === 1 ? (
        <Form className="custom-form">
          <Form.Item
            label=""
            name="tel"
            rules={[{ required: true, message: "请输入您的邮箱" }]}
          >
            <Input
              className="custom-input"
              placeholder="请输入邮箱"
              suffix={<span></span>}
            />
          </Form.Item>
          <Form.Item
            label=""
            name="code"
            rules={[{ required: true, message: "请输入验证码" }]}
          >
            <Input.Password
              className="custom-input"
              placeholder="请输入密码"
              type="password"
              suffix={<span></span>}
            />
          </Form.Item>
          <Button className="custom-btn" disabled={true}>
            继续
          </Button>
        </Form>
      ) : (
        <Form className="custom-form">
          <div className="back">返回</div>
          <Form.Item
            label=""
            name="code"
            rules={[{ required: true, message: "请输入您的密码" }]}
          >
            <Input.Password
              className="custom-input"
              placeholder="请输入您的密码"
              type="password"
              suffix={<span></span>}
            />
          </Form.Item>
          <Form.Item
            label=""
            name="code"
            rules={[{ required: true, message: "请输入您的密码" }]}
          >
            <Input.Password
              className="custom-input"
              placeholder="请再次输入您的密码"
              type="password"
              suffix={<span></span>}
            />
          </Form.Item>
          <Button type="primary" className="custom-btn">
            进入MoDe
          </Button>
        </Form>
      )}
    </>
  );
}
