import { Avatar, Button, Dropdown, Layout, Menu, Progress } from "antd";
import styles from "./UserDropdown.module.scss";
import {
  Link,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { loginInstance } from "../Service/login";
import avatarurl from "../assets/avatr.png";
import writeIcon from "../assets/write.png";
export const UserDropdown = observer(() => {
  const [qs] = useSearchParams();
  const { userInfo, hasLogin, notLoginInfo } = loginInstance;
  const userNames = userInfo.user_info || {};
  const invQuey = qs.get("code") ? "?code=" + qs.get("code") : "";
  const leftWords = userNames.usedWords;
  const usedWords = userNames.totalWords - Math.max(0, leftWords);
  const iconProgress = Math.min((usedWords / userNames.totalWords) * 100, 100);

  const logout = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  const login = () => {
    loginInstance.open();
  };

  const notLoginLeftWords = notLoginInfo.usedWords;
  const notLoginUsedWords =
    notLoginInfo.totalWords - Math.max(0, notLoginLeftWords);
  const notLoginIconProgress = Math.min(
    (notLoginUsedWords / notLoginInfo.totalWords) * 100,
    100
  );
  if (!hasLogin) {
    return (
      <div className={styles.userInfo} onClick={login}>
        <Avatar size={55} className={styles.av}>
          M
        </Avatar>
        <div className={styles.info}>
          <div className={styles.name}>请先登录</div>
          <img
            src={writeIcon}
            className={styles.writeIcon}
            style={{
              marginLeft:
                notLoginIconProgress < 100
                  ? notLoginIconProgress + "%"
                  : `calc(100% - 12px)`,
            }}
          />

          <Progress
            percent={notLoginIconProgress}
            showInfo={false}
            className={styles.progress}
          />
          <div className={styles.count}>
            {notLoginUsedWords}/{notLoginInfo.totalWords}字
          </div>
        </div>
      </div>
    );
  }
  return (
    <Dropdown
      placement="right"
      menu={{
        items: [
          {
            key: "account",
            label: (
              <Link to={"/dashboard/userCenter" + invQuey}>
                <div className={styles.it}>账号</div>
              </Link>
            ),
          },
          {
            key: "signout",
            label: (
              <div className={styles.it} onClick={logout}>
                退出登录
              </div>
            ),
          },
        ],
      }}
    >
      <div className={styles.userInfo}>
        <Avatar
          size={55}
          className={styles.av}
          src={<img src={avatarurl} alt="avatar" />}
        >
          F
        </Avatar>
        <div className={styles.info}>
          <div className={styles.name}>{userNames.nickName || "-"}</div>
          <img
            src={writeIcon}
            className={styles.writeIcon}
            style={{
              marginLeft:
                iconProgress < 100 ? iconProgress + "%" : `calc(100% - 12px)`,
            }}
          />
          <Progress
            percent={(usedWords / userNames.totalWords) * 100}
            showInfo={false}
            className={styles.progress}
          />
          <div className={styles.count}>
            {usedWords}/{userNames.totalWords}字
          </div>
        </div>
      </div>
    </Dropdown>
  );
});
