import styles from "./index.module.scss";

export function Agreement() {
  return (
    <div className={styles.agreement}>
      <div className={styles.agreementTitle}>青秧灵感服务条款</div>
      <div
        class="ql-editor notranslate ql-show"
        data-gramm="false"
        contenteditable="true"
        autocorrect="false"
        autocomplete="off"
        spellcheck="false"
      >
        <p
          ql-global-para="true"
          line="Svmd"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 1 条 基础约定
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="LSJx"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            1.1{" "}
          </span>
        </p>
        <p
          ql-global-para="true"
          line="cThR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            在使用本网站及服务之前，请您务必审慎阅读、充分理解本协议(如果您未满
            16 周岁，或已满 16 周岁未满 18
            周岁且不能以自己的劳动收入作为主要收入来源的，请在法定监护人的陪同下阅读本协议)，特别是“特别提示”条款(若有)、限制或免除责任条款、隐私保护、法律适用和争议解决条款(包括管辖条款)，该类条款将以加粗加黑和(或)加下划线等显示形式提示您注意，请务必重点查阅。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="jjDJ"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            1.2
          </span>
        </p>
        <p
          ql-global-para="true"
          line="voPU"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            若您不同意本协议，则您有充分且完全的权利退出使用本网站及服务，您通过网络页面点击注册和(或)实际使用本网站及服务的行为即视为您已阅读、理解并同意接受本协议。如果您对本协议有任何的疑问、投诉、意见和建议，欢迎您通过本协议所附联系方式与我们沟通反馈，我们十分乐意为您解答。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Lp6S"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            1.3
          </span>
        </p>
        <p
          ql-global-para="true"
          line="5YoZ"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            协议范围：考虑到互联网服务的不确定性以及产品频繁迭代更新，为了更全面的界定您与我们之间的权利义务，本协议包括我们根据法律法规规定以及本协议制定的其他政策、规则、公告声明等，如内容发生不一致或有矛盾，以专门适用于某个业务场景的协议条款为准。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="6CK7"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            1.4
          </span>
        </p>
        <p
          ql-global-para="true"
          line="20Pj"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            服务范围：我们可能通过不断丰富的功能界面向您提供本协议项下的网站服务，包括但不限于、PC
            端网站、微信公众号、小程序以及其他形式。具体以我们实时发布的服务功能界面范围为准。{" "}
          </span>
        </p>
        <p
          ql-global-para="true"
          line="rvHY"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            1.5
          </span>
        </p>
        <p
          ql-global-para="true"
          line="pdoV"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            修改与更新：我们有权遵照法律法规规定，在必要时修改本协议，更新后的协议条款将代替原来的协议，并在发布于本网站后正式生效，您可以在相关服务页面查阅最新版本的协议条款。本协议修改、变更后，如果您继续使用本网站及服务的，即视为您已接受修改后的协议。如果您不接受修改后的协议，则您有权利停止使用本网站及服务。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="kyf0"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 2 条 使用本网站及服务的前提
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="9iXV"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            2.1{" "}
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Ybbt"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            年龄限制：您向我们保证您在使用本网站及服务时已年满 18
            周岁，或虽未年满 18 周岁，但已年满16
            周岁并能以自己的劳动收入为主要生活来源。如果您不符合上述条件，您应和您的监护人一起阅读本协议以确保明确您有能力了解本协议内容,在监护人同意之前，您不能且不应使用本网站及服务。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="ECoG"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            2.2
          </span>
        </p>
        <p
          ql-global-para="true"
          line="TZWv"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            系统或数据库更新：为了保证本网站及服务的安全性和功能之一致性，我们有权不经向您特别通知而对网站系统或数据库进行更新，或者对网站部分功能进行改变、限制甚至终止。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="j0Ui"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            2.3
          </span>
        </p>
        <p
          ql-global-para="true"
          line="4Kt0"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            本网站许可范围：本网站授予用户一项个人的、不可转让及付费使用(除本协议另有声明外)的许可，以供您使用本网站及服务。用户不可以对本网站或者本网站运行过程中接收到的任何数据进行复制、更改、修改或创作任何衍生作品，
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Vwet"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入本网站和相关系统、未经授权反向工程本网站程序的全部或部分等。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="vqI5"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            2.4
          </span>
        </p>
        <p
          ql-global-para="true"
          line="1PtY"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            使用限制：您同意仅基于本协议许可目的使用本网站及服务。如果您对本网
            站及服务的使用或其他行为威胁我们提供本网站或其他系统的能力，我们有权采取一切合理步骤保护本网站和系统，当中可能包括暂停您访问本网站及服务。如您屡次违反限制，可能会导致本网站服务的终止。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="9o7H"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 3 条 本网站及服务内容
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="iBa4"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            3.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="rBUx"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            本网站提供的是针对用户提供的关键词进行文章创作的服务。具体请见本网站实时呈现的内容。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="sOhh"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            3.2
          </span>
        </p>
        <p
          ql-global-para="true"
          line="GtVD"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            style={{ color: "rgb(85, 85, 85)" }}
            ql-global="true"
          >
            本网站提供的文章生成，为依托本网站数据库进行的大数据分析、云计算等形成的结果。文章仅供用户参考，用户
          </span>
          <span
            class="ql-author-77709990"
            style={{
              color: "rgb(61, 61, 61)",
              "background-color": "rgba(255, 255, 255, 0.5)",
            }}
            ql-global="true"
          >
            不应将其用于代写或其他不诚信行为
          </span>
          <span
            class="ql-author-77709990 ql-size-12"
            style={{
              color: "rgb(61, 61, 61)",
              "background-color": "rgba(255, 255, 255, 0.5)",
            }}
            ql-global="true"
          >
            。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="VqAU"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            3.3
          </span>
        </p>
        <p
          ql-global-para="true"
          line="l8Ru"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            除本网站及服务特别注明外，任何通过使用本网站及服务而搜索链接到的第三方网页均系第三方制作或提供，您从该第三方网页上获得资讯及享用服务，系您与第三方服务建立的服务合同关系，由此而产生的争议纠纷或损失由您与第三方自行解决，我们对此不承担任何法律责任。{" "}
          </span>
        </p>
        <p
          ql-global-para="true"
          line="n65r"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            3.4
          </span>
        </p>
        <p
          ql-global-para="true"
          line="bHhu"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            服务购买和付款。本网站的文章生成服务属于付费内容(本网站预览部分除外)，您可根据自身所需服务支付相应的费用。您使用生成服务后，无正当理由本网站不接受任何形式的退款。包括但不限于:充值金额错误、充值后未下载文章、对生成内文章内容不准确等。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Wb5H"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            style={{
              color: "rgb(61, 61, 61)",
              "background-color": "rgba(255, 255, 255, 0.5)",
            }}
            ql-global="true"
          >
            预览部分涵盖
          </span>
          <span
            class="ql-author-77709990"
            style={{
              color: "rgb(61, 61, 61)",
              "background-color": "rgba(255, 255, 255, 0.5)",
            }}
            ql-global="true"
          >
            标题，文献信息，摘要，大纲，以及部分章节具体内容。
          </span>
        </p>
        <p
          style={{
            color: "rgb(61, 61, 61)",
            "background-color": "rgba(255, 255, 255, 0.5)",
          }}
        >
          生成的内容仅限于文字形式，该内容不涵盖数据集、图表、公式等非文字性质的信息。
        </p>
        <p
          ql-global-para="true"
          line="C7zv"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 4 条 用户行为规范
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="hkWd"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            4.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Co0V"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            您保证合理地使用本网站及服务，并接受本协议和我们适时制定并发布的其他政策、规则、公告声明。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="90qu"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            4.2
          </span>
        </p>
        <p
          ql-global-para="true"
          line="ogN7"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            行为禁止:您可在本协议约定的范围内使用本网站及服务，您不得利用本网站及服务从事以下行为:
            (1) 超出授权或恶意使用本网站及服务; (2)
            利用本网站及服务发表、传送、传播、储存危害国家安全、国家统一、社会稳定的内容，或具有侮辱诽谤、暴力等任何违反国家法律法规政策的内容，或者设置含有上述内容的用户名，发布、传送、传播违法广告信息、营销信息及垃圾信息等;
            (3)
            利用本网站侵害他人知识产权、肖像权、隐私权、名誉权、个人信息等合法权利或权益;
            (4)
            进行任何危害计算机网络安全的行为，包括但不限于:使用未经许可的数据或进入未经许可的服务器/账户;未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息;未经许可，探查、扫描、测试本网站系统、
            服务器弱点或其他实施破坏网络安全的行为;干涉、破坏本网站系统、服务器或网站的正常运行，故意传播恶意程序、病毒、木马、蠕虫以及其他破坏干扰正常网络信息服务的行为;伪造
            TCP/IP
            数据包名称或部分名称;利用本网站及服务上传任何病毒、木马、蠕虫等危害网络健康的内容;
            (5)
            对本网站及服务进行反向工程、反向编译或反向汇编或以其他方式企图发现本网站及服务的源代码和算法，未经许可修改、禁用软件的任何功能或创建基于软件的衍生作品。去除本网站及服务或文档上的任何所有权声明或标签，或将其他软件与本网站及服务合并;
            (6)
            进行任何破坏我们提供服务公平性或者其他影响应用正常秩序的行为，如使用外挂或者其他的作弊软件、利用
            BUG(又叫“漏洞”或者“缺陷”)来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、BUG
            公之于众; (7)
            从事其他法律法规、政策及公序良俗、社会公德禁止的行为以及侵犯其他个人、公司、社会团体、组织的合法权益的行为。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="WzU6"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            4.3
          </span>
        </p>
        <p
          ql-global-para="true"
          line="7vzC"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            信息内容规范:为了营造良好网络生态，保障公民、法人和其他组织的合法权益，维护国家安全和公共利益，我们将根据《中华人民共和国国家安全法》《中华人民共和国网络安全法》《互联网信息服务管理办法》等法律、行政法规，自觉践行社会主义核心价值观，营造清朗的网络空间，并开展的弘扬正能量、处置违法和不良信息等相关活动。本网站一旦发现您制作、复制、发布相应违法信息，
            本网站有权利不经通知您而立即删除。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="yPEc"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            4.3.2
          </span>
        </p>
        <p
          ql-global-para="true"
          line="kvCD"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            您不得制作、复制、发布含有下列内容的违法信息: (1)
            反对宪法所确定的基本原则的; (2)
            危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的; (3)
            损害国家荣誉和利益的; (4)
            歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的;
            (5) 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的; (6)
            煽动民族仇恨、民族歧视，破坏民族团结的; (7)
            破坏国家宗教政策，宣扬邪教和封建迷信的; (8)
            散布谣言，扰乱经济秩序和社会秩序的; (9)
            散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的; (10)
            侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的; (11)
            法律、行政法规禁止的其他内容。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="69S4"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            4.3.3
          </span>
        </p>
        <p
          ql-global-para="true"
          line="F26x"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            我们也将依法采取措施，防范和抵制制作、复制、发布含有下列内容的不良信息:
            (1) 使用夸张标题，内容与标题严重不符的; (2)
            炒作绯闻、丑闻、劣迹等的; (3) 不当评述自然灾害、重大事故等灾难的;
            (4) 带有性暗示、性挑逗等易使人产生性联想的; (5)
            展现血腥、惊悚、残忍等致人身心不适的; (6)
            煽动人群歧视、地域歧视等的; (7) 宣扬低俗、庸俗、媚俗内容的; (8)
            可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的;
            (9) 其他对网络生态造成不良影响的内容。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="W2nE"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            4.4
          </span>
        </p>
        <p
          ql-global-para="true"
          line="D3vb"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            信息内容的使用规范
          </span>
        </p>
        <p
          ql-global-para="true"
          line="kslE"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            4.4.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="ziWf"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            未经我们书面许可，您不得自行或授权、允许、协助任何第三人对本网站及服务中信息内容进行如下行为:
            (1) 复制、读取、采用本网站及服务的信息内容，用于任何形式的商业用途;
            (2)
            擅自编辑、整理、编排本网站及服务的信息内容后在本网站及服务的源页面以外的渠道进行展示;
            (3)
            采用不正当方式，自行或协助第三人对本网站及服务的信息内容产生流量、
            阅读量或交易引导、转移、劫持等不利影响。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="2hp9"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 5 条 个人信息保护与隐私政策
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="qDlH"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            5.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="vBtb"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            本网站及服务将会采取合理的措施保护您的个人信息与隐私，我们承诺，除非获得用户同意，我们不会收集、使用提供服务所必须以外的用户个人信息或者将信息用于提供服务之外的目的。{" "}
          </span>
        </p>
        <p
          ql-global-para="true"
          line="v3CD"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            5.2
          </span>
        </p>
        <p
          ql-global-para="true"
          line="He42"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            安全保护:
            我们将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息及隐私安全，以免遭受未经授权的访问、使用或披露。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="aRuR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            5.3
          </span>
        </p>
        <p
          ql-global-para="true"
          line="enFZ"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            信息使用与披露: 我们有权在遵守法律法规的前提下，以明示的方式获取、
            使用、储存您的个人信息。我们不会在未经您授权时，公开、编辑或透露您的个人信息及您保存在网站的非公开内容。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Lgxm"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            5.4
          </span>
        </p>
        <p
          ql-global-para="true"
          line="kbT0"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            您同意，我们有权通过 cookie
            等技术收集您的使用行为信息，并在经过数据脱敏使之不再指向、关联到您个人的身份信息时，自由使用脱敏后的纯商业数据。当然，您也可根据自己的偏好删除
            Cookie，但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。目前本网站及服务删除
            Cookie 的路 径是“设置-清除数据”。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="BFs4"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            5.5
          </span>
        </p>
        <p
          ql-global-para="true"
          line="5A1O"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            在遵守本协议项下特别约定的个人信息保护与隐私政策外，我们希望您认真
            并完整阅读我们特别针对本网站及服务而制定并适时发布、更新的《用户隐私政策和声明》，这将更有助于保障您的个人信息。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="nuNH"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 6 条 信息或广告推送
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="Nzjd"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            6.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Daul"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            您同意在接受我们提供服务的同时，允许我们在遵守法律法规的前提下，由本网站或关联平台、第三方广告商向您发送、展示广告、推广或宣传信息(包括商业与非商业信息)。如您对发送、推荐的广告或信息不感兴趣，您可以基于我们提供的相关技术选项，控制系统向您展示或不展示/减少展示相关类型的广告或信息。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="BfUR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 7 条 知识产权等权益
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="5m7h"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            7.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="I47i"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            我们对本网站及服务中所包含的受知识产权或其他法律保护的资料享有相应的权利;除依法由用户或第三方享有著作权的内容之外，本网站及服务的整体内容的知识产权归我们或关联公司所有。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="drvX"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            7.2
          </span>
        </p>
        <p
          ql-global-para="true"
          line="nyWo"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            关于信息内容的投诉或举报。若您在使用本网站及服务过程中，不慎受到合法权益的侵犯，您有权通知我们采取必要措施进行处置。若您在使用本网站及服务过程中，发现存在违法违规或违反本网站及服务相关规则的情况，您也有权向我们发起举报，我们亦会及时采取必要措施(删除、屏蔽、断开链接或限制使用功能等)进行处置。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="HUtU"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            7.3
          </span>
        </p>
        <p
          ql-global-para="true"
          line="vFwG"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            内容维权授权。在法律法规允许的范围内，您同意并授权我们就侵犯您合法权益的行为(包括但不限于私自复制、使用、编辑、抄袭等行为)采取任何形式的法律行动，包括但不限于投诉、诉讼等必要的维权措施。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="q5Hb"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 8 条 第三方服务
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="gISX"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            8.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="tk0G"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            本网站及服务因服务内容所定，可能会以包括但不限于外部链接、服务接口等形式指向或包含任何第三方所提供的网站、产品、服务或其他任何内容。第三方服务均由第三方公司或机构提供。第三方提供服务的过程中向您做出的任何承诺、声明或行为与我们无关。您在使用第三方服务时，请您注意阅读并遵守第三方要求您遵守的服务条款、许可协议、个人信息保护政策或其他类似法律文件。
            如您未遵守第三方服务条款、许可协议、隐私政策或其他类似法律文件，造成您
            的任何损失，我们不承担任何责任。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="ligR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            8.2
          </span>
        </p>
        <p
          ql-global-para="true"
          line="eY7L"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            为了您的人身和财产安全，请您谨慎判断是否使用第三方服务，特别是涉及支付环节。您理解并清楚使用第三方服务所可能引发的风险和后果，包括但不限于隐私泄露、财产损失等问题。如果您在使用第三方服务的过程中发生第三方拒绝提供服务等任何事故、损害、投诉、纠纷或其他问题的，请您与第三方协商解
            决。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="PLlE"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            8.3
          </span>
        </p>
        <p
          ql-global-para="true"
          line="HhMk"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            某些第三方服务需使用您的个人信息，您使用这些服务，即表示您已经同意我们将您的相应个人信息发送至相应第三方，以使得第三方能够为您提供相应服务。我们对于第三方如何收集、使用、处理、存储、传输和保护您的个人信息无法监控和控制，由于第三方的原因导致您的个人信息被未经授权的访问、使用、
            披露的，您应向第三方主张权利，并由第三方承担相应责任。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="ozb9"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            8.4
          </span>
        </p>
        <p
          ql-global-para="true"
          line="3sX2"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            您与其他用户的互动仅属于您与其他用户之间的行为，我们不控制且不对以上用户内容承担法律责任，您对因使用该用户内容以及与其他用户互动产生的风险承自行担法律责任，我们对此类行为不承担任何法律责任。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="ei7d"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 9 条 免责声明
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="afoT"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            9.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="aK9s"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            我们对于您自本网站及服务而获得的所有他人、第三方的信息、内容、链
            接或者广告宣传等任何资讯(以下统称“信息”)，除法律明确规定外，不保证真实、准确和完整性。如果任何单位或者个人通过上述“信息”而进行任何行为，
            您须自行甄别真伪和谨慎预防风险。无论何种原因，我们不对任何非与我们直接
            发生的交易或行为承担任何直接、间接、附带或衍生的损失和责任。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="x8nR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            9.2
          </span>
        </p>
        <p
          ql-global-para="true"
          line="meDN"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            style={{ color: "rgb(85, 85, 85)" }}
            ql-global="true"
          >
            本网站生成的文章仅供参考，
          </span>
          <span
            class="ql-author-77709990"
            style={{
              color: "rgb(61, 61, 61)",
              "background-color": "rgba(255, 255, 255, 0.5)",
            }}
            ql-global="true"
          >
            不应将其用于代写或其他不诚信行为
          </span>
          <span
            class="ql-author-77709990"
            style={{ color: "rgb(85, 85, 85)" }}
            inline-dir="ltr"
            ql-global="true"
          >
            。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Nirb"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            9.3
          </span>
        </p>
        <p
          ql-global-para="true"
          line="tnrB"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            鉴于互联网服务的特殊性，您理解并同意我们在以下情况下无须对您所遭受的损失(包括但不限于财产、收益、数据资料等方面的损失或其他无形损失)承担责任:
            (1)
            因台风、地震、海啸、洪水、停电、战争、恐怖袭击、政府政策管控等不可抗力之因素导致本网站及服务障碍不能正常运作;
            (2)
            因计算机病毒、木马、其他恶意程序、黑客攻击、电信部门及网络运营公司技术调整或故障、系统维护等原因而造成本网站及服务中断或延迟;
            (3)
            因法律法规的变更、司法机关及行政机关等的命令、裁定等原因而导致的本网站及服务中断、终止或延迟。
            (4)
            因本站不定期对网络服务平台及相关设备径行检修或维护，由此造成本网站及服务中断或延迟。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="x1lS"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            9.4
          </span>
        </p>
        <p
          ql-global-para="true"
          line="gjz9"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            我们有权根据实际情况自行决定用户在本网站上使用数据的最长存储期限和最大存储空间等，用户可自行根据自己的需要备份您在本网站的使用数据。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="4IxB"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            9.5
          </span>
        </p>
        <p
          ql-global-para="true"
          line="FXJt"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            用户理解并接受下载、电子邮箱获取或通过相应产品服务取得的任何信息资料取决于用户自己，并自行承担系统受损、资料丢失以及其他任何风险。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="qeuR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 10 条 违约责任
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="xMW8"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            10.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="VFHF"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            如果本网站发现、收到他人举报或投诉您违反本协议约定或违反法律法规和国家有关规定的，我们有权采取警示、冻结、终止/中止/限制使用账号等功能、
            删除相关内容、解除或终止本协议，或提出损害赔偿等。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="oRty"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            10.2
          </span>
        </p>
        <p
          ql-global-para="true"
          line="5IIO"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            若您的行为给我们造成损失的(包括但不限于直接损失、名誉损失、第三方的罚款、索赔、违约金、主张权利的律师费等)，我们有权全额向您追偿。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="YkWM"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            10.3
          </span>
        </p>
        <p
          ql-global-para="true"
          line="m3j8"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            本协议终止后，除法律有明确规定外，我们无义务向您或您指定的第三方披露您账户中的任何信息。本协议终止后，本平台仍享有下列权利:根据法律规定，继续保存您留存于本平台的各类信息;对于您过往的违约行为，本平台仍可依据本协议向您追究违约责任。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="xGTF"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 11 条 法律适用与管辖
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="Zp04"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            11.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="boGB"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            本协议的订立、执行、解释及争议的解决均适用中华人民共和国法律。如双方就本服务协议内容或其履行发生任何争议，双方应尽力友好协商解决;协商不成时，任何一方均可向协议签订地，即北京互联网法院提起诉讼。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="fR1N"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            第 12 条 联系我们
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="3k0W"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            12.1
          </span>
        </p>
        <p
          ql-global-para="true"
          line="init"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            如果您有任何的疑问、投诉、意见和建议，欢迎您与我们沟通反馈。我们的联系方式见下:
            在线客服: 请登录本网站点击右上角头像下方的“客服”进入
          </span>
        </p>
      </div>
      <div>
        <div className={styles.agreementTitle}>用户权益保护条款</div>
        <p
          ql-global-para="true"
          line="Svmd"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            隐私保护：
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="cThR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            本网站承诺遵守相关法律法规，对用户提供的个人信息进行保密，并采取合理措施保护用户隐私。
            <br />
            未经用户明确同意，本网站不会向任何第三方披露、出售或非法使用用户的个人信息，但法律法规另有规定的除外。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Svmd"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            知情权与选择权：
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="cThR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            用户在注册及使用本网站服务时，有权充分了解服务内容、使用规则及可能涉及的个人信息处理情况。
            <br />
            用户有权选择是否接受本网站提供的特定服务或功能，并有权随时终止使用服务。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Svmd"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            数据安全：
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="cThR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            本网站将采取必要的技术措施和管理制度，确保用户数据的安全，防止数据丢失、泄露或被非法使用。
            <br />
            如发生用户数据泄露等安全事件，本网站将及时通知用户，并采取补救措施。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Svmd"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            投诉与反馈：
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="cThR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            用户有权对本网站的服务提出投诉或建议，本网站将设立便捷的投诉渠道，并及时响应和处理。
            <br />
            本网站承诺对用户反馈进行认真调查，并在合理时间内给予答复。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Svmd"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            未成年人保护：
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="cThR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            对于未满法定年龄的未成年人用户，本网站将采取特别保护措施，限制其访问不适宜的内容或服务。
            <br />
            未成年人用户在使用本网站服务时，应得到其法定监护人的同意和指导。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Svmd"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            违约责任：
          </strong>
        </p>
        <p
          ql-global-para="true"
          line="cThR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            如因本网站违反相关法律法规或本协议约定，导致用户权益受损，本网站将承担相应的法律责任。
            <br />
            用户如违反本协议约定，本网站有权依据协议条款采取相应措施，包括但不限于限制服务、终止账号等。
          </span>
        </p>
        <p
          ql-global-para="true"
          line="Svmd"
          class="ql-direction-ltr ql-long-77709990"
        >
          <strong
            class="ql-author-77709990 ql-size-12"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            法律适用与争议解决：
          </strong>
        </p>

        <p
          ql-global-para="true"
          line="cThR"
          class="ql-direction-ltr ql-long-77709990"
        >
          <span
            class="ql-author-77709990"
            inline-dir="ltr"
            ql-global="true"
            style={{ color: "rgb(85, 85, 85)" }}
          >
            本协议的解释、效力及执行均适用中华人民共和国法律。
            <br />
            如因本协议产生争议，双方应首先通过友好协商解决；协商不成时，可提交至有管辖权的人民法院诉讼解决。
          </span>
        </p>
      </div>
    </div>
  );
}
