import styles from "./result.module.scss";
import { Modal, QRCode, Button, Alert, message, Input } from "antd";
import { useState, useEffect, useRef } from "react";
import error from "../../assets/error.png";
import success from "../../assets/success.png";
import { get, post } from "../../axios/axios";
import { Checkbox, Carousel } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import "../Billing.scss";
import { TEMPLATE_THEME } from "./const";
import { newPPTStore } from "./newStore";

import PPTImage from "../../assets/template.png";
import moment from "moment";
import { handleDownLoad } from "../../Service/utils";
export function PPTResult({ ppt }) {
  console.log("ppt", ppt);
  const createTime = moment().format("YYYY-MM-DD HH:mm:ss");
  const [pptResult, setPPTResult] = useState(null);
  const template = TEMPLATE_THEME[ppt.theme || "blue"];

  const [showPayModal, setShowPayModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");
  const [timerId, setTimerId] = useState("");
  const [payResult, setPayResult] = useState(false);
  const payInfoRef = useRef({});
  const [showPayResult, setShowPayResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [demoList, setDemoList] = useState([]);
  const submitPay = async () => {
    try {
      if (!checked) {
        message.warning("请先阅读并勾选服务条款");
        return;
      }

      if (loading) {
        return;
      }
      setLoading(true);
      const res = await post("/member/payment/pay", {
        orderNo: ppt.uid,
        client: "PC",
      });

      payInfoRef.current = {
        ...(res.data || {}),
      };
      setShowPayModal(true);
      checkPay();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const showCodeModal = async (e) => {
    e.stopPropagation();
    setShowRedeemModal(true);
  };

  const submitRedeemCode = async () => {
    try {
      const res = await post("/member/payment/redeemCode", {
        orderNo: ppt.uid,
        code: redeemCode,
      });
      console.log("res", res);
      if (res && res.code === 0) {
        message.info("正在加速生成中，预计需要10分钟");
        setShowRedeemModal(false);
        setPayResult(true);
      } else {
        const msg = res && res.msg;
        message.error(
          msg || "请检查您输入的兑换码是否正确，如果错误请重新输入。"
        );
      }
    } catch (error) {
      console.error(error);
      // message.error("请检查您输入的兑换码是否正确，如果错误请重新输入。");
    }
  };

  const onCancel = () => {
    window.clearTimeout(timerId);
    payInfoRef.current = {};
    setShowPayResult(false);
    setShowPayModal(false);
  };

  const toRePay = async () => {
    setShowPayResult(false);
    const res = await post("/member/payment/pay", {
      orderNo: ppt.uid,
      client: "PC",
    });

    payInfoRef.current = res.data || {};
  };

  const checkPay = async () => {
    if (timerId) {
      window.clearTimeout(timerId);
    }

    try {
      const res = await get("/member/payment/pay/result/" + ppt.uid);
      if (res && res.data && res.data.code === 0) {
        setShowPayResult(true);
        setPayResult(res.data.code === 0);

        setTimeout(() => {
          if (res.data.code === 0) {
            onCancel();
          }
        }, 1000);
      } else {
        const id = window.setTimeout(() => {
          checkPay();
        }, 1000);
        setTimerId(id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const exportPaper = async () => {
    const { data, msg } = await get(`/ai/ai/ppt/result?uid=${ppt.uid}`);
    if (data.code === 0) {
      message.success("PPT下载完成");
      handleDownLoad(data.url, ppt.title);
      return;
    }

    if (data === null) {
      message.error(msg || "查询订单失败，请联系客服");
      return;
    }

    message.info("正在加速生成中，预计需要3分钟");
  };

  const [checked, setChecked] = useState(false);
  return (
    <div className={styles.result}>
      <div className={styles.resultPage}>
        <div className={styles.pageTitle}>PPT预览</div>
        {ppt && (
          <div className={styles.pptPreview}>
            <div className={styles.pptPreviewContent}>
              <div
                className={styles.pptTitle}
                style={{ color: template.titleColor }}
              >
                {ppt.title}
              </div>
              <div
                className={styles.pptSubTitle}
                style={{ color: template.subTitleColor }}
              >
                {ppt.subTitle}
              </div>
              <div className={styles.pptAuthor}>作者：青秧灵感</div>
              <div className={styles.createTime}>汇报时间：{createTime}</div>
            </div>
            <img src={template.bg} className={styles.pptImg} />
          </div>
        )}
        <div className={styles.pay}>
          <div className={styles.hideTop}></div>
          <div className={styles.payContent}>
            {payResult ? (
              <div className={styles.payResultBtnList}>
                <div onClick={exportPaper} className={styles.payBtn}>
                  下载PPT
                </div>
              </div>
            ) : (
              <div>
                <div className="flex">
                  <Button
                    type="primary"
                    loading={loading}
                    className={styles.payBtn}
                    onClick={submitPay}
                  >
                    <div>￥{ppt.pptType && ppt.pptType.price}解锁全篇</div>
                  </Button>
                </div>

                <div className={styles.payTip}>
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  >
                    <span style={{ marginLeft: "4px", color: "#24be58" }}>
                      请先阅读并同意
                      <a
                        className={styles.fuwuLink}
                        href={`${window.location.protocol}//${window.location.host}/agreement`}
                        target="_blank"
                      >
                        《服务条款》
                      </a>
                    </span>
                  </Checkbox>
                  <div
                    style={{
                      color: "#999",
                      fontSize: "12px",
                      marginTop: "2px",
                    }}
                    onClick={showCodeModal}
                  >
                    我有兑换码
                  </div>
                </div>

                {/* <div onClick={showCodeModal}>
                  使用<span>兑换码</span>
                </div> */}
              </div>
            )}
          </div>

          {demoList && demoList.length ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 16px",
                width: "500px",
                margin: "0 auto",
                marginBottom: "20px",
              }}
              className={styles.demoList}
            >
              <NotificationOutlined style={{ color: "rgb(36, 190, 88)" }} />
              <Carousel
                style={{ width: "400px", margin: "0 0 0 10px" }}
                dotPosition="left"
                autoplay={true}
                autoplaySpeed={1000}
              >
                {demoList.map((item, idx) => {
                  return <div key={idx}>{item}</div>;
                })}
              </Carousel>
            </div>
          ) : null}
        </div>
      </div>

      <Modal
        open={showPayModal}
        width={790}
        footer={null}
        onCancel={onCancel}
        maskClosable={false}
      >
        {showPayResult ? (
          <div className="pay-result">
            <div
              className="result"
              style={payResult ? { padding: "16px 0 20px 0" } : {}}
            >
              <p className="tips">
                {payResult ? "恭喜您，支付成功" : "很遗憾，支付失败"}
              </p>
              <img
                src={payResult ? success : error}
                className="image"
                style={payResult ? { margin: "16px 0 20px 0" } : {}}
              />
              {!payResult ? (
                <Button className="action_btn" onClick={toRePay}>
                  <span>重新支付</span>
                </Button>
              ) : (
                <Button onClick={onCancel}>
                  <span>完成</span>
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="w-pay">
            <Alert
              message="付款成功前请不要关闭此页面。如付款后页面没有更新，请根据您的情况点击下方按钮"
              type="warning"
              showIcon
              className="w-pay-alert"
            />
            <div className="title">请使用微信扫一扫</div>
            <p className="order">订单号：{ppt.uid}</p>
            <div className="qr-code">
              <QRCode value={payInfoRef.current.codeUrl} />
            </div>
            <p className="cost-tips">
              支付金额 <span className="unit">¥</span>
              <span className="value">
                {(ppt.pptType && ppt.pptType.price) || "35.9"}
              </span>
            </p>
            <div className="button-group">
              <Button type="primary" onClick={checkPay}>
                <span>付款成功</span>
              </Button>
            </div>
            <p className="tips">如遇支付问题，请联系客服微信</p>
          </div>
        )}
      </Modal>

      <Modal
        open={showRedeemModal}
        width={790}
        footer={null}
        onCancel={() => setShowRedeemModal(false)}
        maskClosable={false}
        title="请输入兑换码"
      >
        <Input
          style={{ marginTop: "16px" }}
          placeholder="请输入兑换码"
          value={redeemCode}
          onChange={(e) => setRedeemCode(e.target.value)}
        />
        <div
          style={{ marginTop: "20px", justifyContent: "flex-end" }}
          className="flex"
        >
          <Button type="primary" onClick={submitRedeemCode}>
            确定
          </Button>
        </div>
      </Modal>
    </div>
  );
}
