import classNames from 'classnames'
import styles from './index.module.scss'
import { Checkbox, Collapse, Button, Steps } from 'antd'
import { reduceStore } from './store'
import { observer } from 'mobx-react-lite'
import { isMobile } from '../../common/const'
import cx from 'classnames'

export const Step2 = observer(() => {
  return (
    <div className={'flex flex-col lg:flex-row lg:items-start lg:gap-4'}>
      {/*左侧*/}
      <div className={'bg-white p-4 flex-1'}>
        <div className={'scale-75 -translate-x-5 lg:-translate-x-0 lg:scale-100 lg:mb-6'}>
          <Steps
            current={reduceStore.curStep}
            onChange={reduceStore.onNewStepChange}
            className={'site-navigation-steps'}
            items={reduceStore.steps}
            direction="horizontal"
            style={{
              flexDirection: isMobile ? 'row' : ''
            }}
          />
        </div>
        <div className={styles.left}>

          <div className={styles.h4}>选择段落</div>
          <div>
            {reduceStore.step2.list.map((p, index) => {
              const isActive = reduceStore.step2selected.includes(p)
              return (
                <Checkbox
                  rootClassName={classNames(styles.pra, {
                    [styles.active]: isActive
                  })}
                  style={{
                    marginLeft: 0
                  }}
                  key={index}
                  onChange={(e) =>
                    reduceStore.onStep2TogglePars(p, e.target.checked)
                  }
                  checked={isActive}
                >
                  {p.text}
                </Checkbox>
              )
            })}
          </div>
        </div>
      </div>
      {/*右侧*/}
      <div className={'lg:w-[288px]'}>
        <div className={cx(styles.right,styles.helpBox)}>
          <div className={styles.title}>
            服务内容
            <div className={styles.wordCount}>
              已选：{reduceStore.totalCount}字符
            </div>
          </div>
          <div className={styles.hr}/>
          <div className={styles.h5}>基础服务</div>
          <Checkbox
            rootClassName={classNames(styles.item, {
              [styles.active]: reduceStore.step2Service.basic
            })}
            onChange={(e) =>
              reduceStore.onStep2Service('basic', e.target.checked ? 1 : 0)
            }
            checked={reduceStore.step2Service.basic}
          >
            <div className={styles.itemContent}>
              智能降重
              <b>￥{reduceStore.step2.order.basicPrice || 0}</b>
            </div>
          </Checkbox>
          <div className={styles.h5}>去AI痕迹</div>
          <Checkbox
            rootClassName={classNames(styles.item, {
              [styles.active]: reduceStore.step2Service.type
            })}
            onChange={(e) =>
              reduceStore.onStep2Service('type', e.target.checked ? 1 : 0)
            }
            checked={reduceStore.step2Service.type}
          >
            <div className={styles.itemContent}>
              强力去痕
              <b>￥{reduceStore.step2.order.powerfulPrice || 0}</b>
            </div>
          </Checkbox>
          <Collapse
            defaultActiveKey={['1']}
            bordered={false}
            expandIconPosition="end"
            style={{ background: 'transparent' }}
            className={styles.collapse}
          >
            <Collapse.Panel
              header={<span className={styles.h5}>费用明细</span>}
              key="1"
              extra={
                <span className={styles.payPrice}>
                {reduceStore.step2.order.totalAmount || 0}元
              </span>
              }
            >
              <div>
                {reduceStore.step2Service.basic ? (
                  <div className={styles.payRuleRow}>
                    <div className={styles.payRule}>
                      智能降重
                      <strong className={styles.price}>
                        ￥{reduceStore.step2.order.basicPrice || 0}
                      </strong>
                    </div>
                    <div className={styles.payDesc}>
                      3元/1000字符（不满1000字符计1000字符）
                    </div>
                  </div>
                ) : null}

                {reduceStore.step2Service.type ? (
                  <div className={styles.payRuleRow}>
                    <div className={styles.payRule}>
                      强力去痕
                      <span className={styles.red}>(推荐高疑似度使用)</span>
                      <strong className={styles.price}>
                        ￥{reduceStore.step2.order.powerfulPrice || 0}
                      </strong>
                    </div>
                    <div className={styles.payDesc}>
                      {reduceStore.step2.order.powerfulPrice}元/1000字符（不满1000字符计1000字符）
                    </div>
                  </div>
                ) : null}
              </div>
            </Collapse.Panel>
          </Collapse>
          <div className={styles.rightBtns}>
            <div>
              <Button
                loading={reduceStore.step2.loading}
                className={styles.btn}
                size="large"
                type="primary"
                onClick={reduceStore.onStep2Pay}
              >
                一键降重
              </Button>
              <Button
                className={styles.btn}
                size="large"
                onClick={reduceStore.goBackStep1}
              >
                返回
              </Button>
            </div>
            <div className={styles.redeem} onClick={reduceStore.onStep2Redeem}>
              我有兑换码
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
