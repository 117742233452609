import { makeAutoObservable } from "mobx";
import { get } from "../../axios/axios";

class EditorStore {
  data = {
    title: "",
    open: "",
    paragraph: [],
  };
  saving = false;
  constructor() {
    makeAutoObservable(this);
  }
  get wordCount() {
    return (
      this.data.title.length +
      this.data.open.length +
      this.data.paragraph.reduce((c, par) => {
        return c + par.content.length + (par.text || "").length;
      }, 0)
    );
  }
  initData(data) {
    console.log(data);
    // this.data = data;
    this.data = {
      title: "人工智能时代,如何让机器像人一样学习?",
      open: "人工智能:未来已来,我们如何应对?",
      paragraph: [
        { id: "0", content: "1212341234123", text: "asdfa" },
        { id: "1", content: "asdfasdfasdfasdf" },
        { id: "2", content: "#$%^&*()" },
      ],
    };
  }
  async getDataById(id) {}
  newPar() {
    this.data.paragraph.push({
      id: this.data.paragraph.length,
      content: "",
      text: "",
    });
  }
  updateTxt(field, txt) {
    this.data[field] = txt;
  }
  updatePar(index, field, txt) {
    this.data.paragraph[index][field] = txt;
  }
  parUp(i) {
    this.data.paragraph = reorder(this.data.paragraph, i, i - 1);
  }
  parDown(i) {
    this.data.paragraph = reorder(this.data.paragraph, i, i + 1);
  }
  parDel(i) {
    this.data.paragraph.splice(i, 1);
  }
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export const editorStore = new EditorStore();
