import styles from "./Recommend.module.scss";
import { BulbOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import { post, get } from "../../axios/axios";
import { useEffect, useState } from "react";
import { Checkbox, Modal, message } from "antd";
import ClipboardJS from "clipboard";

const { TextArea } = Input;

function Recommend(props) {
  const [list, setList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState(null);
  const [referDetail, setReferDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    searchlLiterature();
  }, [props.selectText]);
  const searchlLiterature = async () => {
    if (!props.selectText) {
      return;
    }
    setLoading(true);
    setSelectAll(false);
    const { data = [] } = await post("/ai/literature/r", {
      text: props.selectText,
    });
    setLoading(false);
    setList(data.map((item) => ({ ...item, checked: false })));
  };

  const handleSelectAll = (e) => {
    const nv = list.map((i) => {
      return {
        ...i,
        checked: e.target.checked,
      };
    });
    setList(nv);
    setSelectAll(e.target.checked);
  };

  const toUrl = (url) => {
    window.open(url, "_blank");
  };

  const getRefer = async (cite) => {
    const { data = "" } = await post("/ai/literature/getCite?id=" + cite);
    setReferDetail(data);
  };

  const handleOpen = async (item) => {
    setActive(item);
    getRefer(item.cite);
    setModalOpen(true);
  };
  const handleCancel = () => {
    setModalOpen(false);
    setReferDetail("");
  };
  const handleExport = (txt) => {
    props.insertText(txt);
  };

  const handleExportRow = async (cite) => {
    const { data = "" } = await post("/ai/literature/getCite?id=" + cite);
    props.insertText(data);
  };
  const handleCopy = () => {
    var clipboard = new ClipboardJS("#copyRefer", {
      text: function () {
        return referDetail;
      },
    });

    clipboard.on("success", function (e) {
      console.log("success");
      message.success("复制成功");
      clipboard.destroy();
    });

    clipboard.on("error", function (e) {
      console.log("Failed to copy to clipboard!");
      clipboard.destroy();
    });
  };
  const handleOk = () => {
    handleCopy();
    setTimeout(() => {
      setModalOpen(false);
      setReferDetail("");
    }, 300);
  };

  return (
    <div className={styles.recommendBox}>
      <div className={styles.header}>
        <div className={styles.lightIcon}>
          <BulbOutlined style={{ fontSize: "16px", color: "#f7941d" }} />
        </div>
        <div className={styles.headerDesc}>
          <div>文献推荐</div>
          <div className={styles.headerSubDesc}>请概述您想要搜索的文献内容</div>
        </div>
        <CloseOutlined
          className={styles.closeIcon}
          onClick={() => props.closeRecommend()}
        />
      </div>
      <div className={styles.scrollBox}>
        <div className={styles.searchTitle}>请概述您想要搜索的文献内容</div>
        <TextArea
          onChange={(e) => props.setSelectText(e.target.value)}
          value={props.selectText}
          rows={5}
          maxLength={3000}
          showCount={true}
        />
        <Button
          onClick={searchlLiterature}
          type="primary"
          size="large"
          className={styles.submitButton}
          loading={loading}
        >
          检索
        </Button>
        {list.length ? (
          <div className={styles.searchResult}>
            {/* <div className={styles.searchResultHeader}>
              <Checkbox onChange={handleSelectAll}>全选</Checkbox>
              {selectAll ? (
                <Button onClick={handleExport}>批量导出</Button>
              ) : null}
            </div> */}
            {list.map((item, idx) => {
              return (
                <div key={idx} className={styles.searchRow}>
                  {/* <Checkbox
                    checked={item.checked}
                    onChange={(v) => {
                      item.checked = v.target.checked;
                      setList([...list]);
                    }}
                  /> */}
                  <div className={styles.right}>
                    <div className={styles.searchRowTitle}>
                      <span className={styles.titleText}>{item.title}</span>
                    </div>
                    <div className={styles.authors}>{item.authors}</div>
                    <div className={styles.searchRowDesc}>
                      <span>{item.publication}</span>
                      <span className={styles.m4}>|</span>
                      <span>{item.publishDate}</span>
                      <span className={styles.m4}>|</span>
                      <span
                        className={styles.export}
                        onClick={() => handleExportRow(item.cite)}
                      >
                        导入
                      </span>
                      <span className={styles.m4}>|</span>
                      <span
                        className={styles.refer}
                        onClick={() => handleOpen(item)}
                      >
                        <svg
                          data-v-d70c87b2=""
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ verticalAlign: "-3px" }}
                        >
                          <path
                            data-v-d70c87b2=""
                            d="M11.375 6.99644L7.04153 6.99644L7.04153 2.66297L11.375 2.66297L11.375 6.99644ZM11.375 6.99644L9.54566 10.5625L8.14816 10.5625L9.97791 6.99644L11.375 6.99644Z"
                            fill="#f7941d"
                          ></path>
                          <path
                            data-v-d70c87b2=""
                            d="M6.09375 6.99644L1.76028 6.99644L1.76028 2.66297L6.09375 2.66297L6.09375 6.99644ZM6.09375 6.99644L4.26441 10.5625L2.86691 10.5625L4.69666 6.99644L6.09375 6.99644Z"
                            fill="#f7941d"
                          ></path>
                        </svg>
                        引用
                      </span>
                      <Button
                        onClick={() => toUrl(item.url)}
                        className={styles.seeMore}
                        size="small"
                      >
                        查看
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        <Modal
          title="引用"
          open={modalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          {referDetail ? <p id="refer">{referDetail}</p> : "检索中..."}
          <div
            className="flex"
            style={{ marginTop: "30px", justifyContent: "center" }}
          >
            <Button onClick={handleCancel} style={{ marginRight: "20px" }}>
              取消
            </Button>
            <Button type="primary" onClick={handleOk} id="copyRefer">
              复制
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Recommend;
