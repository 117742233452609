import styles from "./index.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NewPPT } from "./new";
import { PPTResult } from "./result";
import { newPPTStore } from "./newStore";

export function PPT() {
  const nav = useNavigate();
  const [qs] = useSearchParams();
  const uid = qs.get("id");
  const title = qs.get("title");
  const [theme, setTheme] = useState(qs.get("theme"));
  const [showNew, setShowNew] = useState(true);
  const [ppt, setPPT] = useState({});

  useEffect(() => {
    newPPTStore.reset();
  }, []);
  return (
    <div className={styles.ppt}>
      <div className={styles.header}>
        {/* <Button
          shape="round"
          icon={<LeftOutlined style={{ fontSize: "12px" }} />}
          onClick={() => {
            nav("/", { replace: true });
          }}
        >
          返回
        </Button> */}
        <div
          onClick={() => {
            nav("/", { replace: true });
          }}
          className={'cursor-pointer'}
        >
          青秧灵感
        </div>
      </div>
      {/*<div className={styles.header}>*/}
      {/*  <div*/}
      {/*    onClick={() => {*/}
      {/*      nav("/", { replace: true });*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    青秧灵感*/}
      {/*  </div>*/}
      {/*</div>*/}

      {showNew ? (
        <NewPPT
          uid={uid}
          title={title}
          handleNew={(data = {}) => {
            setShowNew(false);
            setTheme(data.theme);
            setPPT(data);
            window.history.replaceState(null, null, `/ppt`);
          }}
        />
      ) : (
        <PPTResult
          ppt={{
            uid,
            ...ppt,
            theme,
            title: newPPTStore.step2.mainTitle,
          }}
        />
      )}
    </div>
  );
}
