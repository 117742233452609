import styles from "./FloatInput.module.scss";
import { useOutsideClick } from "../../Service/hooks";
import { Input } from "antd";
import {
  ExpandAltOutlined,
  ArrowUpOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useState, useRef, useEffect } from "react";
import { post } from "../../axios/axios";
import axios from "axios";
export function FloatInput(props) {
  // click outside
  const handleClickOutside = () => {
    console.log("click outside");
    props.closeFloatInput();
  };
  const ref = useOutsideClick(handleClickOutside);

  const [loading, setLoading] = useState("before");
  const [userText, setUserText] = useState("");
  const [result, setResult] = useState("");
  // const [cancelToken, setCancelToken] = useState(null);

  // // 创建新的 CancelToken
  // const source = axios.CancelToken.source();
  // setCancelToken(source);

  const getAIWrite = async () => {
    try {
      setLoading("loading", () => {});
      const { data } = await post(
        "/ai/select/menu/ac",
        {
          text: props.selectText,
          userText: userText,
        }
        // { cancelToken: source.token }
      );
      // if (!axios.isCancel(source.token)) {
      console.log("xx", loading, data);
      setResult(data);
      setLoading("end");
      //}
    } catch (error) {
      console.error(error);
      setLoading("before");
    }
  };
  const onCancel = () => {
    // if (cancelToken) {
    //   cancelToken.cancel("请求被用户取消");
    //   setLoading("before");
    //   setResult("");
    // }
  };

  const inputRef = useRef(null);
  useEffect(() => {
    const e = props.QuillRef.current.getEditor();
    e.formatText(
      props.selectRange.index,
      props.selectRange.length,
      "background",
      "#AACEFB"
    ); // 设置背景颜色为黄色
    inputRef.current?.focus();

    return () => {
      e.removeFormat(props.selectRange);
    };
  }, [props.QuillRef, props.selectRange]);
  return (
    <div
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      ref={ref}
      className={styles.floatInput}
      style={props.floatInputStyles}
    >
      <div className={styles.floatInputContent}>
        {loading === "before" && (
          <Input
            ref={inputRef}
            autoFocus={true}
            onChange={(e) => {
              setUserText(e.target.value);
            }}
            bordered={false}
            size="large"
            placeholder="您可以让AI写任何内容"
            prefix={<ExpandAltOutlined style={{ color: "#C3C3C3" }} />}
            suffix={
              <div className={styles.enterIcon} onClick={getAIWrite}>
                <ArrowUpOutlined style={{ color: "#fff", fontSize: "14px" }} />
              </div>
            }
            onPressEnter={getAIWrite}
          />
        )}

        {loading === "loading" && (
          <div className={styles.loadingRow}>
            <ExpandAltOutlined
              style={{ color: "#C3C3C3", fontSize: "18px", marginRight: "8px" }}
            />
            生成中...
            <span className={styles.loadingEsc} onClick={onCancel}>
              取消 ESC
            </span>
          </div>
        )}

        {loading === "end" && (
          <div>
            <div className={styles.genResult}>{result}</div>
            <div className={styles.optBar}>
              <ExclamationCircleOutlined style={{ marginRight: "auto" }} />
              <div
                className={styles.optItem}
                onClick={() => {
                  props.replaceResultText(result, props.selectRange);
                  props.closeFloat();
                  props.closeFloatInput();
                }}
              >
                替换
              </div>
              <div
                className={styles.optItem}
                onClick={() => {
                  props.insertResutText(result, props.selectRange);
                  props.closeFloat();
                  props.closeFloatInput();
                }}
              >
                插入
              </div>
              <div className={styles.optItem} onClick={getAIWrite}>
                重写
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
