import styles from "./FloatToolbar.module.scss";
import { StrikethroughOutlined, ExpandAltOutlined } from "@ant-design/icons";
import { Dropdown, Button, Spin, Tabs, Select, Input } from "antd";
import { useState } from "react";
import { useOutsideClick } from "../../Service/hooks";
import { get, post } from "../../axios/axios";
import { HeaderDropdown } from "./HeaderDropdown";
import { FontDropdown } from "./FontDropdown";
import { ColorDropdown } from "./ColorDropdown";
import { AlignDropdown } from "./AlignDropdown";
export default function FloatToolbar(props) {
  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={(e) => {
            handleClickAction("JIANYI");
          }}
          style={{ whiteSpace: "nowrap" }}
        >
          内容推荐
        </div>
      ),
    },
    {
      key: "2",
      label: <div onClick={props.onShowRecommend}>文献总结</div>,
    },
  ];
  const onBold = () => {
    const e = props.QuillRef.current.getEditor();

    e.format("bold", !e.getFormat()?.bold);
  };
  const onItalic = () => {
    const e = props.QuillRef.current.getEditor();

    e.format("italic", !e.getFormat()?.italic);
  };
  const onUnderline = () => {
    const e = props.QuillRef.current.getEditor();

    e.format("underline", !e.getFormat()?.underline);
  };

  const onStrike = () => {
    const e = props.QuillRef.current.getEditor();

    e.format("strike", !e.getFormat()?.strike);
  };

  const onHeader = (level) => {
    const e = props.QuillRef.current.getEditor();
    e.format("header", e.getFormat()?.header === level ? false : level);
  };

  const onOrder = () => {
    const e = props.QuillRef.current.getEditor();

    e.format("list", e.getFormat()?.list === "ordered" ? false : "ordered");
  };
  const onBullet = () => {
    const e = props.QuillRef.current.getEditor();

    e.format("list", e.getFormat()?.list === "bullet" ? false : "bullet");
  };
  // click outside
  const handleClickOutside = () => {
    console.log("click outside");
    props.closeFloat();
  };
  const ref = useOutsideClick(handleClickOutside);

  // 改扩译总结
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [resultTxt, setResultTxt] = useState("");
  const ACTION_MAP = {
    GAI: { url: "/ai/select/menu/r", label: "改写" },
    KUO: { url: "/ai/select/menu/e", label: "扩写" },
    YI: { url: "/ai/select/menu/t", label: "翻译" },
    ZONG: { url: "ai/select/menu/s", label: "总结" },
    JIANYI: { url: "ai/select/menu/c", label: "内容建议" },
  };

  const handleClickAction = async (action, payload = {}) => {
    setAction(action);
    setLoading(true);
    const result = await post(ACTION_MAP[action].url, {
      text: getSelectText(),
      ...payload,
    });
    setResultTxt(result.data);
    setLoading(false);
  };

  const handleRecommendContent = () => {
    props.showRecommentContent();
  };

  const getSelectText = () => {
    const e = props.QuillRef.current.getEditor();

    var range = e.getSelection();
    if (range) {
      if (range.length === 0) {
        return "";
      } else {
        return e.getText(range.index, range.length);
      }
    } else {
      return "";
    }
  };
  const insertText = () => {
    const e = props.QuillRef.current.getEditor();
    const t = resultTxt || "";
    if (!e || !t || !t.length) {
      return;
    }
    var range = e.getSelection();
    if (range) {
      // 获取选中文本的起始和结束位置
      var start = range.index;
      var end = start + range.length;

      // 插入新文本
      e.insertText(end, resultTxt || "");
    }
  };

  const replaceText = () => {
    const e = props.QuillRef.current.getEditor();

    var range = e.getSelection();
    if (range) {
      // 获取选中文本的起始
      var start = range.index;

      // 删除选中的文本
      e.deleteText(start, range.length);

      // 在起始位置插入新文本
      e.insertText(start, resultTxt || "");
      e.focus();
    }
  };

  const tabItems = [
    {
      key: "l1",
      label: `MoDeAI`,
    },
    {
      key: "l4",
      label: `常规`,
    },
    {
      key: "l5",
      label: `重度`,
    },
    {
      key: "l6",
      label: `强力`,
    },
  ];

  const onFont = (value) => {
    const e = props.QuillRef.current.getEditor();

    e.format("font", { "font-family": "Arial" });
    console.log(e.getFormat());
  };

  return (
    <div
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      ref={ref}
      style={props.floatBarStyles}
      className={styles.floatToolbar}
    >
      <div className={styles.toolbarRow}>
        <div className={styles.floatLeft}>
          <div>
            <HeaderDropdown onHeader={onHeader} QuillRef={props.QuillRef} />
          </div>
          <div>
            {/* <FontDropdown
              onFont={onFont}
              QuillRef={props.QuillRef}
            ></FontDropdown> */}
          </div>

          <div className={styles.floatBarItem} onClick={onBold}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 13 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.24522 0.2108C8.61983 0.2108 9.68014 0.525273 10.4661 1.19271C11.2119 1.82113 11.5851 2.66576 11.5851 3.74594C11.5851 4.53133 11.3889 5.19895 10.9962 5.74896C10.6035 6.25961 10.073 6.6523 9.36619 6.90754C10.289 7.0842 10.9962 7.45721 11.4671 8.02691C11.9386 8.59627 12.1743 9.36197 12.1743 10.3049C12.1743 11.7187 11.6833 12.7398 10.7213 13.4074C9.89635 13.9576 8.73777 14.2323 7.24522 14.2323H0.843262V0.2108H7.24522ZM2.98375 6.16135H6.67551C7.65742 6.16135 8.36459 5.98434 8.79649 5.6507C9.22873 5.29721 9.44442 4.74736 9.44442 4.00117C9.44442 3.294 9.22873 2.78353 8.79649 2.46906C8.36459 2.13543 7.65742 1.97846 6.71506 1.97846H2.98375V6.16135ZM2.98375 12.4652H6.95078C7.85412 12.4652 8.56076 12.3082 9.07141 12.0333C9.69982 11.6601 10.0338 11.0709 10.0338 10.2655C10.0338 9.4409 9.77858 8.83199 9.28762 8.45898C8.79666 8.0858 8.03078 7.90932 7.00967 7.90932H2.98375V12.4652Z"
                fill="#4B4B4B"
              ></path>
            </svg>
          </div>
          <div className={styles.floatBarItem} onClick={onItalic}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.70604 2.28041V0.776428H5.19408V2.28041H6.43914L2.37912 15.7959H0.682129V17.2999H5.19408V15.7959H3.94902L8.00922 2.28041H9.70604Z"
                fill="#4B4B4B"
              ></path>
            </svg>
          </div>
          <div className={styles.floatBarItem} onClick={onUnderline}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.96006 14.2805C11.9431 14.2805 14.3701 11.8535 14.3701 8.87047V0.0390396H12.7337V8.87047C12.7337 10.9515 11.041 12.6441 8.96006 12.6441C6.87916 12.6441 5.18639 10.9514 5.18639 8.87047V0.0390396H3.55004V8.87047C3.55004 11.8535 5.97705 14.2805 8.96006 14.2805ZM0.77832 15.3303H17.142V16.9666H0.77832V15.3303Z"
                fill="#4B4B4B"
              ></path>
            </svg>
          </div>
          <div className={styles.floatBarItem} onClick={onStrike}>
            <StrikethroughOutlined width={18} height={18} />
          </div>
          <div className={styles.floatBarItem} onClick={() => onHeader(1)}>
            <svg viewBox="0 0 18 18">
              <path
                className={styles.fill3}
                d="M10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Zm6.06787,9.209H14.98975V7.59863a.54085.54085,0,0,0-.605-.60547h-.62744a1.01119,1.01119,0,0,0-.748.29688L11.645,8.56641a.5435.5435,0,0,0-.022.8584l.28613.30762a.53861.53861,0,0,0,.84717.0332l.09912-.08789a1.2137,1.2137,0,0,0,.2417-.35254h.02246s-.01123.30859-.01123.60547V13.209H12.041a.54085.54085,0,0,0-.605.60547v.43945a.54085.54085,0,0,0,.605.60547h4.02686a.54085.54085,0,0,0,.605-.60547v-.43945A.54085.54085,0,0,0,16.06787,13.209Z"
              ></path>
            </svg>
          </div>
          <div className={styles.floatBarItem} onClick={() => onHeader(2)}>
            <svg viewBox="0 0 18 18">
              <path
                className={styles.fill3}
                d="M16.73975,13.81445v.43945a.54085.54085,0,0,1-.605.60547H11.855a.58392.58392,0,0,1-.64893-.60547V14.0127c0-2.90527,3.39941-3.42187,3.39941-4.55469a.77675.77675,0,0,0-.84717-.78125,1.17684,1.17684,0,0,0-.83594.38477c-.2749.26367-.561.374-.85791.13184l-.4292-.34082c-.30811-.24219-.38525-.51758-.1543-.81445a2.97155,2.97155,0,0,1,2.45361-1.17676,2.45393,2.45393,0,0,1,2.68408,2.40918c0,2.45312-3.1792,2.92676-3.27832,3.93848h2.79443A.54085.54085,0,0,1,16.73975,13.81445ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"
              ></path>
            </svg>
          </div>
          <div className={styles.floatBarItem} onClick={onOrder}>
            <svg viewBox="0 0 18 18">
              <line
                className={styles.stoke}
                x1="7"
                x2="15"
                y1="4"
                y2="4"
              ></line>
              <line
                className={styles.stoke}
                x1="7"
                x2="15"
                y1="9"
                y2="9"
              ></line>
              <line
                className={styles.stoke}
                x1="7"
                x2="15"
                y1="14"
                y2="14"
              ></line>
              <line
                className={styles.thin}
                x1="2.5"
                x2="4.5"
                y1="5.5"
                y2="5.5"
              ></line>
              <path
                className={styles.fill3}
                d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"
              ></path>
              <path
                className={styles.thin}
                d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"
              ></path>
              <path
                className={styles.thin}
                d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"
              ></path>
            </svg>
          </div>
          <div className={styles.floatBarItem} onClick={onBullet}>
            <svg viewBox="0 0 18 18" width="18" height="18">
              <line
                className={styles.stoke}
                x1="6"
                x2="15"
                y1="4"
                y2="4"
              ></line>
              <line
                className={styles.stoke}
                x1="6"
                x2="15"
                y1="9"
                y2="9"
              ></line>
              <line
                className={styles.stoke}
                x1="6"
                x2="15"
                y1="14"
                y2="14"
              ></line>
              <line className={styles.stoke} x1="3" x2="3" y1="4" y2="4"></line>
              <line className={styles.stoke} x1="3" x2="3" y1="9" y2="9"></line>
              <line
                className={styles.stoke}
                x1="3"
                x2="3"
                y1="14"
                y2="14"
              ></line>
            </svg>
          </div>
          <div>
            <ColorDropdown QuillRef={props.QuillRef}></ColorDropdown>
          </div>
          <div>
            <AlignDropdown QuillRef={props.QuillRef} />
          </div>
        </div>
        {/* <div className={styles.floatRight}>
          <Dropdown
            menu={{ items }}
            getPopupContainer={() => document.getElementById("floatmore")}
          >
            <div className={styles.floatBarItem} id="floatmore">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.66455 12.8323C7.66455 13.8321 6.82971 14.6645 5.83227 14.6645C4.83484 14.6645 4 13.8297 4 12.8323C4 11.8348 4.83484 11 5.83227 11C6.82971 11 7.66455 11.8324 7.66455 12.8323Z"
                  fill="#4B4B4B"
                ></path>
                <path
                  d="M11.1641 12.8395C11.1641 13.3255 11.3572 13.7915 11.7008 14.1351C12.0444 14.4788 12.5104 14.6718 12.9964 14.6718C13.4823 14.6718 13.9484 14.4788 14.292 14.1351C14.6356 13.7915 14.8287 13.3255 14.8287 12.8395C14.8287 12.3536 14.6356 11.8875 14.292 11.5439C13.9484 11.2003 13.4823 11.0073 12.9964 11.0073C12.5104 11.0073 12.0444 11.2003 11.7008 11.5439C11.3572 11.8875 11.1641 12.3536 11.1641 12.8395Z"
                  fill="#4B4B4B"
                ></path>
                <path
                  d="M18.3354 12.8395C18.3354 13.3255 18.5285 13.7915 18.8721 14.1351C19.2157 14.4788 19.6818 14.6718 20.1677 14.6718C20.6537 14.6718 21.1197 14.4788 21.4633 14.1351C21.807 13.7915 22 13.3255 22 12.8395C22 12.3536 21.807 11.8875 21.4633 11.5439C21.1197 11.2003 20.6537 11.0073 20.1677 11.0073C19.6818 11.0073 19.2157 11.2003 18.8721 11.5439C18.5285 11.8875 18.3354 12.3536 18.3354 12.8395Z"
                  fill="#4B4B4B"
                ></path>
              </svg>
            </div>
          </Dropdown>
        </div> */}
      </div>
      <div className={styles.toolbarRow} style={{ marginTop: "5px" }}>
        <div className={styles.floatLeft}>
          <div
            className={styles.floatBarItem}
            onClick={() => {
              handleClickAction("GAI", { level: "l1" });
            }}
          >
            改
          </div>
          <div
            className={styles.floatBarItem}
            onClick={() => {
              handleClickAction("KUO");
            }}
          >
            扩
          </div>
          <div
            className={styles.floatBarItem}
            onClick={(e) => {
              handleClickAction("YI");
            }}
          >
            译
          </div>
          <div
            className={styles.floatBarItem}
            onClick={(e) => {
              handleClickAction("ZONG");
            }}
          >
            总
          </div>
        </div>
        <div className={styles.floatRight}>
          <div
            className={styles.floatBarButton}
            onClick={props.onShowRecommend}
          >
            文献推荐
          </div>
          <div
            className={styles.floatBarButton}
            onClick={(e) => {
              handleRecommendContent();
            }}
          >
            内容建议
          </div>
          <div className={styles.aiWrite} onClick={props.handleShowFloatInput}>
            <ExpandAltOutlined style={{ color: "#ffb347" }} />
            <span>你说我改</span>
          </div>
        </div>
      </div>
      {action ? (
        <div className={styles.contentRow}>
          <Spin spinning={loading}>
            {action !== "GAI" && (
              <div className={styles.label}>
                AI写作：{ACTION_MAP[action].label}
              </div>
            )}

            <div className={styles.resultBox}>
              {action === "GAI" && (
                <Tabs
                  defaultActiveKey="l1"
                  items={tabItems}
                  size="small"
                  onChange={(level) => handleClickAction("GAI", { level })}
                />
              )}

              <div
                style={{ paddingTop: action === "GAI" ? 0 : "15px" }}
                className={styles.result}
              >
                {resultTxt}
              </div>
            </div>
            <div className={styles.footerBtn}>
              <Button
                disabled={!resultTxt}
                size="small"
                type="primary"
                className={styles.left}
                onClick={replaceText}
              >
                替换
              </Button>
              <Button disabled={!resultTxt} size="small" onClick={insertText}>
                插入
              </Button>
            </div>
          </Spin>
        </div>
      ) : null}
    </div>
  );
}
