import blue from "../../assets/ppt/blue.png";
import green from "../../assets/ppt/green.png";
import lightblue from "../../assets/ppt/lightblue.png";
import purple from "../../assets/ppt/purple.png";
import taupe from "../../assets/ppt/taupe.png";
import telecomGreen from "../../assets/ppt/telecomGreen.png";
import telecomRed from "../../assets/ppt/telecomRed.png";
import version10 from "../../assets/ppt/version10.png";
import version9 from "../../assets/ppt/version9.png";
import version8 from "../../assets/ppt/version8.png";
import version7 from "../../assets/ppt/version7.png";
import version6 from "../../assets/ppt/version6.png";
import version5 from "../../assets/ppt/version5.png";
import version4 from "../../assets/ppt/version4.png";
import version3 from "../../assets/ppt/version3.png";
import version2 from "../../assets/ppt/version2.png";
import version1 from "../../assets/ppt/version1.png";
import dragon from "../../assets/ppt/dragon.png";
import deepdark from "../../assets/ppt/deepdark.png";

import bluePreview from "../../assets/pptPreviewBg/blue.jpg";
import greenPreview from "../../assets/pptPreviewBg/green.jpg";
import lightbluePreview from "../../assets/pptPreviewBg/lightblue.jpg";
import purplePreview from "../../assets/pptPreviewBg/purple.jpg";
import taupePreview from "../../assets/pptPreviewBg/taupe.jpg";
import telecomGreenPreview from "../../assets/pptPreviewBg/telecomGreen.jpg";
import telecomRedPreview from "../../assets/pptPreviewBg/telecomRed.jpg";
import version10Preview from "../../assets/pptPreviewBg/version10.jpg";
import version9Preview from "../../assets/pptPreviewBg/version9.jpg";
import version8Preview from "../../assets/pptPreviewBg/version8.jpg";
import version7Preview from "../../assets/pptPreviewBg/version7.jpg";
import version6Preview from "../../assets/pptPreviewBg/version6.jpg";
import version5Preview from "../../assets/pptPreviewBg/version5.jpg";
import version4Preview from "../../assets/pptPreviewBg/version4.jpg";
import version3Preview from "../../assets/pptPreviewBg/version3.jpg";
import version2Preview from "../../assets/pptPreviewBg/version2.jpg";
import version1Preview from "../../assets/pptPreviewBg/version1.jpg";
import dragonPreview from "../../assets/pptPreviewBg/dragon.jpg";
import deepdarkPreview from "../../assets/pptPreviewBg/deepdark.jpg";

export const TEMPLATES = {
  blue,
  green,
  lightblue,
  purple,
  taupe,
  telecomGreen,
  telecomRed,
  stagePrelude: version10,
  brocadeDragonSoar: version9,
  whisperingWaves: version8,
  nightSkyMirror: version7,
  dawnMistRising: version6,
  auroraMiracle: version5,
  sereneSnowMountain: version4,
  crystalFrost: version3,
  rippleWaters: version2,
  starryJourney: version1,
  dragon,
  deepdark,
};

export const TEMPLATE_THEME = {
  blue: { bg: bluePreview, titleColor: "#fff", subTitleColor: "#34D1F2" },
  green: { bg: greenPreview, titleColor: "#071417", subTitleColor: "#51C0A6" },
  lightblue: {
    bg: lightbluePreview,
    titleColor: "#071417",
    subTitleColor: "#7295F8",
  },
  purple: {
    bg: purplePreview,
    titleColor: "#071417",
    subTitleColor: "#B760ED",
  },
  taupe: { bg: taupePreview, titleColor: "#071417", subTitleColor: "#DD742F" },
  telecomGreen: {
    bg: telecomGreenPreview,
    titleColor: "#071417",
    subTitleColor: "#5EBAB1",
  },
  telecomRed: {
    bg: telecomRedPreview,
    titleColor: "#071417",
    subTitleColor: "#F9AC96",
  },
  stagePrelude: {
    bg: version10Preview,
    titleColor: "#CD9B63",
    subTitleColor: "#F0A5A5",
  },
  brocadeDragonSoar: {
    bg: version9Preview,
    titleColor: "#F6E6A9",
    subTitleColor: "#E96C54",
  },
  whisperingWaves: {
    bg: version8Preview,
    titleColor: "#D7964F",
    subTitleColor: "#8ACAFB",
  },
  nightSkyMirror: {
    bg: version7Preview,
    titleColor: "#FA666E",
    subTitleColor: "#03AADF",
  },
  dawnMistRising: {
    bg: version6Preview,
    titleColor: "#4E5199",
    subTitleColor: "#6B94BE",
  },
  auroraMiracle: {
    bg: version5Preview,
    titleColor: "#FD7C3B",
    subTitleColor: "#F4A6A4",
  },
  sereneSnowMountain: {
    bg: version4Preview,
    titleColor: "#0250AF",
    subTitleColor: "#2D6D91",
  },
  crystalFrost: {
    bg: version3Preview,
    titleColor: "#5197FC",
    subTitleColor: "#415E76",
  },
  rippleWaters: {
    bg: version2Preview,
    titleColor: "#0DD3F1",
    subTitleColor: "#DAC9BC",
  },
  starryJourney: {
    bg: version1Preview,
    titleColor: "#0055FF",
    subTitleColor: "#0C3484",
  },
  dragon: {
    bg: dragonPreview,
    titleColor: "#F8B62C",
    subTitleColor: "#F7D18E",
  },
  deepdark: {
    bg: deepdarkPreview,
    titleColor: "#EC9F48",
    subTitleColor: "#9B754D",
  },
};
