import { makeAutoObservable, runInAction } from "mobx";
import { sleep } from "../../helper";
import { post, get } from "../../axios/axios";
import { uniqueId } from "lodash";
import { message } from "antd";

class NewPPTStore {
  curStep = 0;
  nextLoading = false;
  uid = "";
  hs = 0; // 是否提交过生成PPT
  steps = [
    {
      title: "标题",
    },
    {
      title: "大纲",
    },
    {
      title: "模板",
    },
  ];
  // 标题
  step1 = {
    title: "",
    type: undefined,
    isCardNote: 0,
    canSubmit: false,
    writingRecordId: "",
  };

  // 大纲
  step2 = {
    focusIdx: -1,
    mainTitle: "",
    subTitle: "",
    primarySections: [],
  };
  step3 = {
    focusIdx: -1,
    selectIdx: 1,
    templates: [],
  };

  pptTypes = [
    {
      label: "开题答辩",
      value: 1,
    },
    {
      label: "结题答辩",
      value: 2,
    },
  ];

  constructor() {
    makeAutoObservable(this);
    this.reset();
    this.getTypeList();
  }

  reset() {
    this.curStep = 0;
    this.nextLoading = false;
    this.hs = 0;
    this.steps = [
      {
        title: "标题",
      },
      {
        title: "大纲",
      },
      {
        title: "模板",
      },
    ];
    this.step1 = {
      title: "",
      type: undefined,
      isCardNote: 0,
      canSubmit: false,
      writingRecordId: "",
    };
    this.step2 = {
      selectIdx: -1,
      focusIdx: -1,
      mainTitle: "",
      subTitle: "",
      primarySections: [],
    };

    this.step3 = {
      focusIdx: -1,
      selectIdx: 1,
      templates: [
        {
          name: "purple",
          value: "紫色主题",
        },
        {
          name: "green",
          value: "绿色主题",
        },
        {
          name: "lightblue",
          value: "清逸天蓝",
        },
        {
          name: "taupe",
          value: "质感之境",
        },
        {
          name: "blue",
          value: "星光夜影",
        },
        {
          name: "telecomRed",
          value: "炽热暖阳",
        },
        {
          name: "telecomGreen",
          value: "幻翠奇旅",
        },
        { name: "stagePrelude", value: "舞台序曲" },
        { name: "brocadeDragonSoar", value: "锦绣龙腾" },
        { name: "whisperingWaves", value: "海浪呢喃" },
        { name: "nightSkyMirror", value: "夜空之镜" },
        { name: "dawnMistRising", value: "晨雾初升" },
        { name: "auroraMiracle", value: "极光奇迹" },
        { name: "sereneSnowMountain", value: "雪山静谧" },
        { name: "crystalFrost", value: "晶莹霜华" },
        { name: "rippleWaters", value: "水波涟漪" },
        { name: "starryJourney", value: "星辰旅途" },
        { name: "dragon", value: "龙韵新禧" },
        { name: "deepdark", value: "墨韵金章" },
      ],
    };
  }

  async getTypeList() {
    const { data } = await get("/ai/ai/ppt/type/list");
    this.pptTypes = data.map((item) => ({
      value: item.id,
      label: item.remark,
      ...item,
    }));
  }

  async step1Submit() {
    this.nextLoading = true;
    this.step2.focusIdx = -1;
    try {
      const { data = {} } = await post("/ai/ai/ppt/gen/outline", {
        title: this.step1.title,
        type: this.step1.type,
        uid: this.uid,
        isCardNote: this.step1.isCardNote,
        writingRecordId: this.step1.writingRecordId
          ? this.step1.writingRecordId
          : undefined,
      });
      this.step2.mainTitle = data.mainTitle;
      this.step2.subTitle = data.subTitle;
      this.step2.primarySections = data.primarySections.map((d) => ({
        id: uniqueId("step2"),
        ...d,
      }));

      this.nextLoading = false;
      this.curStep = 1;
    } catch (error) {
      this.nextLoading = false;
      console.error(error);
    }
  }
  async step2Submit() {
    this.nextLoading = true;
    try {
      // const res = await get("/ai/ai/ppt/themeList", {});
      // this.step3.templates = [...res.data];
      this.nextLoading = false;
      this.curStep = 2;
    } catch (error) {
      this.nextLoading = false;
    }
  }

  goLast() {
    this.curStep--;
    if (this.curStep < 0) {
      this.curStep = 0;
    }
  }
  goFinish() {}

  step1Change(a, b) {
    console.log(b);
    const { title, type, isCardNote } = b;
    this.step1.title = title;
    this.step1.type = type;
    this.step1.isCardNote = isCardNote;
    if (title && type && [0, 1].includes(isCardNote)) {
      this.step1.canSubmit = true;
    } else {
      this.step1.canSubmit = false;
    }
  }

  step2Foucs(i) {
    this.step2.focusIdx = i;
  }
  step2DragEnd(result) {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      this.step2.primarySections,
      result.source.index,
      result.destination.index
    );
    this.step2.primarySections = items;
  }
  step2Del(i) {
    this.step2.primarySections.splice(i, 1);
    this.step2.primarySections = [...this.step2.primarySections];
  }
  step2Add() {
    this.step2.primarySections.push({
      id: uniqueId("step2"),
      title: "",
      secondarySections: [
        {
          title: "",
          tertiaryTitles: [""],
        },
      ],
    });
  }

  step2Change(index1, v) {
    this.step2.primarySections[index1].title = v;
    this.step2.primarySections = [...this.step2.primarySections];
  }
  step2ChangeSub(index1, index2, v) {
    this.step2.primarySections[index1].secondarySections[index2].title = v;
    this.step2.primarySections = [...this.step2.primarySections];
  }
  step2DelSub(index1, index2, index3) {
    if (index3 === undefined) {
      this.step2.primarySections[index1].secondarySections.splice(index2, 1);
    } else {
      this.step2.primarySections[index1].secondarySections[
        index2
      ].tertiaryTitles.splice(index3, 1);
    }
    this.step2.primarySections = [...this.step2.primarySections];
  }
  step2AddSub(index1, index2, index3) {
    if (index3 === undefined) {
      this.step2.primarySections[index1].secondarySections.splice(
        index2 + 1,
        0,
        {
          id: uniqueId("step2"),
          title: "",
          tertiaryTitles: [""],
        }
      );
    } else {
      this.step2.primarySections[index1].secondarySections[
        index2
      ].tertiaryTitles.splice(index3 + 1, 0, "");
    }

    this.step2.primarySections = [...this.step2.primarySections];
  }
  step2ChangeThird(index1, index2, index3, v) {
    this.step2.primarySections[index1].secondarySections[index2].tertiaryTitles[
      index3
    ] = v;
    this.step2.primarySections = [...this.step2.primarySections];
  }

  step3Focus(idx) {
    this.step3.focusIdx = idx;
  }

  step3Blur(e) {
    e.stopPropagation();
    this.step3.focusIdx = -1;
  }

  async step3Submit() {
    this.nextLoading = true;

    try {
      await post("/ai/ai/ppt/w", {
        uid: this.uid,
        pptOutline: {
          mainTitle: this.step2.mainTitle,
          subTitle: this.step2.subTitle,
          primarySections: this.step2.primarySections,
        },
        pptThemeType: this.step3.templates[this.step3.focusIdx].name,
        isCardNote: this.step1.isCardNote,
      });
      this.nextLoading = false;
    } catch (err) {
      this.nextLoading = false;
    }
  }

  async createPPT() {
    const res = await post("/ai/ai/ppt/w", {
      uid: this.uid,
      pptOutline: {
        mainTitle: this.step2.mainTitle,
        subTitle: this.step2.subTitle,
        primarySections: this.step2.primarySections,
      },
      pptThemeType: this.step3.templates[this.step3.focusIdx].name,
      isCardNote: this.step1.isCardNote,
    });
    return res;
  }
}
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export const newPPTStore = new NewPPTStore();
