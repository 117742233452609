import styles from "./RewriteEditor.module.scss";
import {
  Tooltip,
  Tabs,
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  message,
  ConfigProvider,
  Popconfirm,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ReactQuill, { Quill } from "react-quill";
import ClipboardJS from "clipboard";
import { useState, useRef, useEffect } from "react";
import { initData } from "./mock";
import { post } from "../../axios/axios";
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function convertToDelta(data) {
  const delta = [];

  data.forEach(([text, ranges]) => {
    let lastEnd = 0;
    ranges.forEach(([start, end]) => {
      if (start > lastEnd) {
        delta.push({ insert: text.substring(lastEnd, start) });
      }
      delta.push({
        insert: text.substring(start, end),
        attributes: { background: "yellow", underline: true },
      });
      lastEnd = end;
    });
    if (lastEnd < text.length) {
      delta.push({ insert: text.substring(lastEnd) });
    }
    delta.push({ insert: "\n" }); // 添加一行空白行
  });

  return delta;
}
const submitRewrite = async (sourceEditor, level) => {
  try {
    const txt = sourceEditor.current.getEditor().getText();
    if (!txt || !level) return;
    const res = await post("/ai/text/r", {
      text: txt,
      level: level,
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};
const ReWriteEditor = (props) => {
  const [articleTitle, setArticleTitle] = useState(props.title || "未命名");
  const RewriteQuillRef = useRef(null);
  const [lockEditor, setLockEditor] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await submitRewrite(props.sourceEditor, props.level);
      if (res && res.data && RewriteQuillRef && RewriteQuillRef.current) {
        const delta = convertToDelta(res.data);
        RewriteQuillRef.current.getEditor().setContents(delta);
      }

      setLoading(false);
    })();
  }, [props.sourceEditor, props.level, props.rewriteCount]);

  const handleClickRewrite = async () => {
    props.handleReWrite(props.level || "L2");
  };

  const handleCopy = () => {
    const text = RewriteQuillRef.current.getEditor().getText();
    var clipboard = new ClipboardJS("#copyRewrite", {
      text: function () {
        return text;
      },
    });

    clipboard.on("success", function (e) {
      console.log("success");
      messageApi.success("复制成功");
    });

    clipboard.on("error", function (e) {
      console.log("Failed to copy to clipboard!");
    });
  };
  const handleReplace = () => {
    props.replace &&
      props.replace(RewriteQuillRef.current.getEditor().getContents());
  };
  const handleExit = () => {
    props.exit && props.exit();
  };

  return (
    <div className={styles.rewriteEditor}>
      {contextHolder}
      <div className={styles.title}>
        <TextArea
          className={styles.input}
          maxLength={150}
          placeholder="请输入标题"
          autoSize={{ minRows: 1, maxRows: 5 }}
          value={articleTitle}
          onChange={(e) => {
            let str = e.target.value;
            str = str.replace(/[\r\n]/g, " ");
            str = str.replace(/\ +/g, " ");
            setArticleTitle(str);
          }}
          readOnly={lockEditor}
        />
      </div>
      <div className={styles.content}>
        <Spin indicator={antIcon} spinning={loading}>
          <ReactQuill
            className="notranslate"
            ref={RewriteQuillRef}
            theme="snow"
            readOnly={lockEditor}
            placeholder="给青秧一点提示，它就会辅助您获取灵感，该自己写的还是要自己写哦！"
            onChange={(v) => {
              if (!RewriteQuillRef.current) {
                return;
              }
              const e = RewriteQuillRef.current.getEditor();
              const len = e.getLength();
              const limit = 50000;
              if (len > limit) {
                e.deleteText(limit, len);
              }
            }}
            modules={{
              toolbar: {
                container: "#toolbar",
              },
            }}
          />
        </Spin>
      </div>
      <div className={styles.toolbar}>
        <Spin indicator={antIcon} spinning={loading}>
          <div className={styles.toolbarBtn} onClick={handleClickRewrite}>
            全文改写
          </div>
          <div
            id="copyRewrite"
            className={styles.toolbarBtn}
            onClick={handleCopy}
          >
            一键复制
          </div>
        </Spin>
        <Spin indicator={antIcon} spinning={loading}>
          <div className={styles.toolbarBtn} onClick={handleReplace}>
            一键替换
          </div>
        </Spin>

        <div className={styles.toolbarBtn} onClick={handleExit}>
          退出改写
        </div>
      </div>
    </div>
  );
};

export default ReWriteEditor;
