import { Modal, QRCode, Button, Alert, message, Input, Spin } from "antd";
import { useState, useEffect, useRef } from "react";
import { post } from "../../axios/axios";

export const RedeemCodeModal = (props) => {
  const [redeemCode, setRedeemCode] = useState("");
  const submitRedeemCode = async () => {
    try {
      const res = await post("/member/payment/redeemCode", {
        orderNo: props.orderNo,
        code: redeemCode,
      });
      console.log("res", res);
      if (res && res.code === 0) {
        message.info("正在加速生成中");
        props.handleOk && props.handleOk(1);
      } else {
        const msg = res && res.msg;
        message.error(
          msg || "请检查您输入的兑换码是否正确，如果错误请重新输入。"
        );
      }
    } catch (error) {
      console.error(error);
      // message.error("请检查您输入的兑换码是否正确，如果错误请重新输入。");
    }
  };
  return (
    <div>
      <Input
        style={{ marginTop: "16px" }}
        placeholder="请输入兑换码"
        value={redeemCode}
        onChange={(e) => setRedeemCode(e.target.value)}
      />
      <div
        style={{ marginTop: "20px", justifyContent: "flex-end" }}
        className="flex"
      >
        <Button type="primary" onClick={submitRedeemCode}>
          确定
        </Button>
      </div>
    </div>
  );
};
