import classNames from 'classnames'
import styles from './index.module.scss'
import {
  Checkbox,
  Col,
  Row,
  Radio,
  Collapse,
  Button,
  message,
  Steps, ConfigProvider
} from 'antd'
import { reduceStore } from './store'
import { observer } from 'mobx-react-lite'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useEffect } from 'react'
import { useInterval } from 'usehooks-ts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import cx from 'classnames'
import copyIcon from '../../assets/copyIcon.svg'
import waitImage from '../../assets/waitImage.svg'
import noSelect from '../../assets/noSelect.svg'
import { isMobile } from '../../common/const'

const Block = (props) => {
  const { showCopy, position } = props

  return (
    <div
      id={props.id}
      className={cx(
        styles.listItem,
        props.active ? styles.active : '',
        props.origin ? styles.origin : '',
        position === 'left' ? styles.blockLeft : styles.blockRight
      )}
    >
      <div className={styles.cardBox}>
        <div className={styles.txtCnt}>
          <span className={'text-[#666] text-wrap'}>
            段落字数：{props.words}字
          </span>
          {showCopy && (
            <CopyToClipboard
              text={props.text}
              onCopy={() => message.success('复制成功')}
            >
              <Button type="text" className={styles.copy}>
                <img src={copyIcon} className={'inline'}/>
                <span className={'text-[#00CE72]'}>复制</span>
              </Button>
            </CopyToClipboard>
          )}
        </div>
        <div className={'h-[1px] w-full bg-[#F0F0F0] mb-3'}></div>
        <div className={styles.text}>{props.text}</div>
      </div>
    </div>
  )
}

const MiniMap = (props) => {
  function scrollTo (id) {
    const el = document.getElementById(id)
    el.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className={styles.miniMap}>
      <div className={styles.mapWrap}>
        {props.list.map((item, index) => {
          return (
            <div
              className={classNames(styles.anchor, {
                [styles.highlight]: !!item.recommend
              })}
              onClick={() => scrollTo(item.id)}
            />
          )
        })}
      </div>
    </div>
  )
}

export const Step3 = observer(() => {
  const nav = useNavigate()
  const { step3 } = reduceStore

  useEffect(() => {
    reduceStore.onStep3GetOrderInfo({ slient: true })
  }, [])
  useInterval(
    () => {
      // Your custom logic here
      reduceStore.onStep3GetOrderInfo({ slient: true })
    },
    // Delay in milliseconds or null to stop it
    reduceStore.step3.status === 1 ? null : 10000
  )

  function refresh () {
    reduceStore.onStep3GetOrderInfo()
  }

  function getAnother () {
    window.open('https://cqingyoung.com/reduce', '_blank')
  }

  return (
    <div className={styles.step3}>
      {step3.status === 1 ? (
        <>
          <MiniMap list={reduceStore.step3.list}/>
          <div className={styles.reduceRes}>
            <div
              className={'scale-75 -translate-x-5 lg:-translate-x-0 lg:scale-100 lg:mb-6'}>
              <Steps
                current={reduceStore.curStep}
                onChange={reduceStore.onNewStepChange}
                className={'site-navigation-steps'}
                items={reduceStore.steps}
                direction="horizontal"
                style={{
                  flexDirection: isMobile ? 'row' : 'initial'
                }}
              />
            </div>

            <div className={styles.resHeader}>
              <div className={styles.headerItem}>
                <span>原文：全文报告单-我的1 (2)</span>
              </div>
              <div className={styles.headerItem}>
                <span className={'text-[#00CE72]'}>降重后</span>
              </div>
            </div>
            {reduceStore.step3.list.map((l, idx) => {
              return (
                <div className={styles.list} style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: '24px'
                }} key={l.id}>
                  <Block
                    text={l.text}
                    active={l.recommend ? true : false}
                    id={l.id}
                    words={l.words}
                    origin={true}
                    position={'left'}
                  />
                  {l.recommend ? (
                    <Block
                      id={l.recommend.id}
                      showCopy
                      active
                      text={l.recommend.text}
                      words={l.recommend.words}
                      position={'right'}
                    />
                  ) : (<div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: '1px solid #F0F0F0',
                    borderRadius: '12px',
                    minHeight: '158px'
                  }}>
                    <div>
                      <img className={'w-[92px] h-[92px]'} src={noSelect}
                           alt=""/>
                    </div>
                    <div
                      className={'text-[14px] text-[#999]'}>未选择此段落降重
                    </div>
                  </div>)}
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <div className={styles.wait}>
          <img
            src={waitImage}
            className={styles.waitImg}
          />
          <div className={'text-[#00CE72]'}>
            提交成功
          </div>
          <div className={styles.tip} style={{
            marginTop: '8px',
            marginBottom: '12px'
          }}>
            您可在“首页-查询订单”中查看降重结果！
          </div>
          <div className={styles.waitDesc} style={{
            marginTop: 0,
            color:'#999999',
            fontSize:'16px'
          }}>
            降重结果一般需要5～10分钟（高峰期所需时间更长）退出页面不影响降重结果哦～
          </div>
          <div className={styles.footer}>
            <Button style={{
              width: '176px',
              height: '56px',
              backgroundColor: '#F5F8F9'
            }} size="large" onClick={refresh}>
              刷新结果
            </Button>
            <Button
              onClick={getAnother}
              size="large"
              type="primary"
              style={{
                width: '176px',
                height: '56px'
              }}
              className={styles.rightBtn}
            >
              再来一篇
            </Button>
          </div>
        </div>
      )}
    </div>
  )
})
