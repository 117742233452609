export const BILL_MAP = {
  MONTH: [],
  YEAR: [],
};

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const isWexin = /micromessenger/.test(navigator.userAgent.toLowerCase());
