import { Modal, ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import { RedeemCodeModal } from "./RedeemCodeModal";

import {
  legacyLogicalPropertiesTransformer,
  StyleProvider,
} from "@ant-design/cssinjs";
import { globalData } from "../../Service/utils";

export function Redeem(props = {}) {
  const div = document.createElement("div");
  document.body.appendChild(div);
  const root = createRoot(div);

  function remove() {
    root.unmount();
    div.parentNode.removeChild(div);
  }

  return new Promise((resolve) => {
    const handleOk = (result) => {
      // submit to server success
      globalData.timerId && window.clearTimeout(globalData.timerId);
      resolve(result);

      remove();
    };

    const handleCancel = () => {
      globalData.timerId && window.clearTimeout(globalData.timerId);
      resolve(false);
      remove();
    };

    root.render(
      <StyleProvider
        hashPriority="high"
        transformers={[legacyLogicalPropertiesTransformer]}
      >
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#24be58",
              colorInfoBg: "#fffbe6",
              colorInfoActive: "#f7941d",
              colorInfoText: "#f7941d",
            },
          }}
        >
          <Modal
            destroyOnClose={true}
            open={true}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            bodyStyle={{ padding: "0px" }}
            className="buy-modal"
            width={790}
            title="请输入兑换码"
          >
            <RedeemCodeModal
              {...props}
              handleCancel={handleCancel}
              handleOk={handleOk}
            />
          </Modal>
        </ConfigProvider>
      </StyleProvider>
    );
  });
}
