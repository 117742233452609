export const TableIcon = (
  <svg
    fill="none"
    width="14"
    height="14"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 8V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v2M3 8v6m0-6h6m12 0v6m0-6H9m12 6v4a2 2 0 0 1-2 2H9m12-6H9m-6 0v4a2 2 0 0 0 2 2h4m-6-6h6m0-6v6m0 0v6m6-12v12"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    ></path>
  </svg>
);

export const PicIcon = (
  <svg
    t="1713872268020"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="4917"
    width="14"
    height="14"
  >
    <path
      d="M914 652.13h-47.35V504.26c0-20.81-16.87-37.68-37.68-37.68H557.41v-94.72h62.82c20.81 0 37.68-16.87 37.68-37.68V148.65c0-20.81-16.87-37.68-37.68-37.68H434.69c-20.81 0-37.68 16.87-37.68 37.68v185.54c0 20.81 16.87 37.68 37.68 37.68h47.37v94.72H210.5c-20.81 0-37.68 16.87-37.68 37.68v147.87H110c-20.81 0-37.68 16.87-37.68 37.68v185.54c0 20.81 16.87 37.68 37.68 37.68h185.54c20.81 0 37.68-16.87 37.68-37.68V689.81c0-20.81-16.87-37.68-37.68-37.68h-47.37V541.94h233.89v110.19h-62.82c-20.81 0-37.68 16.87-37.68 37.68v185.54c0 20.81 16.87 37.68 37.68 37.68h185.54c20.81 0 37.68-16.87 37.68-37.68V689.81c0-20.81-16.87-37.68-37.68-37.68h-47.37V541.94H791.3v110.19h-62.84c-20.81 0-37.68 16.87-37.68 37.68v185.54c0 20.81 16.87 37.68 37.68 37.68H914c20.81 0 37.68-16.87 37.68-37.68V689.81c0-20.81-16.87-37.68-37.68-37.68zM472.37 186.32h110.19v110.19H472.37V186.32z m-214.5 651.36h-110.2v-110.2h110.19v110.2z m309.24 0h-110.2v-110.2H567.1v110.2z m309.22 0h-110.2v-110.2h110.19v110.2z"
      fill="currentColor"
      p-id="4918"
    ></path>
  </svg>
);
export const GongshiIcon = (
  <svg
    t="1713872121151"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="2393"
    width="14"
    height="14"
  >
    <path
      d="M414.896552 120.712109H169.777065c-29.151564 0-52.554932 23.403368-52.554932 52.554932V418.797113c0 29.151564 23.403368 52.554932 52.554932 52.554932h245.119487c29.151564 0 52.554932-23.403368 52.554932-52.554932V173.267041c0-28.740978-23.403368-52.554932-52.554932-52.554932z m-13.138733 197.080994H314.303128v87.454692H270.370489V317.793103H182.915798V274.271051h87.454691V186.405774H314.303128v87.454691h87.454691v43.932638z m451.23336-197.080994h-245.119487c-29.151564 0-52.554932 23.403368-52.554932 52.554932V418.797113c0 29.151564 23.403368 52.554932 52.554932 52.554932h245.119487c29.151564 0 52.554932-23.403368 52.554932-52.554932V173.267041c0-28.740978-23.403368-52.554932-52.554932-52.554932z m-13.138733 197.080994h-218.842021V274.271051H839.852446v43.522052zM414.896552 558.806736H169.777065c-29.151564 0-52.554932 23.403368-52.554932 52.554932v245.119487c0 29.151564 23.403368 52.554932 52.554932 52.554932h245.119487c29.151564 0 52.554932-23.403368 52.554932-52.554932v-245.119487c0-29.151564-23.403368-52.554932-52.554932-52.554932z m-29.562149 237.318364l-30.793906 30.793906-61.998396-61.998397-61.998396 61.998397-30.793905-30.793906 61.998396-61.998396L199.339214 672.128308l30.793905-30.793905 61.998397 61.998396 61.998396-61.998396 30.793905 30.793905-61.998396 61.998396 62.408982 61.998396z m467.656776-237.318364h-245.119487c-29.151564 0-52.554932 23.403368-52.554932 52.554932v245.119487c0 29.151564 23.403368 52.554932 52.554932 52.554932h245.119487c29.151564 0 52.554932-23.403368 52.554932-52.554932v-245.119487c0-29.151564-23.403368-52.554932-52.554932-52.554932z m-34.89976 241.013633H642.771451v-43.932638h175.319968v43.932638z m0-87.865277H642.771451v-43.932638h175.319968v43.932638z m0 0"
      fill="currentColor"
      p-id="2394"
    ></path>
  </svg>
);

export const CodeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <g fill="none">
      <path d="M0 0h24v24H0z"></path>
      <path
        fill="currentColor"
        d="M14.486 3.143a1 1 0 0 1 .692 1.233l-4.43 15.788a1 1 0 0 1-1.926-.54l4.43-15.788a1 1 0 0 1 1.234-.693M7.207 7.05a1 1 0 0 1 0 1.414L3.672 12l3.535 3.535a1 1 0 1 1-1.414 1.415L1.55 12.707a1 1 0 0 1 0-1.414L5.793 7.05a1 1 0 0 1 1.414 0m9.586 1.414a1 1 0 1 1 1.414-1.414l4.243 4.243a1 1 0 0 1 0 1.414l-4.243 4.243a1 1 0 0 1-1.414-1.415L20.328 12z"
      ></path>
    </g>
  </svg>
);
