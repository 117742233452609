import React, { useEffect, useMemo, useState } from "react";
import { Button, Layout, Menu, message, Popover } from "antd";
import Sider from "antd/es/layout/Sider";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import styles from "./index.module.scss";
import {
  CreditCardFilled,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import logo from "../assets/logo11.png";
import { UserDropdown } from "../components/UserDropdown";
import { About } from "../components/About";
import { loginInstance } from "../Service/login";
import { useLayoutEffect } from "react";
import openImg from "../assets/open.png";
import { Pay } from "./Pay";
import { isWexin } from "../common/const";

export function Article() {
  const [qs] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  let { pathname } = useLocation();
  const selectedKey = useMemo(() => {
    return pathname.replace("/dashboard/", "").replace("/", "");
  }, [pathname]);
  const [showPay, setShowPay] = useState(qs.get("code") && qs.get("state"));
  const nav = useNavigate();

  const logout = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  useLayoutEffect(() => {}, []);

  return (
    <>
      {contextHolder}
      <Layout style={{ minHeight: "100vh" }}>
        <Layout>
          {showPay ? (
            <Pay
              orderNo={qs.get("state")}
              code={qs.get("code")}
              state={qs.get("state")}
            />
          ) : (
            <Outlet />
          )}
        </Layout>
      </Layout>
    </>
  );
}
