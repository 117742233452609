import styles from "./index.module.scss";
import { Button } from "antd";

import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { get } from "../../axios/axios";

export const PayResult = () => {
  const [qs] = useSearchParams();
  const orderNo = qs.get("orderNo");
  const [baseInfo, setBaseInfo] = useState({});
  const nav = useNavigate();

  async function getBaseInfo() {
    const res = await get("/ai/paper/order/info?orderNo=" + orderNo);
    setBaseInfo(res.data);
  }

  function back() {
    nav("/");
  }
  useEffect(() => {
    getBaseInfo();
  }, []);
  return (
    <div className={styles.payRes}>
      <div className={styles.payTop}>
        <svg
          t="1709453995149"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4500"
          width="64"
          height="64"
        >
          <path
            d="M512.04096 1023.67232h-1.3312C228.61824 1022.95552-0.32768 792.84224 0.34816 510.70976c0.32768-136.66304 53.8624-265.03168 150.77376-361.472 96.5632-96.03072 224.6656-148.8896 360.83712-148.8896h1.3312c136.66304 0.32768 265.03168 53.82144 361.472 150.7328 96.3584 96.89088 149.23776 225.50528 148.8896 362.20928-0.32768 136.66304-53.82144 265.03168-150.7328 361.41056a508.33408 508.33408 0 0 1-360.8576 148.97152z m-0.08192-949.76A435.26144 435.26144 0 0 0 202.99776 201.4208a435.2 435.2 0 0 0-129.08544 309.47328c-0.57344 241.5616 195.4816 438.5792 436.98176 439.1936h1.14688c116.59264 0 226.304-45.2608 308.96128-127.54944a435.0976 435.0976 0 0 0 129.08544-309.4528 435.2 435.2 0 0 0-127.50848-310.08768 435.2 435.2 0 0 0-309.47328-129.08544h-1.14688z"
            fill="#42BB4B"
            p-id="4501"
          ></path>
          <path
            d="M451.54304 716.24704a36.80256 36.80256 0 0 1-27.3408-61.39904L725.8112 319.488a36.80256 36.80256 0 0 1 54.70208 49.23392L478.90432 704.1024a36.80256 36.80256 0 0 1-27.36128 12.16512z"
            fill="#42BB4B"
            p-id="4502"
          ></path>
          <path
            d="M448.57344 716.67712c-9.76896 0-19.53792-3.8912-26.76736-11.55072l-177.7664-188.7232a36.82304 36.82304 0 0 1 1.536-52.0192 36.82304 36.82304 0 0 1 52.0192 1.536l177.7664 188.7232a36.82304 36.82304 0 0 1-26.78784 62.03392z"
            fill="#42BB4B"
            p-id="4503"
          ></path>
        </svg>
      </div>

      <div style={{ color: "#666" }}>支付成功</div>
      <div style={{ color: "#666", margin: "10px 0" }}>
        青秧灵感——{baseInfo.name}
      </div>
      <div className={styles.payMoney}>
        <span className={styles.unit}>￥</span>
        <span>{baseInfo.price}</span>
      </div>

      <div className={styles.payFooter}>
        <Button onClick={back} size="large">
          再写一篇
        </Button>
        <Button
          size="large"
          type="primary"
          className={styles.right}
          onClick={() => nav("/download?orderNo=" + orderNo)}
        >
          下载论文
        </Button>
      </div>
    </div>
  );
};
