import { Button, message, Spin } from "antd";
import { get, post } from "../../axios/axios";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export function Pay(props) {
  const [baseInfo, setBaseInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const [timerId, setTimerId] = useState("");
  const [fetching, setFetching] = useState(true);

  async function getBaseInfo() {
    try {
      const res = await get("/ai/paper/order/info?orderNo=" + props.orderNo);
      setBaseInfo(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  }
  useEffect(() => {
    getBaseInfo();
    // checkPay()
  }, []);

  // const checkPay = async () => {
  //   if (timerId) {
  //     window.clearTimeout(timerId);
  //   }

  //   try {
  //     const res = await get("/member/payment/pay/result/" + props.orderNo);
  //     if (res && res.data && res.data.code === 0) {
  //       nav("/success?payNo=" + props.orderNo);
  //     } else {
  //       const id = window.setTimeout(() => {
  //         checkPay();
  //       }, 1000);
  //       setTimerId(id);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  async function prePay() {
    try {
      if (loading) return;
      setLoading(true);
      const res = await get(
        `/member/wechat/code?code=${props.code}&state=${props.state}`
      );

      if (res && res.code === 1) {
        message.error(res.msg || "网络异常");
        setLoading(false);
        return;
      }

      const { data: payData } = await post("/member/payment/pay", {
        orderNo: props.orderNo,
        client: "WeChat",
      });

      // 从后端获取的支付参数
      const paymentData = {
        appId: payData.appid, // 公众号名称，由商户传入
        timeStamp: payData.timeStamp, // 时间戳，自1970年以来的秒数
        nonceStr: payData.nonceStr, // 随机串
        package: `prepay_id=${payData.prepayId}`, // 预支付交易会话标识
        signType: payData.signType, // 微信签名方式：
        paySign: payData.sign, // 微信签名
      };

      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }

      function onBridgeReady() {
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: paymentData.appId, // 公众号名称，由商户传入
            timeStamp: paymentData.timeStamp, // 时间戳，自1970年以来的秒数
            nonceStr: paymentData.nonceStr, // 随机串
            package: paymentData.package,
            signType: paymentData.signType, // 微信签名方式：
            paySign: paymentData.paySign, // 微信签名
          },
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              nav(`/success?orderNo=${props.orderNo}&payNo=${props.orderNo}`);
            } else {
              message.error("支付未成功！");
            }
            setLoading(false);
          }
        );
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className={styles.pay}>
      <Spin spinning={fetching}>
        <div className={styles.payInfo}>
          <span className={styles.payUnit}>￥</span>
          <span>{baseInfo.price}</span>
        </div>
        <div className={styles.orderInfo}>{baseInfo.name}</div>
      </Spin>

      <Button
        size="large"
        type="primary"
        className={styles.payButton}
        onClick={prePay}
        loading={loading}
      >
        去支付
      </Button>
    </div>
  );
}
