import "./LoginForm.scss";
import logo from "../../../assets/logo11.png";
import { LOGIN_TABS } from "./const";
import { Tabs } from "antd";
import { Button, Form, Input, Select } from "antd";
import { useState } from "react";
import { EmailForm } from "./EmailForm";
import { TelForm } from "./TelForm";
import { RegisterForm } from "./RegisterForm";
import weixinIcon from "../../../assets/weixin_icon.png";
export function LoginForm(props) {
  const [isLogin, setIsLogin] = useState(true);
  const [isReset, setIsReset] = useState(true);
  const [activeKey, setActiveKey] = useState(1);
  const [title, setTitle] = useState("注册账号");
  const onChange = (key) => {
    setActiveKey(key);
  };

  const toForgetPassword = () => {
    setIsLogin(false);
    setTitle("重置密码");
  };

  const toRegister = () => {
    setIsLogin(false);
    setTitle("注册账号");
  };
  const toLogin = () => {
    setTitle("");
    setIsLogin(true);
  };

  return (
    <div className="login-form">
      <div className="login-form-header">
        <img className="logo" src={logo} />
        <div>
          {isLogin ? (
            <Tabs
              activeKey={activeKey}
              items={LOGIN_TABS}
              onChange={onChange}
              size="large"
              className="login-tabs"
            />
          ) : (
            <div className="title">{title}</div>
          )}
        </div>
      </div>
      {isLogin ? (
        <div className="login-content">
          <div className="login-main">
            <div className="custom-form">
              {activeKey === 1 ? (
                <EmailForm toForgetPassword={toForgetPassword} {...props} />
              ) : (
                <TelForm {...props} />
              )}
            </div>
            <div className="clause">
              {activeKey === 1 ? (
                <p className="tip weixinTip">
                  <img className="weixin" src={weixinIcon} />
                  微信扫码登录，自动登录即代表同意
                </p>
              ) : (
                <p className="tip">未注册手机验证后自动登录，注册即代表同意</p>
              )}
              <p>
                <a href="https://modeai.cn/help/g.html" target="_blank">
                  《墨得问题隐私政策》
                </a>
                <span className="and">及</span>
                <a
                  href="https://modeai.cn/help/f.html"
                  target="_blank"
                  style={{ color: "#697581" }}
                >
                  《墨得问题用户协议》
                </a>
              </p>
            </div>
            {/* <div className="foot-login">
              <div className="tip">
                <span className="desc">其他登录方式</span>
              </div>
              <div className="register-row">
                <span className="register" onClick={toRegister}>
                  邮箱注册
                </span>
              </div>
            </div> */}
          </div>
        </div>
      ) : (
        <div className="login-content">
          <div className="login-main">
            <RegisterForm />
            <div className="clause">
              <span className="tip">注册即代表同意</span>
              <a href="/terms" target="_blank">
                《墨得问题隐私政策》
              </a>
              <span className="and">&nbsp;及&nbsp;</span>
              <a href="/privacypolicy" target="_blank">
                《墨得问题用户协议》
              </a>
            </div>
          </div>
          <div className="foot-login">
            <div className="foot-register">
              已有账号？
              <span className="login" onClick={toLogin}>
                立即登录
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
