import { Button, message } from "antd";
import { useEffect, useState } from "react";
import Input from "antd/es/input/Input";
import Search from "antd/es/input/Search";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios, { get, post } from "../../axios/axios";
import orderDemo from "../../assets/order-demo.jpg";
import styles from "./index.module.scss";
import { handleDownLoad } from "../../Service/utils";
// www.cqingyoung.com/download?orderNo=M1764444559609597952
export const Download = () => {
  const [qs] = useSearchParams();
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);
  const [orderNo, setOrderNo] = useState(qs.get("orderNo"));
  const downlodType = "paper";
  const [downloadUrl, setDownloadUrl] = useState("");
  console.log("xx", orderNo);

  function openUrl(url) {
    handleDownLoad(url);
  }

  const exportPaper = async () => {
    if (downloadUrl) {
      handleDownLoad(downloadUrl);
      return;
    }

    if (!orderNo) {
      message.error("请输入交易单号");
      return;
    }
    try {
      setLoading(true);
      const { data, msg } = await get(
        downlodType === "ppt"
          ? `/ai/ai/ppt/status/${orderNo}`
          : `/ai/paper/status/${orderNo}`
      );
      if (downlodType === "ppt") {
        if (data.url) {
          message.success("PPT已完成");
          handleDownLoad(data.url);
          setDownloadUrl(data.url);
          return;
        }
        if (data === null) {
          message.error(msg || "查询订单失败，请检查订单编号");
          return;
        }
        message.info("PPT正在加速生成中，请稍后重试");
      } else {
        if (data) {
          message.success("论文已完成，如未自动下载可再次手动下载");
          const url = "https://cqingyoung.com/ai/word/paper/export/" + orderNo;
          handleDownLoad(url);
          setDownloadUrl(url);
          return;
        }

        if (data === null) {
          message.error(msg || "查询订单失败，请检查订单编号");
        }

        if (data === false) {
          message.info("论文正在加速生成中，请稍后重试");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    setOrderNo(e.target.value);
  };

  return (
    <div className={styles.download}>
      <div className={styles.header} onClick={() => nav("/")}>
        青秧灵感
      </div>
      <div>请输入交易单号或兑换码下载</div>
      <div style={{ marginTop: "24px" }}>
        <Input
          size="large"
          placeholder="请输入订单编号"
          onChange={onInputChange}
          value={orderNo}
        />
        {downloadUrl && (
          <a
            href={downloadUrl}
            target="_blank"
            style={{ marginTop: "10px", display: "block", fontSize: "16px" }}
          >
            生成成功，点击此处手动下载
          </a>
        )}
      </div>
      <div className={styles.tips}>
        温馨提示：
        <div>1.为了产出更高质量的文档，预计10分钟左右生成完成</div>
        <div>
          2.文件下载流程：微信打开账单，找到对应的支付记录，点开详情页复制订单号/交易单号后，查询结果输入编号即可下载对应的文件
        </div>
        <img src={orderDemo} style={{ width: "100%", height: "auto" }} />
        <div>
          3.所有论文及文档都应该由本人通过学习后认真撰写，AI范文相关内容仅视为格式参考，不允许抄袭、代写、直接挪用等行为。
        </div>
      </div>

      <div className={styles.footer}>
        <Button size="large" onClick={() => nav("/")} style={{ width: "45vw" }}>
          返回首页
        </Button>
        <Button
          size="large"
          type="primary"
          style={{ marginLeft: "4vw", width: "45vw" }}
          onClick={exportPaper}
          loading={loading}
        >
          下载
        </Button>
      </div>
    </div>
  );
};
