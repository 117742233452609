import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { get } from "../../axios/axios";
import { EventSourcePolyfill } from "event-source-polyfill";
import { loginInstance } from "../../Service/login";

import { SSE } from "sse.js";
import { message } from "antd";

function genConstructEntry(templateCategory) {
  return {
    id: "_url",
    url: "/construct",
    templateCategory: 8,
    templateName: "全文写作",
    descStr: "按写作步骤逐步生成文章。",
    iconUrl: "https://multure.oss-cn-hangzhou.aliyuncs.com/ht.png",
    orderNum: -101,
    topTemp: -101,
    formList: [],
    exampleList: [],
  };
}

export function useTemplateData(isDashboard = false) {
  const [tabs, setTabs] = useState([]);
  const [cards, setCards] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await get("/member/template/category/list");
      console.log(res);
      setTabs([
        {
          icon: null,
          id: "all",
          name: "全部",
          orderNum: 0,
        },
        ...(res.data || []),
      ]);
    })();
    (async () => {
      const res = await get("/member/template/list");
      if (isDashboard) {
        res.data.unshift(genConstructEntry(8));
      }
      setCards(res.data);
    })();
  }, []);
  return {
    tabs,
    cards,
  };
}

export function eventSource(url, cb, cb2, payload, singleWordCb) {
  const auth = loginInstance.getHeaders();
  const authHeaders = auth ? { Authorization: auth } : {};
  const h = payload ? { "Content-Type": "application/json" } : {};
  const source = new SSE(url, {
    headers: {
      ...authHeaders,
      ...h,
    },
    payload,
  });
  const text = [];
  source.onopen = () => {
    console.log("连接成功");
  };
  source.onmessage = function (event) {
    const data = JSON.parse(event.data);
    if (!data || !data.chatToken) {
      return;
    }
    let { chatToken: token, index } = data;
    if (token === "[DONE]") {
      cb2();
      source.close();
      return;
    }
    if (index === undefined) {
      return;
    }
    token = token.replace(/%/g, "%25");
    token = decodeURIComponent(token);
    if (text[index]) {
      text[index] += token;
    } else {
      text[index] = token;
    }

    if (text[index] && /台湾$/.test(text[index])) {
      text[index].replace(/台湾$/, "中国(台湾)");
    }
    singleWordCb && singleWordCb(token, index, /台湾$/.test(text[index]));
    cb(text);
  };
  source.onerror = (e) => {
    console.error("连接失败", e);

    cb2();
    source.close();
    // show error
    try {
      const errorData = JSON.parse(e.data);
      message.error(errorData.msg);
      // if (!loginInstance.hasLogin) {
      //   loginInstance.open();
      // }
    } catch (error) {}
  };
  source.stream();
}

export function useEventSource() {
  const text = useRef([]);
  const [ts, setTS] = useState(0);
  const [status, setStatus] = useState("init");

  const startFetch = useCallback(async (url, payload, singleWordCb) => {
    text.current = [];
    setStatus("loading");
    console.log("ur;");
    eventSource(
      url,
      (t) => {
        text.current = [...t];
        setTS(Date.now());
      },
      () => {
        setStatus("done");
      },
      payload,
      singleWordCb
    );
  }, []);

  return {
    startFetch,
    text: text.current,
    status,
  };
}

export function commonEventSource(url, cb, cb2, payload, singleWordCb) {
  const auth = loginInstance.getHeaders();
  const authHeaders = auth ? { Authorization: auth } : {};
  const h = payload ? { "Content-Type": "application/json" } : {};
  const source = new SSE(url, {
    headers: {
      ...authHeaders,
      ...h,
    },
    payload,
  });
  const text = [];
  source.onopen = () => {
    console.log("连接成功");
  };
  source.onmessage = function (event) {
    const data = JSON.parse(event.data);
    if (!data || !data.chatToken) {
      return;
    }
    let { chatToken: token, index } = data;
    if (token === "[DONE]") {
      cb2();
      source.close();
      return;
    }
    if (index === undefined) {
      return;
    }
    token = token.replace(/%/g, "%25");
    token = decodeURIComponent(token);
    if (text[index]) {
      text[index] += token;
    } else {
      text[index] = token;
    }

    singleWordCb && singleWordCb(token, index);
    cb(text);
  };
  source.onerror = (e) => {
    console.error("连接失败", e);

    source.close();
    // show error
    try {
      const errorData = JSON.parse(e.data);
      message.error(errorData.msg);
      cb2({ status: "error", msg: errorData.msg });
      if (!loginInstance.hasLogin) {
        loginInstance.open();
      }
    } catch (error) {}
  };
  source.stream();

  return source;
}
