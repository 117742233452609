import axios, { AxiosRequestConfig } from "axios";
import { message } from "antd";
import { loginInstance } from "../Service/login";
export const baseURL = "https://cqingyoung.com";
//export const baseURL = "http://36.170.51.164:9999";
axios.interceptors.request.use((config) => {
  return config;
});
axios.defaults.baseURL = baseURL;

axios.interceptors.response.use(
  (response) => {
    return response.data || response;
  },
  (error) => {
    if (!error.config.slient) {
      message.error(
        error.response && error.response.data && error.response.data.msg
      );
    }

    // if (error.config.checkAuth && error.response.status === 424) {
    //   loginInstance.open();
    // }
    throw error;
  }
);

export const get = (url, data, config = {}) => {
  const params = data || {};
  const { headers = {} } = config;
  const auth = headers.Authorization || loginInstance.getHeaders();
  const authHeaders = auth ? { Authorization: auth } : {};
  config.headers = {
    ...headers,
    ...authHeaders,
  };

  return axios.get(url, {
    ...config,
    params: params,
  });
};

export const post = (url, data = {}, config = {}) => {
  let postData = data || {};
  const { headers = {} } = config;
  const auth = headers.Authorization || loginInstance.getHeaders();
  const authHeaders = auth ? { Authorization: auth } : {};
  config.headers = {
    ...headers,
    ...authHeaders,
  };

  return axios.post(url, postData, config);
};

export default axios;
