import { Button, Form, Input, message, Select } from "antd";
import { useState } from "react";
import cx from "classnames";
import { useCountDown } from "../../../Service/hooks";
import { post, get } from "../../../axios/axios";
import qs from "qs";

export function TelForm(props) {
  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(false);
  const [code, setCode] = useState("");
  const [form] = Form.useForm();
  const [seconds, setSeconds] = useCountDown(0);
  const [messageApi, contextHolder] = message.useMessage();
  const search = window.location.search;
  const parsed = qs.parse(search, { ignoreQueryPrefix: true });
  const invCode = parsed.code;

  const onPhoneChange = (v) => {
    const phone = v.target.value;
    setPhone(phone);

    const valid = phone && /^1[3|4|5|6|7|8|9]\d{9}$/.test(phone);
    console.log("valid", valid);
    setPhoneValid(valid);
  };

  const getCode = async () => {
    if (!phoneValid || seconds > 0) {
      return;
    }

    setSeconds(60);
    await post("/member/sms/send", { phone });
    messageApi.success("验证码发送成功");
  };

  const submitLogin = async () => {
    const res = await post(
      "/auth/oauth2/token",
      {},
      {
        params: {
          code: code,
          phone: phone,
          grant_type: "app",
          scope: "server",
          invCode: invCode,
        },
        headers: {
          Authorization: "Basic " + window.btoa("app:app"),
        },
      }
    );
    messageApi.success("登录成功");
    props.onOk(res);
  };

  return (
    <>
      {contextHolder}
      <Form form={form} className="custom-form">
        <Form.Item
          label=""
          name="phone"
          rules={[
            { required: true, message: "请输入您的手机号" },
            {
              pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
              message: "请输入正确的手机号码",
            },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="请输入您的手机号"
            value={phone}
            onChange={onPhoneChange}
            suffix={<span></span>}
          />
        </Form.Item>

        <Form.Item
          label=""
          name="code"
          rules={[{ required: true, message: "请输入验证码" }]}
        >
          <>
            <Input
              className="custom-input"
              placeholder="请输入验证码"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              suffix={
                <span
                  onClick={getCode}
                  className={cx("send-code-content", {
                    valid: phoneValid && seconds <= 0,
                  })}
                >
                  {seconds <= 0 ? "发送验证码" : "重新发送" + seconds + "s"}
                </span>
              }
            />
          </>
        </Form.Item>
        {invCode ? (
          <Form.Item label="邀请码" name="code" rules={[{ message: "邀请码" }]}>
            <div>{invCode}</div>
          </Form.Item>
        ) : (
          ""
        )}

        <Button
          className={cx("custom-btn", { "enable-btn": phoneValid && code })}
          disabled={!(phoneValid && code)}
          onClick={submitLogin}
        >
          登录
        </Button>
      </Form>
    </>
  );
}
