import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Form,
  Popover,
  Radio,
  Input,
  Select,
  Steps,
  Switch,
  Tooltip,
  ConfigProvider,
  message,
  Modal,
} from "antd";
import styles from "./index.module.scss";
import { observer } from "mobx-react-lite";
import { newConstructStore } from "./newStore";
import cx from "classnames";
import TextArea from "antd/es/input/TextArea";
import {
  CheckOutlined,
  DeleteOutlined,
  HolderOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  RightOutlined,
  ExportOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useCallback, useEffect, useMemo, useState } from "react";
import nzhcn from "nzh/cn";
import Img1 from "../../assets/wenxian-1.jpg";
import Img2 from "../../assets/wenxian-2.jpg";
import { isMobile } from "../../common/const";
import { Operator } from "./operator";
import { debounce } from "lodash";
import { CodeIcon, GongshiIcon, PicIcon, TableIcon } from "./icons";
import example from "../../assets/example.jpg";

export const NewConstuct = observer(function (props) {
  useEffect(() => {
    newConstructStore.reset();
  }, []);

  const createNew = async () => {
    try {
      const data = await newConstructStore.step4Submit();
      props.handleNew(data);
    } catch (err) {
      console.error(err);
    }
  };

  const handlePressEnter = (i, iv, e) => {
    if (e.nativeEvent.isComposing) {
      return;
    }
    // 如果同时按下了 Shift 和 Enter 键
    if (e.shiftKey && e.key === "Enter") {
      // 插入换行符
      return;
    } else {
      e.preventDefault();
      newConstructStore.step3AddSub(i, iv);
      setTimeout(() => {
        const nextInput = document.getElementById(`sub-${i}-${iv + 1}`);
        const curInput = document.getElementById(`sub-${i}-${iv}`);
        if (nextInput) {
          nextInput.focus();
        } else if (curInput) {
          curInput.focus();
        }
      });
    }
  };

  const handleKeyDown = (i, iv, e) => {
    if (e.key === "Backspace" && !e.target.value && iv > 0) {
      e.preventDefault();
      // 执行你想要的操作
      newConstructStore.step3DelSub(i, iv);
      setTimeout(() => {
        const preInput = document.getElementById(`sub-${i}-${iv - 1}`);
        const curInput = document.getElementById(`sub-${i}-${iv}`);
        if (preInput) {
          preInput.focus();
        } else if (curInput) {
          curInput.focus();
        }
      });
    }
  };
  const TYPES_DESC = {
    CODE: "代码",
    TABLE: "数据(表)",
    DIAGRAM: "图",
    FORMULA: "公式",
  };
  const step1 = (
    <div className={styles.step1}>
      {/*<h4>提供详细的描述有助于让青秧更好地理解您想要创作的内容</h4>*/}
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#F5F8F9",
          },
        }}
      >
        <Form
          initialValues={newConstructStore.step1}
          layout="vertical"
          className={styles.form}
          onValuesChange={(...e) => {
            newConstructStore.step1Change(...e);
          }}
        >
          <Form.Item
            required
            label={<div className={"font-medium text-base"}>详细内容</div>}
            name="majorName"
          >
            <Input.TextArea
              placeholder={
                "请输入您想写的具体内容，写的越详细有助于让青秧灵感更好地理解您想要创作的内容～"
              }
              className={"text-[#F5F8F9]"}
              autoSize={{ minRows: 5, maxRows: 20 }}
              showCount
              maxLength={300}
            />
          </Form.Item>
          <Form.Item
            label={<div className={"font-medium text-base"}>选择类型</div>}
            name="type"
            required
          >
            <Select
              placeholder="开题报告"
              options={newConstructStore.paperTypes}
            />
          </Form.Item>

          {newConstructStore.step1.type === 1 && (
            <Form.Item label="论文获取内容需要包含" name="autoLightTypes">
              <Checkbox.Group
                options={[
                  { label: "代码", value: "CODE" },
                  { label: "数据(表)", value: "TABLE" },
                  { label: "图", value: "DIAGRAM" },
                  { label: "公式", value: "FORMULA" },
                ]}
              />
            </Form.Item>
          )}
          <div style={isMobile ? {} : { display: "flex" }}>
            <Form.Item
              required
              label={<div className={"font-medium text-base"}>关键词</div>}
              name="keywords"
              tooltip="关键词的质量会很大程度影响生成的效果"
              style={isMobile ? { flex: "100%" } : { flex: "40%" }}
            >
              <Select
                style={{ width: "100%" }}
                mode="tags"
                value={newConstructStore.step1.keywords}
                tokenSeparators={[",", "，", " ", "、", ";", "；"]}
                placeholder="关键词请用，或、或；或空格隔开"
                className={styles.keywords}
                onChange={(value) => {
                  if (value?.length > 3) {
                    value.pop();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label={<div className={"font-medium text-base"}>选择语气</div>}
              style={
                isMobile
                  ? { flex: "100%" }
                  : {
                      flex: "40%",
                      marginLeft: "20px",
                    }
              }
            >
              <Select
                placeholder="学术性"
                options={[
                  { value: "学术性", label: "学术性" },
                  { value: "有说服力的", label: "有说服力的" },
                  { value: "专业的", label: "专业的" },
                  { value: "正式的", label: "正式的" },
                  { value: "书面得体的", label: "书面得体的" },
                ]}
              />
            </Form.Item>
          </div>

          {/* <div className={styles.tryout}>
          试试
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAyklEQVRIiWNgGKQgltYW/IdigoCJCDWrKXEJCwH5/0g0I5FqUdQR8gGyYnxB8oqAOQQBvjDfRUCeaJBEwAEDAwhFHCkA3ReMDAzEJVNsBv0jQl0J3BYiDUUG2PTNZmBgMISyXzIwMHjjM/AbAyJlYMNEA+SMRqxGCTQ+1gzGgEPwLwNpmQ/ZAqyWoBvGDFVkTMASXBaSFHzYwl6AgHqSDWdkIDNyYQA9iEIYUCONqkWAMAN2l86ghkW2OAyHAar4xBaP3CVqWDA0AQA7DTcUoinV5gAAAABJRU5ErkJggg==" />
          <div className={styles.klist}>
            <div
              className={styles.k}
              onClick={() => newConstructStore.tryout()}
            >
              人工智能
            </div>
          </div>
        </div> */}
        </Form>
      </ConfigProvider>
      <div
        className={styles.paging}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            height: "48px",
          }}
          className={"w-[208px]"}
          disabled={!newConstructStore.step1.canSubmit}
          type="primary"
          onClick={() => newConstructStore.step1Submit()}
          loading={newConstructStore.nextLoading}
        >
          下一步
        </Button>
      </div>
    </div>
  );
  const step2 = (
    <div className={styles.step2}>
      <div className={"text-[#999]  text-center mt-4"}>
        给文章选择一个亮眼的标题吧～
      </div>
      <div className={styles.titles}>
        {newConstructStore.step2.titles.map((title, idx) => {
          const isSel = newConstructStore.step2.selectIdx === idx;
          return (
            <div
              key={idx}
              className={classNames(styles.item, {
                [styles.focus]: newConstructStore.step2.focusIdx === idx,
                [styles.select]: isSel,
              })}
            >
              <Input.TextArea
                placeholder={idx === 0 ? "自定义标题" : ""}
                bordered={false}
                value={title}
                onFocus={() => newConstructStore.step2Foucs(idx)}
                onBlur={() => newConstructStore.step2Foucs(-1)}
                onChange={(e) =>
                  newConstructStore.step2Change(idx, e.target.value)
                }
                maxLength={200}
                autoSize={true}
                readOnly={newConstructStore.nextLoading}
              />
              {isSel ? (
                <CheckOutlined className={styles.checked} />
              ) : (
                <Button
                  disabled={!title || newConstructStore.nextLoading}
                  onClick={() => newConstructStore.step2Select(idx)}
                >
                  <CheckOutlined />
                  选择
                </Button>
              )}
            </div>
          );
        })}
        <div className={"flex justify-center"}>
          {newConstructStore.nextLoading ? null : (
            <Button
              type={"text"}
              className={styles.reload}
              onClick={() => newConstructStore.step1Submit()}
            >
              <ReloadOutlined className={"-translate-y-0.5 text-[#00CE72]"} />
              <span className={"text-[#00CE72]"}>重新生成</span>
            </Button>
          )}
        </div>
      </div>
      <div className={"flex justify-center gap-4"}>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                defaultBg: "#fff",
              },
            },
          }}
        >
          <Button
            disabled={newConstructStore.nextLoading}
            onClick={() => newConstructStore.goLast()}
            style={{
              height: "48px",
            }}
            className={"w-[208px]"}
          >
            上一步
          </Button>
          <Button
            style={{
              height: "48px",
            }}
            className={"w-[208px]"}
            type="primary"
            onClick={() => newConstructStore.step2Submit()}
            disabled={newConstructStore.step2.selectIdx <= -1}
            loading={newConstructStore.nextLoading}
          >
            下一步
          </Button>
        </ConfigProvider>
      </div>
    </div>
  );

  const stepwenxian = (
    <div className={styles.step2}>
      <h4>文献推荐</h4>
      <div className={styles.subTitle}>
        自定义输入文献时，请使用{"  "}
        <Popover
          placement="rightTop"
          trigger={isMobile ? "click" : "hover"}
          content={
            <div className={styles.wenxianGuide}>
              <img src={Img1} />
              <img src={Img2} />
            </div>
          }
          title="文献获取指引"
        >
          <b>
            查新引文格式
            <QuestionCircleOutlined className={styles.icon} />
          </b>
        </Popover>
      </div>
      <div className={styles.titles}>
        {newConstructStore.stepWenxian.wenxianList
          .slice(0, 10)
          .map((wenxianTitle, idx) => {
            const systemWenxianListIndexs =
              newConstructStore.stepWenxian.wenxianList
                .filter((it) => it.id)
                .map((it) =>
                  newConstructStore.stepWenxian.wenxianList.findIndex(
                    (item) => item.id === it.id
                  )
                );
            const selectSystemWenxianListIndexs =
              newConstructStore.stepWenxian.selectIdxList.filter((it) => {
                return systemWenxianListIndexs.includes(it);
              });
            const checked =
              systemWenxianListIndexs.every((it) => {
                return selectSystemWenxianListIndexs.includes(it);
              }) && selectSystemWenxianListIndexs.length > 0;
            const indeterminate =
              systemWenxianListIndexs.some((it) => {
                return selectSystemWenxianListIndexs.includes(it);
              }) && !checked;
            const isSel =
              newConstructStore.stepWenxian.selectIdxList.includes(idx);
            return (
              <>
                <div
                  key={idx}
                  className={classNames(styles.item, {
                    [styles.focus]: newConstructStore.step2.focusIdx === idx,
                    [styles.select]: isSel,
                  })}
                >
                  <div style={{ flex: 1 }}>
                    <Input.TextArea
                      showCount={idx === 0}
                      placeholder={
                        idx === 0
                          ? "自定义输入，请使用查新引文格式，建议五到六篇文献。"
                          : ""
                      }
                      bordered={false}
                      value={wenxianTitle.title}
                      maxLength={2000}
                      autoSize={true}
                      readOnly={idx !== 0 || newConstructStore.nextLoading}
                      onChange={(e) =>
                        newConstructStore.stepWenxianChange(idx, e.target.value)
                      }
                    />
                    {idx !== 0 && (
                      <>
                        <div className={styles.wenxianDesc}>
                          {wenxianTitle.authors}
                        </div>
                        <div className={styles.wenxianDesc}>
                          {wenxianTitle.publication} |{" "}
                          {wenxianTitle.publishDate} |
                          <span
                            style={{
                              color: "#3770F1",
                              marginLeft: "4px",
                              cursor: "pointer",
                            }}
                            onClick={() => window.open(wenxianTitle.url)}
                          >
                            <ExportOutlined />
                            查看
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  {isSel ? (
                    <CheckOutlined
                      className={styles.checked}
                      onClick={() => newConstructStore.stepWenxianSelect(idx)}
                    />
                  ) : (
                    <Button
                      disabled={!wenxianTitle || newConstructStore.nextLoading}
                      onClick={() => newConstructStore.stepWenxianSelect(idx)}
                    >
                      <CheckOutlined />
                      选择
                    </Button>
                  )}
                </div>
                {idx === 0 && (
                  <div className={"flex justify-between p-5"}>
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={(e) => {
                        newConstructStore.stepWenxianSelectAll(
                          e.target.checked
                        );
                      }}
                      checked={checked}
                    >
                      文献全选
                    </Checkbox>
                    <div>使用系统推荐文献</div>
                    <div></div>
                  </div>
                )}
              </>
            );
          })}
        {/* 从第11条开始的数据使用Collapse包裹 */}
        {newConstructStore.stepWenxian.wenxianList.length > 10 && (
          <Collapse className={styles.moreCollapse}>
            <Collapse.Panel header="展开更多文献" key="more">
              {newConstructStore.stepWenxian.wenxianList
                .slice(10)
                .map((wenxianTitle, idx) => {
                  // 需要加上前面的10条数据的长度
                  const actualIdx = idx + 10;
                  const isSel =
                    newConstructStore.stepWenxian.selectIdxList.includes(
                      actualIdx
                    );

                  return (
                    <div
                      key={actualIdx}
                      className={classNames(styles.item, {
                        [styles.focus]:
                          newConstructStore.step2.focusIdx === actualIdx,
                        [styles.select]: isSel,
                      })}
                    >
                      <div style={{ flex: 1 }}>
                        <Input.TextArea
                          placeholder=""
                          bordered={false}
                          value={wenxianTitle.title}
                          maxLength={2000}
                          autoSize={true}
                          readOnly={newConstructStore.nextLoading}
                          onChange={(e) =>
                            newConstructStore.stepWenxianChange(
                              actualIdx,
                              e.target.value
                            )
                          }
                        />
                        <>
                          <div className={styles.wenxianDesc}>
                            {wenxianTitle.authors}
                          </div>
                          <div className={styles.wenxianDesc}>
                            {wenxianTitle.publication} |{" "}
                            {wenxianTitle.publishDate} |
                            <span
                              style={{
                                color: "#3770F1",
                                marginLeft: "4px",
                                cursor: "pointer",
                              }}
                              onClick={() => window.open(wenxianTitle.url)}
                            >
                              <ExportOutlined />
                              查看
                            </span>
                          </div>
                        </>
                      </div>

                      {isSel ? (
                        <CheckOutlined
                          className={styles.checked}
                          onClick={() =>
                            newConstructStore.stepWenxianSelect(actualIdx)
                          }
                        />
                      ) : (
                        <Button
                          disabled={
                            !wenxianTitle || newConstructStore.nextLoading
                          }
                          onClick={() =>
                            newConstructStore.stepWenxianSelect(actualIdx)
                          }
                        >
                          <CheckOutlined />
                          选择
                        </Button>
                      )}
                    </div>
                  );
                })}
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
      <div className={styles.paging}>
        <Button
          disabled={newConstructStore.nextLoading}
          style={{
            height: "48px",
          }}
          className={"w-[208px]"}
          onClick={() => newConstructStore.goLast()}
        >
          上一步
        </Button>
        <Button
          style={{
            height: "48px",
          }}
          className={"w-[208px]"}
          type="primary"
          onClick={() => newConstructStore.stepWenxianSubmit()}
          loading={newConstructStore.nextLoading}
        >
          下一步
        </Button>
      </div>
    </div>
  );
  const step3 = (
    <div className={styles.step2}>
      <div className={"text-[#999] mt-5 text-center"}>
        生成一段引人入胜的摘要
      </div>
      <div className={styles.titles}>
        {newConstructStore.step4.titles.map((title, idx) => {
          const isSel = newConstructStore.step4.selectIdx === idx;
          return (
            <div
              key={idx}
              className={classNames(styles.item, {
                [styles.focus]: newConstructStore.step4.focusIdx === idx,
                [styles.select]: isSel,
              })}
            >
              <Input.TextArea
                bordered={false}
                value={title}
                onFocus={() => newConstructStore.step4Foucs(idx)}
                onBlur={() => newConstructStore.step4Foucs(-1)}
                onChange={(e) =>
                  newConstructStore.step4Change(idx, e.target.value)
                }
                autoSize
                readOnly={newConstructStore.nextLoading}
              />
              {isSel ? (
                <CheckOutlined className={styles.checked} />
              ) : (
                <Button
                  disabled={!title || newConstructStore.nextLoading}
                  onClick={() => newConstructStore.step4Select(idx)}
                >
                  <CheckOutlined />
                  选择
                </Button>
              )}
            </div>
          );
        })}
        <div className={"flex justify-center"}>
          <Button
            type={"text"}
            className={styles.reload}
            onClick={() => newConstructStore.moreStep2()}
            disabled={newConstructStore.nextLoading}
          >
            <ReloadOutlined className={"-translate-y-0.5 text-[#00CE72]"} />
            <span className={"text-[#00CE72]"}>再来一个</span>
          </Button>
        </div>
      </div>
      <div className={styles.paging}>
        <Button
          disabled={newConstructStore.nextLoading}
          onClick={() => newConstructStore.goLast()}
          style={{
            height: "48px",
          }}
          className={"w-[208px]"}
        >
          上一步
        </Button>
        <Button
          style={{
            height: "48px",
          }}
          className={"w-[208px]"}
          type="primary"
          onClick={() => newConstructStore.step3Submit()}
          disabled={newConstructStore.step4.selectIdx <= -1}
          loading={newConstructStore.nextLoading}
        >
          下一步
        </Button>
      </div>
    </div>
  );
  useEffect(() => {
    // 这个不能删除
  }, [newConstructStore.step3.menus.length]);

  const [modalInfo, setModal] = useState({
    show: false,
    // 弹窗类型，add新增，edit编辑
    type: "add",
    isAfter: true,
    inputMain: "",
    inputSub: "",
    // 标题级别，从1开始，表示1级标题，最多3级
    level: 1,
    level1idx: 0,
    level2idx: 0,
    level3idx: 0,
  });

  const handleSubmitModal = useCallback(() => {
    const { type, level, inputMain, inputSub } = modalInfo;
    if (inputMain.trim() === "") {
      message.error("请输入章节标题");
      return;
    }
    if (level === 3 && inputSub.trim() === "") {
      message.error("请输入章节描述");
      return;
    }
    if (type === "add") {
      newConstructStore.addTitle({ ...modalInfo });
    } else if (type === "edit") {
      newConstructStore.editTitle({ ...modalInfo });
    }
    setModal((m) => ({
      ...m,
      show: false,
    }));
  }, [modalInfo]);

  const handleDelete = useCallback((opts) => {
    newConstructStore.deleteTitle(opts);
  }, []);

  const SummaryHeader = useCallback(
    ({ it, idx }) => {
      return (
        <div className={styles.summaryHeader}>
          <div>{it.chapter}</div>
          <Operator
            docType={newConstructStore.step1.type}
            data={it}
            onAdd={() =>
              setModal({
                show: true,
                type: "add",
                isAfter: true,
                inputMain: "",
                inputSub: "",
                level: 1,
                level1idx: idx,
                level2idx: 0,
                level3idx: 0,
              })
            }
            onEdit={() =>
              setModal({
                show: true,
                type: "edit",
                isAfter: true,
                inputMain: it.chapter,
                inputSub: it.chapterAbstract,
                level: 1,
                level1idx: idx,
                level2idx: 0,
                level3idx: 0,
              })
            }
            onDelete={() =>
              handleDelete({
                level: 1,
                level1idx: idx,
                level2idx: 0,
                level3idx: 0,
                txt: it.chapter,
              })
            }
          />
        </div>
      );
    },
    [handleDelete]
  );

  const SectionHeader = useCallback(
    ({ s, idx, sidx, isLastLevel }) => {
      return (
        <div className={styles.cSectionHeader}>
          <div className={styles.cSectionTitle}>
            <div className={styles.title}>
              {/* <span>
                {idx + 1}.{sidx + 1}
              </span> */}
              <div>{s.chapter}</div>
            </div>
            <div className={styles.desc}>{s.chapterAbstract}</div>
          </div>
          <Operator
            data={s}
            docType={newConstructStore.step1.type}
            className={styles.opt}
            isLastLevel={isLastLevel}
            onAdd={() =>
              setModal({
                show: true,
                type: "add",
                isAfter: true,
                inputMain: "",
                inputSub: "",
                level: 2,
                level1idx: idx,
                level2idx: sidx,
                level3idx: 0,
              })
            }
            onEdit={() =>
              setModal({
                show: true,
                type: "edit",
                isAfter: true,
                inputMain: s.chapter,
                inputSub: s.chapterAbstract,
                level: 2,
                level1idx: idx,
                level2idx: sidx,
                level3idx: 0,
              })
            }
            onDelete={() =>
              handleDelete({
                level: 2,
                level1idx: idx,
                level2idx: sidx,
                level3idx: 0,
                txt: s.chapter,
              })
            }
            onChangeExtra={(v) => {
              newConstructStore.changeTitleExtra({
                v,
                level: 2,
                level1idx: idx,
                level2idx: sidx,
                level3idx: 0,
              });
            }}
          />
        </div>
      );
    },
    [handleDelete]
  );

  const [top, setTop] = useState(80);

  const onScroll = useMemo(
    () =>
      debounce(() => {
        const el = document.getElementById("summaryEl");
        if (!el) return;
        const maxH = el ? el.offsetHeight - 200 : 1000;
        const distance =
          document.scrollingElement.scrollTop - el.offsetTop + 60;
        setTop(Math.min(Math.max(35, distance), maxH));
      }, 500),
    []
  );

  const step4 = (
    <div
      className={cx([styles.summaryWrapper, styles[props.from]])}
      id="summaryEl"
    >
      <div className={styles.summaryBox}>
        <Collapse
          ghost
          defaultActiveKey={newConstructStore.step4.outlines?.map(
            (_, idx) => idx
          )}
        >
          {[
            ...newConstructStore.step4.outlines,
            {
              chapter: `${
                newConstructStore.step1.type === 1
                  ? nzhcn.encodeS(newConstructStore.step4.outlines.length) +
                    "、"
                  : ""
              }参考文献`,
              subheadings: [],
            },
          ]?.map((it, idx) => {
            return (
              <Collapse.Panel
                key={idx}
                header={<SummaryHeader it={it} idx={idx} />}
              >
                {it.sections?.map((s, sidx) => {
                  const haveThreeOutline =
                    s.subsections && s.subsections.length > 0;
                  return haveThreeOutline ? (
                    <Collapse
                      ghost
                      key={sidx}
                      className={styles.collaspseSection}
                      defaultActiveKey={[sidx]}
                    >
                      <Collapse.Panel
                        key={sidx}
                        header={<SectionHeader s={s} idx={idx} sidx={sidx} />}
                      >
                        {s.subsections.map((sub, subIdx) => (
                          <div className={styles.subSection} key={subIdx}>
                            <div>
                              <div className={styles.subSectionTitle}>
                                {/* {idx + 1}.{sidx + 1}.{subIdx + 1} */}
                                {sub.chapter}
                              </div>
                              <div className={styles.subSectionAbs}>
                                {sub.chapterAbstract}
                              </div>
                            </div>
                            <Operator
                              key={subIdx}
                              docType={newConstructStore.step1.type}
                              data={sub}
                              className={styles.opt}
                              isLastLevel={true}
                              onAdd={() =>
                                setModal({
                                  show: true,
                                  type: "add",
                                  isAfter: true,
                                  inputMain: "",
                                  inputSub: "",
                                  level: 3,
                                  level1idx: idx,
                                  level2idx: sidx,
                                  level3idx: subIdx,
                                })
                              }
                              onEdit={() =>
                                setModal({
                                  show: true,
                                  type: "edit",
                                  isAfter: true,
                                  inputMain: sub.chapter,
                                  inputSub: sub.chapterAbstract,
                                  level: 3,
                                  level1idx: idx,
                                  level2idx: sidx,
                                  level3idx: subIdx,
                                })
                              }
                              onDelete={() =>
                                handleDelete({
                                  level: 3,
                                  level1idx: idx,
                                  level2idx: sidx,
                                  level3idx: subIdx,
                                  txt: sub.chapter,
                                })
                              }
                              onChangeExtra={(v) => {
                                newConstructStore.changeTitleExtra({
                                  v,
                                  level: 3,
                                  level1idx: idx,
                                  level2idx: sidx,
                                  level3idx: subIdx,
                                });
                              }}
                            />
                          </div>
                        ))}
                      </Collapse.Panel>
                    </Collapse>
                  ) : (
                    <div
                      className={classNames(
                        styles.collaspseSection,
                        styles.collaspseSectionSingle
                      )}
                      key={sidx}
                    >
                      <SectionHeader
                        isLastLevel={true}
                        s={s}
                        idx={idx}
                        sidx={sidx}
                      />
                    </div>
                  );
                })}
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </div>
      <div className={styles.paging}>
        <Button
          disabled={newConstructStore.nextLoading}
          style={{
            height: "48px",
          }}
          className={"w-[208px]"}
          onClick={() => newConstructStore.goLast()}
        >
          上一步
        </Button>
        <Button
          style={{
            height: "48px",
          }}
          className={"w-[208px]"}
          type="primary"
          onClick={() => createNew()}
          disabled={newConstructStore.step3.menus.length <= 0}
          loading={newConstructStore.nextLoading}
        >
          提交
        </Button>
      </div>
      {props.from !== "drawer" && (
        <div className={styles.summaryTips} style={{ top: top + "px" }}>
          <div className={styles.bold}>TIPS：</div>
          <div>👈点亮右侧按钮</div>
          <div className={styles.line}>
            {TableIcon}
            <div>
              ：插入<span>数据表</span>
            </div>
          </div>
          <div className={styles.line}>
            {PicIcon}
            <div>
              ：插入<span>流程图</span>
            </div>
          </div>
          {/* <div className={styles.line}>
            {GongshiIcon}
            <div>
              ：插入<span>公式</span>
            </div>
          </div> */}

          {/* <div className={styles.line}>
            {CodeIcon}
            <div>
              ：插入<span>代码段</span>
            </div>
          </div> */}
          <div className={styles.line}>
            支持<span>增</span>、<span>删</span>、<span>改</span>
          </div>
          <div style={{ lineHeight: "20px" }}>
            <span>小墨会自动根据您的需要点亮相应的图标</span>
            <br />
            您可根据需要进行<span>添加或删减</span>
          </div>
        </div>
      )}
      <Modal
        open={modalInfo.show}
        title={modalInfo.type === "add" ? "新增章节" : "编辑章节"}
        onOk={handleSubmitModal}
        onCancel={() => {
          setModal((m) => ({
            ...m,
            show: false,
          }));
        }}
      >
        <Alert
          message="全文生成效果受章节数和概要内容影响，请谨慎修改"
          type="warning"
          showIcon
        />
        {/* 插入位置 */}
        {modalInfo.type === "add" && (
          <>
            <div className={styles.label}>插入位置</div>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              value={modalInfo.isAfter}
              onChange={(e) =>
                setModal((p) => ({ ...p, isAfter: e.target.value }))
              }
            >
              <Radio.Button value={false}>
                本{modalInfo.level === 1 ? "章" : "节"}之前
              </Radio.Button>
              <Radio.Button value={true}>
                本{modalInfo.level === 1 ? "章" : "节"}之后
              </Radio.Button>
            </Radio.Group>
          </>
        )}
        {/* 章节标题 */}
        <div className={styles.label}>
          章节标题<b>*</b>
        </div>
        <Input
          placeholder="请输入章节标题"
          value={modalInfo.inputMain}
          onChange={(e) =>
            setModal((p) => ({ ...p, inputMain: e.target.value }))
          }
        />
        {/* 章节概要 */}
        {modalInfo.level > 2 ||
        modalInfo.level === 2 ||
        (modalInfo.type === "edit" &&
          modalInfo.inputSub !== null &&
          modalInfo.inputSub !== undefined) ? (
          <>
            <div className={styles.label}>
              章节概要<b>*</b>
            </div>
            <Input.TextArea
              placeholder="请输入章节概要"
              value={modalInfo.inputSub}
              onChange={(e) =>
                setModal((p) => ({ ...p, inputSub: e.target.value }))
              }
            />
          </>
        ) : null}
      </Modal>
      {/*<Modal*/}
      {/*  title="丰富论文内容"*/}
      {/*  open={newConstructStore.showCheckTableDialog}*/}
      {/*  okText="继续生成大纲"*/}
      {/*  footer={*/}
      {/*    <Button*/}
      {/*      onClick={() => {*/}
      {/*        newConstructStore.onModalConfirmGetSummary(notiApi);*/}
      {/*      }}*/}
      {/*      type="primary"*/}
      {/*    >*/}
      {/*      确认*/}
      {/*    </Button>*/}
      {/*  }*/}
      {/*  closable={false}*/}
      {/*>*/}
      {/*  <div>*/}
      {/*    <div>*/}
      {/*      如您的论文需要包含<hl>表，图，公式，代码</hl>*/}
      {/*      ，您可在在下方勾选相应选项，小灵会为您自动生成*/}
      {/*      <Tooltip*/}
      {/*        color="#fff"*/}
      {/*        title={*/}
      {/*          <span style={{ color: "#18191c", width: "250px" }}>*/}
      {/*            您可根据论文需求需要勾选相应的<hl>表，图，公式，代码</hl>*/}
      {/*            <br />*/}
      {/*            在生成大纲后仍可继续进行<hl>添加或删减</hl>*/}
      {/*            <br />*/}
      {/*            点亮后，小灵会自动判断勾选内容需要的具体需求，如图所示：*/}
      {/*            <img*/}
      {/*              src={example}*/}
      {/*              style={{ color: "#18191c", width: "240px" }}*/}
      {/*            />*/}
      {/*          </span>*/}
      {/*        }*/}
      {/*      >*/}
      {/*        <QuestionCircleOutlined*/}
      {/*          color="#999999"*/}
      {/*          className={styles.summaryIcon}*/}
      {/*          style={{ marginLeft: "4px" }}*/}
      {/*        />*/}
      {/*      </Tooltip>*/}
      {/*    </div>*/}
      {/*    <div*/}
      {/*      className={styles.summaryChecks}*/}
      {/*      style={{ display: "flex", alignItems: "center" }}*/}
      {/*    >*/}
      {/*      <Checkbox.Group*/}
      {/*        value={summaryStore.autoLightTypes}*/}
      {/*        onChange={(e) => summaryStore.onAutoLightChange(e)}*/}
      {/*        disabled={summaryStore.isLoadingSummary}*/}
      {/*      >*/}
      {/*        <Checkbox value="TABLE">表</Checkbox>*/}

      {/*        <Checkbox value="DIAGRAM">图</Checkbox>*/}

      {/*        <Checkbox value="FORMULA">公式</Checkbox>*/}

      {/*        <Checkbox value="CODE">代码</Checkbox>*/}
      {/*      </Checkbox.Group>*/}
      {/*    </div>*/}
      {/*    <a href="https://lingganfengbao.com/example" target="_blank" rel="noreferrer">*/}
      {/*      查看范文样例*/}
      {/*    </a>*/}

      {/*    /!* <div style={{ color: "#18191c" }} className={styles.summaryTips}>*/}
      {/*      您可根据论文需求需要勾选相应的<hl>表，图，公式，代码</hl>，*/}
      {/*      在生成大纲后仍可继续进行<hl>添加或删减</hl>*/}
      {/*      ，点亮后，小灵会自动判断勾选内容需要的具体需求，如图所示：*/}
      {/*      <img src={example} style={{ color: "#18191c", width: "240px" }} />*/}
      {/*    </div> *!/*/}
      {/*  </div>*/}
      {/*</Modal>*/}
    </div>
  );
  const nowStep = [step1, step2, stepwenxian, step3, step4][
    newConstructStore.curStep
  ]; // newConstructStore.curStep
  return (
    <div className={styles.new}>
      <div
        className={"text-xs  lg:text-2xl font-semibold  text-[#00CE72] mb-1"}
      >
        全文写作流程
      </div>
      <div className={"text-xs  lg:text-base text-[#999] "}>
        按照步骤轻松创作出一篇优质的原创文章
      </div>
      <div className={styles.step}>
        <div className={styles.progress}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#00CE72",
              },
            }}
          >
            <Steps
              current={newConstructStore.curStep}
              // current={2}
              onChange={newConstructStore.onNewStepChange}
              className="site-navigation-steps"
              items={newConstructStore.steps}
              direction="horizontal"
            />
          </ConfigProvider>
        </div>
        <div className={styles.content}>{nowStep}</div>
      </div>
    </div>
  );
});
