export const LOGIN_TABS = [
  {
    key: 1,
    label: `微信登录`,
  },
  {
    key: 2,
    label: `手机登录`,
  },
];
