import { Dropdown } from "antd";
import styles from "./ColorDropdown.module.scss";
import { useState, useEffect } from "react";
export function ColorDropdown(props) {
  const [color, setColor] = useState("");
  useEffect(() => {
    const e = props.QuillRef.current.getEditor();
    const color = e.getFormat()?.color;
    setColor(color);
  }, [props.QuillRef]);

  const setEditorColor = (event) => {
    const e = props.QuillRef.current.getEditor();
    const value = event.target.dataset.value;
    setColor(value);
    console.log("v", value);
    e.format("color", value);
  };

  const items = [
    {
      key: 1,
      label: (
        <div>
          <span
            className={styles.pickerOptions}
            aria-hidden="false"
            tabindex="-1"
            id="ql-picker-options-2"
          >
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#000"
              style={{ backgroundColor: "#000" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#e60000"
              style={{ backgroundColor: "rgb(230, 0, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#ff9900"
              style={{ backgroundColor: "rgb(255, 153, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#ffff00"
              style={{ backgroundColor: "rgb(255, 255, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#008a00"
              style={{ backgroundColor: "rgb(0, 138, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#0066cc"
              style={{ backgroundColor: "rgb(0, 102, 204)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#9933ff"
              style={{ backgroundColor: "rgb(153, 51, 255)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#ffffff"
              style={{ backgroundColor: "rgb(255, 255, 255)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#facccc"
              style={{ backgroundColor: "rgb(250, 204, 204)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#ffebcc"
              style={{ backgroundColor: "rgb(255, 235, 204)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#ffffcc"
              style={{ backgroundColor: "rgb(255, 255, 204)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#cce8cc"
              style={{ backgroundColor: "rgb(204, 232, 204)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#cce0f5"
              style={{ backgroundColor: "rgb(204, 224, 245)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#ebd6ff"
              style={{ backgroundColor: "rgb(235, 214, 255)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#bbbbbb"
              style={{ backgroundColor: "rgb(187, 187, 187)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#f06666"
              style={{ backgroundColor: "rgb(240, 102, 102)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#ffc266"
              style={{ backgroundColor: "rgb(255, 194, 102)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#ffff66"
              style={{ backgroundColor: "rgb(255, 255, 102)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#66b966"
              style={{ backgroundColor: "rgb(102, 185, 102)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#66a3e0"
              style={{ backgroundColor: "rgb(102, 163, 224)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#c285ff"
              style={{ backgroundColor: "rgb(194, 133, 255)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#888888"
              style={{ backgroundColor: "rgb(136, 136, 136)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#a10000"
              style={{ backgroundColor: "rgb(161, 0, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#b26b00"
              style={{ backgroundColor: "rgb(178, 107, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#b2b200"
              style={{ backgroundColor: "rgb(178, 178, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#006100"
              style={{ backgroundColor: "rgb(0, 97, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#0047b2"
              style={{ backgroundColor: "rgb(0, 71, 178)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#6b24b2"
              style={{ backgroundColor: "rgb(107, 36, 178)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#444444"
              style={{ backgroundColor: "rgb(68, 68, 68)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#5c0000"
              style={{ backgroundColor: "rgb(92, 0, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#663d00"
              style={{ backgroundColor: "rgb(102, 61, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#666600"
              style={{ backgroundColor: "rgb(102, 102, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#003700"
              style={{ backgroundColor: "rgb(0, 55, 0)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#002966"
              style={{ backgroundColor: "rgb(0, 41, 102)" }}
            ></span>
            <span
              onClick={setEditorColor}
              role="button"
              className={styles.pickItem}
              data-value="#3d1466"
              style={{ backgroundColor: "rgb(61, 20, 102)" }}
            ></span>
          </span>
        </div>
      ),
    },
  ];
  return (
    <Dropdown
      menu={{ items }}
      getPopupContainer={() => document.getElementById("colorToolbar")}
    >
      <div className={styles.barItem} id="colorToolbar">
        <svg width="18" height={18} viewBox="0 0 18 18">
          <line
            className={styles.aIcon}
            x1="3"
            x2="15"
            y1="15"
            y2="15"
            style={{ stroke: color || "rgb(0, 0, 0)" }}
          ></line>
          <polyline
            className={styles.stoke}
            points="5.5 11 9 3 12.5 11"
          ></polyline>
          <line
            className={styles.stoke}
            x1="11.63"
            x2="6.38"
            y1="9"
            y2="9"
          ></line>
        </svg>
      </div>
    </Dropdown>
  );
}
