import { Button, message, Modal } from "antd";
import styles from "./index.module.scss";
import {
  CheckOutlined,
  LeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { NewConstuct } from "./new";
import { useEffect, useState } from "react";
import { EditResult } from "./result";
import { observer } from "mobx-react-lite";
import { editorStore } from "./editorStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loginInstance } from "../../Service/login";

export const Construct = observer(function () {
  const nav = useNavigate();
  const [qs] = useSearchParams();
  const [constuct, setConstruct] = useState({ orderNo: qs.get("xx") });
  const [showNew, setShowNew] = useState(!qs.get("xx"));
  // const [showNew, setShowNew] = useState(false);
  // useEffect(() => {
  //   if (!loginInstance.hasLogin) {
  //     loginInstance.open();
  //   }
  // }, []);
  function updateOrderNo(orderNo) {
    setConstruct({ ...constuct, orderNo });
  }

  return (
    <div className={styles.construct}>
      <div className={styles.header}>
        {/* <Button
          shape="round"
          icon={<LeftOutlined style={{ fontSize: "12px" }} />}
          onClick={() => {
            nav("/", { replace: true });
          }}
        >
          返回
        </Button> */}
        <div
          onClick={() => {
            nav("/", { replace: true });
          }}
          className={'cursor-pointer'}
        >
          青秧灵感
        </div>
      </div>
      {showNew ? (
        <NewConstuct
          handleNew={(data) => {
            console.log("data", data);
            setConstruct(data);
            setShowNew(false);
            // const id = data.orderNo;
            // if (id) {
            //   window.history.replaceState(null, null, `/construct?id=${id}`);
            //            }
          }}
        />
      ) : (
        <EditResult paper={constuct} updateOrderNo={updateOrderNo} />
      )}
    </div>
  );
});
