import { Dropdown } from "antd";
import { useEffect, useState } from "react";
import styles from "./HeaderDropdown.module.scss";
import { DownOutlined } from "@ant-design/icons";

export function HeaderDropdown(props) {
  const HeaderMap = {
    1: "Heading 1",
    2: "Heading 2",
    false: "Normal",
  };
  const [fontSize, setFontSize] = useState("");
  useEffect(() => {
    const e = props.QuillRef.current.getEditor();
    const fontSize = e.getFormat()?.header;
    setFontSize(fontSize);
  }, [fontSize, props.QuillRef]);

  const HeaderItems = [
    {
      key: 1,
      label: (
        <div
          onClick={(e) => {
            props.onHeader(1);
            setFontSize(1);
          }}
          style={{ fontSize: "2em" }}
        >
          Heading1
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          onClick={(e) => {
            props.onHeader(2);
            setFontSize(2);
          }}
          style={{ fontSize: "1.5em" }}
        >
          Heading2
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div
          onClick={(e) => {
            props.onHeader(false);
            setFontSize(false);
          }}
          style={{ fontSize: "1em" }}
        >
          Normal
        </div>
      ),
    },
  ];
  return (
    <Dropdown
      menu={{ items: HeaderItems }}
      getPopupContainer={() => document.getElementById("toolbarHeader")}
    >
      <div className={styles.headerBarItem} id="toolbarHeader">
        <div style={{ display: "flex", alignItems: "center" }}>
          {HeaderMap[fontSize] || "Normal"}
          <DownOutlined style={{ marginLeft: "2px" }} />
        </div>
      </div>
    </Dropdown>
  );
}
