import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

export const useCountDown = (s) => {
  const [seconds, setSeconds] = useState(s);
  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
  }, [seconds]);

  return [seconds, setSeconds];
};

export function useNavigateWithCode() {
  const navigate = useNavigate();

  return function (to, options) {
    const { search } = options || {};
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get("code");
    if (code) {
      options = { ...options, state: { code } };
    }
    console.log("code", code);
    navigate(to, options);
  };
}

export const useOutsideClick = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      console.log("e", event.target);
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  return ref;
};
