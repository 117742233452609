import {
  Button,
  Form,
  Input,
  Select,
  Steps,
  Popover,
  ConfigProvider
} from 'antd'
import styles from './index.module.scss'
import { observer } from 'mobx-react-lite'
import { newPPTStore } from './newStore'
import TextArea from 'antd/es/input/TextArea'
import {
  CheckOutlined,
  DeleteOutlined,
  HolderOutlined,
  LeftOutlined,
  ReloadOutlined,
  RightOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined, PlusCircleOutlined
} from '@ant-design/icons'
import classNames from 'classnames'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useEffect, useState } from 'react'
import templateImg from '../../assets/template.png'
import { useForm } from 'antd/es/form/Form'
import { TEMPLATES } from './const'
import checked from '../../assets/checked.svg'
import { isMobile } from '../../common/const'

export const NewPPT = observer(function (props) {
  const [formRef] = useForm()
  useEffect(() => {
    newPPTStore.reset()
    newPPTStore.uid = props.uid
    newPPTStore.step1.title = props.title || ''
    if (props.writingRecordId) {
      newPPTStore.step1.writingRecordId = props.writingRecordId || ''
    }
    formRef.setFieldValue('title', newPPTStore.step1.title)
  }, [props, formRef])

  const createNew = async () => {
    try {
      await newPPTStore.step3Submit()
      props.handleNew({
        theme: newPPTStore.step3.templates[newPPTStore.step3.focusIdx].name,
        subTitle: newPPTStore.step2.subTitle,
        pptType: newPPTStore.pptTypes.find(
          (it) => it.value === newPPTStore.step1.type
        )
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handlePressEnter = (index1, index2, index3, e) => {
    if (e.nativeEvent.isComposing) {
      return
    }
    // 如果同时按下了 Shift 和 Enter 键
    if (e.shiftKey && e.key === 'Enter') {
      // 插入换行符
      return
    } else {
      e.preventDefault()
      newPPTStore.step2AddSub(index1, index2, index3)
      setTimeout(() => {
        let nextInput
        let curInput
        if (index3 === undefined) {
          nextInput = document.getElementById(`sub-${index1}-${index2 + 1}`)
          curInput = document.getElementById(`sub-${index1}-${index2}`)
        } else {
          nextInput = document.getElementById(
            `sub-${index1}-${index2}-${index3 + 1}`
          )
          curInput = document.getElementById(
            `sub-${index1}-${index2}-${index3}`
          )
        }
        if (nextInput) {
          nextInput.focus()
        } else if (curInput) {
          curInput.focus()
        }
      })
    }
  }

  const handleKeyDown = (i, iv, e) => {
    if (e.key === 'Backspace' && !e.target.value && iv > 0) {
      e.preventDefault()
      // 执行你想要的操作
      newPPTStore.step2DelSub(i, iv)
      setTimeout(() => {
        const preInput = document.getElementById(`sub-${i}-${iv - 1}`)
        const curInput = document.getElementById(`sub-${i}-${iv}`)
        if (preInput) {
          preInput.focus()
        } else if (curInput) {
          curInput.focus()
        }
      })
    }
  }

  const step1 = (
    <div className={styles.step1}>
      <ConfigProvider theme={{
        token: {
          colorBgContainer: '#F5F8F9'
        }
      }}>
        <Form
          form={formRef}
          initialValues={newPPTStore.step1}
          layout="vertical"
          className={styles.form}
          onValuesChange={(...e) => {
            newPPTStore.step1Change(...e)
          }}
        >
          <Form.Item required label={<div
            className={'font-medium text-base'}>标题</div>} name="title">
            <Input showCount maxLength={100} placeholder="标题"/>
          </Form.Item>
          <div style={{
            display: isMobile ? 'block' : 'flex',
            gap: isMobile ? '0' : '24px'
          }}>
            <Form.Item
              label={
                <div
                  className={'font-medium text-base'}>答辩类型</div>
              }
              name="type"
              required
              style={{ width: isMobile ? '100%' : '50%' }}
            >
              <Select placeholder="开题答辩" options={newPPTStore.pptTypes}/>
            </Form.Item>
            <Form.Item
              label={
                <div
                  className={'font-medium text-base'}>PPT备注</div>
              }
              name="isCardNote"
              style={{ width: isMobile ? '100%' : '50%' }}
              required
            >
              <Select
                placeholder="请选择是否生成PPT备注"
                options={[
                  { value: 0, label: '生成备注' },
                  { value: 1, label: '不生成备注' }
                ]}
              />
            </Form.Item>
          </div>
        </Form>
      </ConfigProvider>
      <div className={styles.paging}>
        <Button
          type="primary"
          style={{
            height: '48px'
          }}
          className={'w-[208px]'}
          disabled={!newPPTStore.step1.canSubmit}
          onClick={() => newPPTStore.step1Submit()}
          loading={newPPTStore.nextLoading}
        >
          下一步
        </Button>
      </div>
    </div>
  )

  useEffect(() => {
    // 这个不能删除
  }, [newPPTStore.step2.primarySections.length])
  const step2 = (
    <div className={'bg-white'}>
      <div className={styles.step2}>
        {/*<h4>创建PPT的大纲和节奏</h4>*/}
        <div className={styles.inner} style={{ marginBottom: '20px' }}>
          <div className={styles.flexTitle} style={{ marginBottom: '10px' }}>
            <div className={'flex'}>
              <span className={styles.label}>主标题</span>
              <Input
                bordered={false}
                maxLength={150}
                readOnly={newPPTStore.nextLoading}
                value={newPPTStore.step2.mainTitle}
              ></Input>
            </div>
          </div>
          {newPPTStore.step2.subTitle && (
            <div className={styles.flexTitle}>
              <div className={'flex'}>
                <span className={styles.label}>副标题</span>
                <Input
                  bordered={false}
                  maxLength={150}
                  readOnly={newPPTStore.nextLoading}
                  value={newPPTStore.step2.subTitle}
                ></Input>
              </div>
            </div>
          )}
        </div>
        <div className={styles.inner}>
          <DragDropContext onDragEnd={(e) => newPPTStore.step2DragEnd(e)}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {newPPTStore.step2.primarySections.map((item, idxFirst) => {
                    return (
                      <Draggable
                        key={`ppt-${item.id}`}
                        draggableId={item.id}
                        index={idxFirst}
                        isDragDisabled={newPPTStore.nextLoading}
                      >
                        {(provided, snapshot) => (
                          <div
                            data-key={`${item.id}`}
                            key={`children-${item.id}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                            className={classNames(styles.itemWrap, {
                              [styles.dragging]: snapshot.isDragging
                            })}
                          >
                            <HolderOutlined className={styles.icon}/>
                            <div className={styles.ss}>
                              <div className={styles.input}>
                                <TextArea
                                  value={item.title}
                                  bordered={false}
                                  autoSize
                                  readOnly={newPPTStore.nextLoading}
                                  style={{ fontSize: '18px' }}
                                  onChange={(e) =>
                                    newPPTStore.step2Change(
                                      idxFirst,
                                      e.target.value
                                    )
                                  }
                                  placeholder="请输入目录"
                                />
                                <div style={{ width: '30px' }}>
                                  <Button
                                    onClick={() => newPPTStore.step2Del(
                                      idxFirst)}
                                    danger
                                    disabled={newPPTStore.nextLoading}
                                    className={styles.ic}
                                    size="small"
                                    type="primary"
                                  >
                                    <DeleteOutlined/>
                                  </Button>
                                </div>
                              </div>
                              <ul className={styles.sh}>
                                {item.secondarySections &&
                                  item.secondarySections.map(
                                    (sh, idxSecond) => {
                                      return (
                                        <li key={`second-${idxSecond}`}>
                                          <div className={styles.shitem}>
                                            <Input
                                              id={`sub-${idxFirst}-${idxSecond}`}
                                              value={sh.title}
                                              bordered={false}
                                              maxLength={50}
                                              onChange={(e) =>
                                                newPPTStore.step2ChangeSub(
                                                  idxFirst,
                                                  idxSecond,
                                                  e.target.value
                                                )
                                              }
                                              onPressEnter={(e) =>
                                                handlePressEnter(
                                                  idxFirst,
                                                  idxSecond,
                                                  undefined,
                                                  e
                                                )
                                              }
                                              onKeyDown={(e) =>
                                                handleKeyDown(
                                                  idxFirst,
                                                  idxSecond,
                                                  e
                                                )
                                              }
                                              placeholder="请输入标题"
                                              readOnly={newPPTStore.nextLoading}
                                            />

                                            <div style={{ width: '30px' }}>
                                              <Button
                                                onClick={() =>
                                                  newPPTStore.step2DelSub(
                                                    idxFirst,
                                                    idxSecond
                                                  )
                                                }
                                                danger
                                                disabled={newPPTStore.nextLoading}
                                                className={styles.ic}
                                                size="small"
                                                type="text"
                                              >
                                                <DeleteOutlined/>
                                              </Button>
                                            </div>
                                          </div>

                                          {sh.tertiaryTitles && (
                                            <ul key={`second-li-${idxSecond}`}>
                                              {sh.tertiaryTitles.map(
                                                (third, idxThird) => {
                                                  return (
                                                    <li
                                                      className={styles.shitem}
                                                      key={`third-${idxThird}`}
                                                    >
                                                      <Input
                                                        id={`sub-${idxFirst}-${idxSecond}-${idxThird}`}
                                                        value={third}
                                                        bordered={false}
                                                        maxLength={50}
                                                        onChange={(e) =>
                                                          newPPTStore.step2ChangeThird(
                                                            idxFirst,
                                                            idxSecond,
                                                            idxThird,
                                                            e.target.value
                                                          )
                                                        }
                                                        onPressEnter={(e) =>
                                                          handlePressEnter(
                                                            idxFirst,
                                                            idxSecond,
                                                            idxThird,
                                                            e
                                                          )
                                                        }
                                                        onKeyDown={(e) =>
                                                          handleKeyDown(
                                                            idxFirst,
                                                            idxSecond,
                                                            e
                                                          )
                                                        }
                                                        placeholder="请输入标题"
                                                        readOnly={
                                                          newPPTStore.nextLoading
                                                        }
                                                      />

                                                      <div
                                                        style={{ width: '30px' }}
                                                      >
                                                        <Button
                                                          onClick={() =>
                                                            newPPTStore.step2DelSub(
                                                              idxFirst,
                                                              idxSecond,
                                                              idxThird
                                                            )
                                                          }
                                                          danger
                                                          disabled={
                                                            newPPTStore.nextLoading
                                                          }
                                                          className={styles.ic}
                                                          size="small"
                                                          type="text"
                                                        >
                                                          <DeleteOutlined/>
                                                        </Button>
                                                      </div>
                                                    </li>
                                                  )
                                                }
                                              )}
                                            </ul>
                                          )}
                                        </li>
                                      )
                                    })}
                              </ul>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className={'flex gap-4 justify-center'}>
            <Button
              className={styles.reload}
              disabled={newPPTStore.nextLoading}
              onClick={() => newPPTStore.step1Submit()}
              style={{ marginRight: '20px' }}
              loading={newPPTStore.nextLoading}
            >
              <ReloadOutlined className={'-translate-y-0.5 text-[#00CE72]'}/>
              <span className={'text-[#00CE72]'}>重新生成</span>
            </Button>
            <Button onClick={() => newPPTStore.step2Add()}>
              <PlusCircleOutlined
                className={'-translate-y-0.5 text-[#FFA408]'}/>
              <span className={'text-[#FFA408]'}>新增目录</span>
            </Button>
          </div>
        </div>
        <div className={styles.paging}>
          <Button
            disabled={newPPTStore.nextLoading}
            style={{
              height: '48px'
            }}
            className={'w-[208px]'}
            onClick={() => newPPTStore.goLast()}
          >
            上一步
          </Button>
          <Button
            style={{
              height: '48px'
            }}
            className={'w-[208px]'}
            type="primary"
            onClick={() => newPPTStore.step2Submit()}
            disabled={
              !(
                newPPTStore.step2.primarySections.length > 0 &&
                newPPTStore.step2.primarySections &&
                newPPTStore.step2.primarySections.some((it) => !!it.title)
              )
            }
            loading={newPPTStore.nextLoading}
          >
            下一步
          </Button>
        </div>
      </div>

    </div>
  )

  const [open, setOpen] = useState(false)

  const hide = (e) => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }

  const step3 = (
    <div className={styles.step3}>
      <div className={styles.title}>
        选择PPT模板
        <span className={styles.titleDesc}>（模板选中后不可更改）</span>
      </div>

      <div className={styles.templateBox}>
        {newPPTStore.step3.templates.map((item, idx) => {
          return (
            <div
              key={`first-outer-${idx}`}
              className={newPPTStore.step3.focusIdx === idx
                ? styles.checkTemplate
                : styles.template}
              onClick={() => newPPTStore.step3Focus(idx)}
            >
              <div className={styles.templateImg}>
                <img className={styles.img} src={TEMPLATES[item.name]}/>
              </div>
              <div className={styles.templateTitle}>{item.value}</div>
              {newPPTStore.step3.focusIdx === idx && (
                <div
                  className={styles.select}
                  onClick={(e) => newPPTStore.step3Blur(e)}
                >
                  <img className={'absolute right-2 bottom-[50px]  lg:right-3 lg:bottom-4'} src={checked}
                       alt=""/>
                  {/*<div className={styles.selectCircle}>*/}
                  {/*<CheckOutlined*/}
                  {/*  style={{ color: '#24be58', fontSize: '30px' }}*/}
                  {/*/>*/}
                  {/*</div>*/}
                </div>
              )}
            </div>
          )
        })}
      </div>

      <div className={styles.paging}>
        <Button
          disabled={newPPTStore.nextLoading}
          onClick={() => newPPTStore.goLast()}
          loading={newPPTStore.nextLoading}
          style={{
            height: '48px'
          }}
          className={'w-[208px]'}
        >
          上一步
        </Button>
        <Button
          disabled={newPPTStore.nextLoading || newPPTStore.step3.focusIdx < 0}
          onClick={createNew}
          type="primary"
          loading={newPPTStore.nextLoading}
          style={{
            height: '48px'
          }}
          className={'w-[208px]'}
        >
          生成PPT
        </Button>
      </div>
    </div>
  )
  const nowStep = [step1, step2, step3][newPPTStore.curStep]
  return (
    <div className={styles.new}>
      <div
        className={'text-2xl font-semibold  text-[#00CE72] mt-2 mb-2 lg:mt-0'}>PPT流程
      </div>
      <div
        className={'text-[#999] text-base'}>按照步骤轻松创作出答辩PPT
      </div>
      <div className={styles.step}>
        <div className={styles.progress}>
          <Steps
            current={newPPTStore.curStep}
            onChange={newPPTStore.onNewStepChange}
            className="site-navigation-steps"
            items={newPPTStore.steps}
          />
        </div>
        <div className={styles.content}>{nowStep}</div>
      </div>
    </div>

  )
})
