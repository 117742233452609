import { Dropdown } from "antd";
import { useEffect, useState } from "react";
import styles from "./AlignDropdown.module.scss";
import { DownOutlined } from "@ant-design/icons";

export function AlignDropdown(props) {
  const AlignMap = {
    left: (
      <svg viewBox="0 0 18 18">
        <line className={styles.stoke} x1="3" x2="15" y1="9" y2="9"></line>{" "}
        <line className={styles.stoke} x1="3" x2="13" y1="14" y2="14"></line>{" "}
        <line className={styles.stoke} x1="3" x2="9" y1="4" y2="4"></line>{" "}
      </svg>
    ),
    center: (
      <svg viewBox="0 0 18 18">
        <line className={styles.stoke} x1="15" x2="3" y1="9" y2="9"></line>{" "}
        <line className={styles.stoke} x1="14" x2="4" y1="14" y2="14"></line>{" "}
        <line className={styles.stoke} x1="12" x2="6" y1="4" y2="4"></line>{" "}
      </svg>
    ),
    right: (
      <svg viewBox="0 0 18 18">
        <line className={styles.stoke} x1="15" x2="3" y1="9" y2="9"></line>{" "}
        <line className={styles.stoke} x1="15" x2="5" y1="14" y2="14"></line>{" "}
        <line className={styles.stoke} x1="15" x2="9" y1="4" y2="4"></line>{" "}
      </svg>
    ),
    between: (
      <svg viewBox="0 0 18 18">
        <line className={styles.stoke} x1="15" x2="3" y1="9" y2="9"></line>{" "}
        <line className={styles.stoke} x1="15" x2="3" y1="14" y2="14"></line>{" "}
        <line className={styles.stoke} x1="15" x2="3" y1="4" y2="4"></line>{" "}
      </svg>
    ),
  };
  const [align, setAlign] = useState("");
  useEffect(() => {
    const e = props.QuillRef.current.getEditor();
    const align = e.getFormat()?.align;
    setAlign(align);
  }, [align, props.QuillRef]);

  const setEditorAlign = (value) => {
    const e = props.QuillRef.current.getEditor();
    setAlign(value);
    e.format("align", value);
  };

  const AlignItems = [
    {
      key: 1,
      label: (
        <div
          className={styles.iconBox}
          onClick={(e) => {
            setEditorAlign("left");
          }}
        >
          {AlignMap.left}
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          className={styles.iconBox}
          onClick={(e) => {
            setEditorAlign("center");
          }}
        >
          {AlignMap.center}
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div
          className={styles.iconBox}
          onClick={(e) => {
            setEditorAlign("right");
          }}
        >
          {AlignMap.right}
        </div>
      ),
    },
    {
      key: 4,
      label: (
        <div
          className={styles.iconBox}
          onClick={(e) => {
            setEditorAlign("justify");
          }}
        >
          {AlignMap.between}
        </div>
      ),
    },
  ];
  return (
    <Dropdown
      menu={{ items: AlignItems }}
      placement="bottom"
      getPopupContainer={() => document.getElementById("alignHeader")}
    >
      <div className={styles.headerBarItem} id="alignHeader">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "18px",
            height: "18px",
          }}
        >
          {AlignMap[align] || AlignMap.left}
        </div>
      </div>
    </Dropdown>
  );
}
