import { Modal, ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import { LoginForm } from "./LoginForm";
import {
  legacyLogicalPropertiesTransformer,
  StyleProvider,
} from "@ant-design/cssinjs";
export function login() {
  const div = document.createElement("div");
  document.body.appendChild(div);
  const root = createRoot(div);
  console.log("root", root);

  function remove() {
    root.unmount();
    div.parentNode.removeChild(div);
  }

  return new Promise((resolve, reject) => {
    const handleOk = (result) => {
      // submit to server success
      remove();
      resolve(result);
    };

    const handleCancel = () => {
      remove();
      reject();
    };

    root.render(
      <StyleProvider
        hashPriority="high"
        transformers={[legacyLogicalPropertiesTransformer]}
      >
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#f7941d",
              colorInfoBg: "#fffbe6",
            },
          }}
        >
          <Modal
            open={true}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            bodyStyle={{ padding: "0px" }}
            className="login-modal"
          >
            <LoginForm onOk={handleOk} onCancel={handleCancel} />
          </Modal>
        </ConfigProvider>
      </StyleProvider>
    );
  });
}
