import styles from "./RecommendContent.module.scss";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import { post } from "../../axios/axios";
export function RecommendContent(props) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");

  const handleSearch = async () => {
    setLoading(true);
    const result = await post("ai/select/menu/c", {
      text: props.selectText,
    });
    setResult(result.data);
    setLoading(false);
  };

  useEffect(() => {
    handleSearch();
  }, []);
  return (
    <div className={styles.recommendContentBox}>
      <div className={styles.titleBox}>
        <div className={styles.title}>
          <span>内容建议</span>
          <span className={styles.underline}></span>
        </div>
        <div onClick={props.closeRecommendContent}>
          <CloseOutlined
            className={styles.close}
            style={{ fontSize: "16px" }}
          />
        </div>
      </div>
      <Spin spinning={loading}>
        <div className={styles.result}>
          <div className={styles.resultContent}>{result}</div>
        </div>
      </Spin>
    </div>
  );
}
