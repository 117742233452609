import { Modal, QRCode, Button, Alert, message, Input, Spin } from "antd";
import { useState, useEffect, useRef } from "react";
import error from "../../assets/error.png";
import success from "../../assets/success.png";
import { post, get } from "../../axios/axios";
import { globalData } from "../../Service/utils";

// orderNo price
export const BuyModal = (props) => {
  const [payResult, setPayResult] = useState(false);
  const payInfoRef = useRef({});
  const [showPayResult, setShowPayResult] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    submitPay();
  }, []);

  useEffect(() => {
    globalData.buyModalDestoryed = false;
    return () => {
      globalData.timerId && window.clearTimeout(globalData.timerId);
      globalData.buyModalDestoryed = true;
    };
  }, []);

  const submitPay = async () => {
    try {
      if (loading) {
        return;
      }
      const isMobile = false;
      const isWexin = false;
      if (isWexin && isMobile) {
        const res = await get(`/member/wechat/a/url?orderNo=${props.orderNo}`);
        window.location.href = res;
        return;
      }

      setLoading(true);
      const client = isMobile ? "H5" : "PC";
      const res = await post("/member/payment/pay", {
        orderNo: props.orderNo,
        client: client,
      });

      if (client === "H5") {
        window.location.href = res.data.mwebUrl;
      } else {
        payInfoRef.current = {
          ...(res.data || {}),
        };
        checkPay();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const checkPay = async () => {
    globalData.timerId && window.clearTimeout(globalData.timerId);

    try {
      const res = await get("/member/payment/pay/result/" + props.orderNo);
      console.log("1", res);
      if (res && res.data && res.data.code === 0) {
        setShowPayResult(true);
        setPayResult(res.data.code === 0);

        setTimeout(() => {
          if (res.data.code === 0) {
            globalData.timerId && window.clearTimeout(globalData.timerId);
            props.handleOk && props.handleOk(1);
          }
        }, 1000);
      } else {
        const id = window.setTimeout(() => {
          checkPay();
        }, 1000);
        globalData.timerId = id;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const toRePay = async () => {
    setShowPayResult(false);
    const res = await post("/member/payment/pay", {
      orderNo: props.orderNo,
      client: "PC",
    });

    payInfoRef.current = res.data || {};
  };

  function onCancel() {
    globalData.timerId && window.clearTimeout(globalData.timerId);
    payInfoRef.current = {};
  }

  return showPayResult ? (
    <div className="pay-result">
      <div
        className="result"
        style={payResult ? { padding: "16px 0 20px 0" } : {}}
      >
        <p className="tips">
          {payResult ? "恭喜您，支付成功" : "很遗憾，支付失败"}
        </p>
        <img
          src={payResult ? success : error}
          className="image"
          style={payResult ? { margin: "16px 0 20px 0" } : {}}
        />
        {!payResult ? (
          <Button className="action_btn" onClick={toRePay}>
            <span>重新支付</span>
          </Button>
        ) : (
          <Button onClick={onCancel}>
            <span>完成</span>
          </Button>
        )}
      </div>
    </div>
  ) : (
    <div className="w-pay">
      <Alert
        message="付款成功前请不要关闭此页面。如付款后页面没有更新，请根据您的情况点击下方按钮"
        type="warning"
        showIcon
        className="w-pay-alert"
      />
      <div className="title">请使用微信扫一扫</div>
      <p className="order">订单号：{props.orderNo}</p>
      <div className="qr-code">
        {payInfoRef.current.codeUrl ? (
          <QRCode value={payInfoRef.current.codeUrl} />
        ) : null}
      </div>
      <p className="cost-tips">
        支付金额 <span className="unit">¥</span>
        <span className="value">{props.price || ""}</span>
      </p>
      <div className="button-group">
        <Button type="primary" onClick={checkPay}>
          <span>付款成功</span>
        </Button>
      </div>
      <p className="tips">如遇支付问题，请联系客服微信</p>
    </div>
  );
};
