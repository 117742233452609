import { Button, Form, Input, Select, message } from "antd";
import { get, post } from "../../../axios/axios";
import { useEffect, useState } from "react";
import styles from "./EmailForm.module.scss";
import { Loading3QuartersOutlined } from "@ant-design/icons";
export function EmailForm(props) {
  const [qrcode, setQRCode] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [timerId, setTimerId] = useState("");
  const [expired, setExpired] = useState(false);
  useEffect(() => {
    return () => {
      if (timerId) {
        window.clearTimeout(timerId);
      }
    };
  }, []);
  const getQRCode = async () => {
    const { data, uid } = await get("member/weixin/qr");
    setQRCode("data:image/png;base64," + data);
    queryLoginStatus(uid);
  };

  const queryLoginStatus = async (uid) => {
    try {
      if (timerId) {
        window.clearTimeout(timerId);
      }
      const { data, code } = await get("/member/weixin/query/code", {
        uid,
      });
      console.log("data", data);
      // 二维码失效
      if (code === 1) {
        setExpired(true);
        return;
      }

      // 登录成功
      if (data.status === 0) {
        submitLogin(data.userCode);
        return;
      }

      const id = window.setTimeout(() => {
        queryLoginStatus(uid);
      }, 1000);
      setTimerId(id);
    } catch (err) {
      setExpired(true);
    }
  };

  const submitLogin = async (code) => {
    const res = await post(
      "/auth/oauth2/token",
      {},
      {
        params: {
          code: code,
          grant_type: "wechat",
          scope: "server",
          client: "wechat",
        },
        headers: {
          Authorization: "Basic " + window.btoa("wechat:wechat"),
        },
      }
    );

    messageApi.success("登录成功");
    props.onOk(res);
  };

  useEffect(() => {
    getQRCode();
  }, []);
  return (
    // <Form className="custom-form">
    //   <Form.Item
    //     label=""
    //     name="tel"
    //     rules={[{ required: true, message: "请输入您的手机号" }]}
    //   >
    //     <Input
    //       className="custom-input"
    //       placeholder="请输入您的邮箱"
    //       suffix={<span></span>}
    //     />
    //   </Form.Item>
    //   <Form.Item
    //     label=""
    //     name="code"
    //     rules={[{ required: true, message: "请输入正确的验证码" }]}
    //   >
    //     <>
    //       <Input.Password
    //         className="custom-input"
    //         placeholder="请输入密码"
    //         type="password"
    //         suffix={<span></span>}
    //       />
    //       <div onClick={props.toForgetPassword}>忘记密码</div>
    //     </>
    //   </Form.Item>
    //   <Button className="custom-btn" disabled={true}>
    //     登录
    //   </Button>
    // </Form>
    <>
      {contextHolder}
      <div className={styles.qrcodeBox}>
        {qrcode && <img src={qrcode} className={styles.qrcodeImg}></img>}
        {expired && (
          <div className={styles.expiredBox}>
            <Loading3QuartersOutlined
              style={{ fontSize: "120px", color: "#999", marginBottom: "10px" }}
            />
            <span>二维码已失效，请刷新重试</span>
          </div>
        )}
        {/* <iframe
        title="qrcode"
        className={styles.qrCodeIframe}
        sandbox="allow-scripts allow-top-navigation"
        src={qrcode}
      /> */}
      </div>
    </>
  );
}
