import zhiwangaigc1 from "./assets/zhiwang_aigc_guide1.png";
import zhiwangaigc2 from "./assets/zhiwang_aigc_guide2.png";
import zhiwangaigc3 from "./assets/zhiwang_aigc_guide3.png";
import wpaigc1 from "./assets/wpaigc_guide1.png";
import wpaigc2 from "./assets/wpaigc_guide2.png";
import wpaigc3 from "./assets/wpaigc_guide3.png";
import aigcgezida1 from "./assets/aigcgezida1.png";
import aigcgezida2 from "./assets/aigcgezida2.png";
import aigcgezida3 from "./assets/aigcgezida3.png";

import zwchachongGuide1 from "./assets/zwchachongGuide1.png";
import zwchachongGuide2 from "./assets/zwchachongGuide2.png";
import zwchachongGuide3 from "./assets/zwchachongGuide3.png";
import wpchachong1 from "./assets/wp_chachongGuide1.png";
import wpchachong2 from "./assets/wp_chachongGuide2.png";
import wpchachong3 from "./assets/wp_chachongGuide3.png";

import wfcc1 from "./assets/wf_chachongGuide1.png";
import wfcc2 from "./assets/wf_chachongGuide2.png";
import wfcc3 from "./assets/wf_chachongGuide3.png";

import okcc1 from "./assets/ok_chachongGuide1.png";
import okcc2 from "./assets/ok_chachongGuide2.png";
import okcc3 from "./assets/ok_chachongGuide3.png";
import rightcc1 from "./assets/right_chachongGuide1.png";
import rightcc2 from "./assets/right_chachongGuide2.png";
import rightcc3 from "./assets/right_chachongGuide3.png";

import gzdcc1 from "./assets/gzdcc1.jpg";
import gzdcc2 from "./assets/gzdcc2.jpg";
import gzdcc3 from "./assets/gzdcc3.jpg";

export const AIGCLIST = [
  {
    btn: "知Wang",
    title: "【知Wang】AIGC报告怎么传？",
    imgs: [
      {
        step: "选择AIGC检测",
        src: zhiwangaigc1,
      },
      {
        step: "下载“全文报告单”",
        src: zhiwangaigc2,
      },
      {
        step: "选择《全文报告单-论文标题.pdf》进行上传",
        src: zhiwangaigc3,
      },
    ],
  },
  {
    btn: "维Pu",
    title: "【维Pu】AIGC报告怎么传？",
    imgs: [
      {
        step: "选择AIGC检测",
        src: wpaigc1,
      },
      {
        step: "点击“下载报告”",
        src: wpaigc2,
      },
      {
        step: "选择《论文标题.html》进行上传",
        src: wpaigc3,
      },
    ],
  },
  {
    btn: "格子Da",
    title: "【格子Da】AIGC报告怎么传？",
    imgs: [
      {
        step: "选择AIGC检测",
        src: aigcgezida1,
      },
      {
        step: "点击“下载报告”",
        src: aigcgezida2,
      },
      {
        step: "选择《gzd_AIGC检测报告.pdf》进行上传",
        src: aigcgezida3,
      },
    ],
  },
];

export const LUNWEN_LIST = [
  {
    btn: "知Wang",
    title: "【知Wang】论文查重报告怎么传？",
    imgs: [
      {
        step: "选择查重检测",
        src: zwchachongGuide1,
      },
      {
        step: "下载“全文报告”",
        src: zwchachongGuide2,
      },
      {
        step: "选择《全文报告单-论文标题.pdf》进行上传",
        src: zwchachongGuide3,
      },
    ],
  },
  {
    btn: "维Pu",
    title: "【维Pu】论文查重报告怎么传？",
    imgs: [
      {
        step: "选择论文检测",
        src: wpchachong1,
      },
      {
        step: "点击“下载报告”",
        src: wpchachong2,
      },
      {
        step: "选择《论文标题_对比报告.html》进行上传",
        src: wpchachong3,
      },
    ],
  },
  {
    btn: "万Fang",
    title: "【万Fang】论文查重报告怎么传？",
    imgs: [
      {
        step: "选择个人用户文献检测",
        src: wfcc1,
      },
      {
        step: "点击“下载报告”，下载后并解压",
        src: wfcc2,
      },
      {
        step: "选择《论文标题（原文标注报告）.pdf》进行上传",
        src: wfcc3,
      },
    ],
  },
  {
    btn: "格子Da",
    title: "【格子Da】论文查重报告怎么传？",
    imgs: [
      {
        step: "选择查看检测报告",
        src: gzdcc1,
      },
      {
        step: "点击“下载”",
        src: gzdcc2,
      },
      {
        step: "选择《检测报告》进行上传",
        src: gzdcc3,
      },
    ],
  },
  // {
  //   btn: "PaperOK",
  //   title: "【万Fang】论文查重报告怎么传？",
  //   imgs: [
  //     {
  //       step: "选择查看报告",
  //       src: okcc1,
  //     },
  //     {
  //       step: "点击“下载”",
  //       src: okcc2,
  //     },
  //     {
  //       step: "选择《PaperOK_创建时间.zip》进行上传",
  //       src: okcc3,
  //     },
  //   ],
  // },
  // {
  //   btn: "PaperRight",
  //   title: "【PaperRight】论文查重报告怎么传？",
  //   imgs: [
  //     {
  //       step: "选择查看报告",
  //       src: rightcc1,
  //     },
  //     {
  //       step: "点击“下载”",
  //       src: rightcc2,
  //     },
  //     {
  //       step: "选择《PaperRight_创建时间.zip》进行上传",
  //       src: rightcc3,
  //     },
  //   ],
  // },
];
