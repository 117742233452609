import { baseURL } from '../../axios/axios'
import styles from './index.module.scss'
import {
  ExclamationCircleFilled
} from '@ant-design/icons'
import {
  Button,
  Upload,
  message,
  Modal,
  Image, Steps
} from 'antd'
import cx from 'classnames'
import { reduceStore } from './store'
import { observer } from 'mobx-react-lite'
import { QueryOrder } from '../Buy/QueryOrder'
import { useNavigate, useSearchParams } from 'react-router-dom'
import upload from './assets/upload.png'
import word from './assets/word.png'
import aigc from './assets/aigc.png'
import search from './assets/search.png'
import success from './assets/success.png'
import { AIGCLIST, LUNWEN_LIST } from './const'
import helpBaogao from '../../assets/helpBaogao.png'
import helpChachong from '../../assets/helpChachong.png'
import helpJiangchong from '../../assets/helpJiangchong.png'
import { isMobile } from '../../common/const'

const { confirm } = Modal
const { Dragger } = Upload
export const Step1 = observer(() => {
  const nav = useNavigate()
  const props = {
    accept: '.pdf,.doc,.docx,.html',
    name: 'file',
    multiple: false,
    maxCount: 1,
    action: baseURL + '/ai/ai/jc/upload',
    data () {
      return {}
    },
    onChange (info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
        reduceStore.onStep1FileChange(info.file)
      }
      if (status === 'done') {
      } else if (status === 'error') {
        message.error(`${info.file.response.msg}`)
      }
    },
    beforeUpload () {
      return new Promise((resolve, reject) => {
        confirm({
          className: styles.uploadConfirm,
          title: '确认上传文档',
          icon: <ExclamationCircleFilled/>,
          okButtonProps: {
            className: 'bg-[#00CE72]  text-white'
          },
          content:
            '解析此文档可能需要访问您的文档内容，我们将严格保障每一个用户文件的安全性，绝不会泄露、篡改或滥用您的文件，您确定要授权此操作吗？',
          onOk () {
            resolve()
          },
          onCancel () {
            resolve(Upload.LIST_IGNORE)
          }
        })
      })
    },
    onDrop (e) {
      console.log('Dropped files', e.dataTransfer.files)
    }
  }

  function showHelpDownload (data) {
    Modal.info({
      title: data.title,
      footer: null,
      closable: true,
      maskClosable: true,
      width: 1115,
      content: (
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
        >
          {data.imgs.map((d, idx) => {
            return (
              <div
                key={idx}
                style={{
                  fontWeight: 500,
                  textAlign: 'center',
                  marginRight: '15px'
                }}
              >
                <Image src={d.src} height={239}/>
                <div>
                  【{idx + 1}】{d.step}
                </div>
              </div>
            )
          })}
        </div>
      )
    })
  }

  function resetUpload () {
    reduceStore.onStep1FileChange('')
  }

  function onQuery () {
    QueryOrder({
      confirm: (orderNo) => {
        return new Promise((resolve, reject) => {
          if (!orderNo) {
            message.info('请输入订单号')
            reject()
            return
          }
          reduceStore.onStep3GetOrderInfo({ orderNo }).then(() => {
            resolve()
            window.open(`${window.location.href}?o=${orderNo}`)
          })
        })
      }
    })
  }

  return (
    <div className={'w-full lg:w-full lg:mx-auto'}>
      {/*banner部分*/}
      <div className={'flex flex-col lg:flex-row lg:gap-4 lg:h-[524px]  lg:justify-between'}>
        {/*左侧*/}
        <div className={'p-6 rounded-[12px] w-full bg-white mb-4 lg:flex-1 lg:bg-white lg:h-full lg:mb-0'}>
          <div className={'scale-75 -translate-x-5 lg:-translate-x-0 lg:scale-100 lg:mb-6'} >
            <Steps
              current={reduceStore.curStep}
              onChange={reduceStore.onNewStepChange}
              className={'site-navigation-steps'}
              items={reduceStore.steps}
              direction="horizontal"
              style={{
                flexDirection:isMobile ? 'row' : 'initial'
              }}
            />
          </div>
          {reduceStore.step1.file ? (
            <div className={styles.hasFile}>
              <img src={success} className={styles.successImg}/>
              <div className={styles.successTip}>上传成功</div>
              <div className={styles.successTip}>
                {reduceStore.step1.file.name}
              </div>
              <div className={styles.successBtns}>
                <Button
                  onClick={resetUpload}
                  size="large"
                  className={styles.leftBtn}
                >
                  重新上传
                </Button>
                <Button
                  onClick={() => reduceStore.toStep2(reduceStore.step1.file)}
                  type="primary"
                  size="large"
                >
                  确认上传
                </Button>
              </div>
            </div>
          ) : (
            <div className={'lg:h-[320px]'}>
              <Dragger {...props} >
                <>
                  <div className={'flex justify-center'}>
                    <img src={upload}/>
                  </div>
                  <div
                    className={'mt-2 text-[#333] text-base mb-2 lg:mb-8'}>点击上传或拖拽文件到此处上传
                  </div>
                  <div
                    className={'flex flex-col px-8 gap-3 lg:flex-row lg:justify-between'}>
                    <div
                      className={'w-[235px] h-[65px] flex items-center bg-white p-2 lg:p-4 rounded-[8px] lg:flex-1  lg:h-[88px]'}>
                      <img className={'w-8 h-8 mr-2'} src={word}/>
                      <div>
                        <div className={'text-start'}>论文原稿</div>
                        <div
                          className={'text-xs text-[#999] text-start'}>.docx
                        </div>
                      </div>
                    </div>
                    <div
                      className={'w-[235px]  h-[65px] flex items-center bg-white p-2 lg:p-4 rounded-[8px] lg:flex-1  lg:h-[88px]'}>
                      <img className={'w-8 h-8 mr-2'} src={aigc}/>
                      <div>
                        <div className={'text-start'}>AIGC检测报告</div>
                        <div
                          className={'text-xs text-[#999] text-start'}>知网、维P
                        </div>
                      </div>
                    </div>
                    <div
                      className={'w-[235px]  h-[65px] flex items-center bg-white p-2 lg:p-4 rounded-[8px] lg:flex-1 lg:h-[88px]'}>
                      <img className={'w-8 h-8 mr-2'} src={search}/>
                      <div>
                        <div className={'text-start'}>论文原稿</div>
                        <div className={'text-xs text-[#999] text-start'}>
                          知W、维P、万F、PaperOK、PaperRight
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Dragger>
            </div>
          )}
          <div className={'text-center text-[#999] text-sm mt-4'}>
            <div className={'mt-12'}>
              青秧严格遵守保密规定，不会收录、泄露您的任何信息，您提交的信息可随时删除，请放心使用。
            </div>
          </div>
        </div>
        {/*右侧*/}
        <div className={cx(styles.card, styles.helpBox)}>
          <div
            className={` text-[#00CE72] text-[18px] pb-3 border-b-[1px] font-bold `}>帮助中心
          </div>
          <div className={styles.tip} style={{
            paddingTop: '16px'
          }}>
            <div className={'flex gap-1'}>
              <img className={'w-4 h-4'} src={helpBaogao} alt=""/>
              <span className={'text-[14px] text-[#333]'}>
                AI报告怎么传？
              </span>
            </div>
          </div>
          <div className={styles.btnBox}>
            {AIGCLIST.map((it, idx) => (
              <Button
                key={idx}
                className={styles.btn}
                onClick={() => showHelpDownload(it)}
              >
                {it.btn}
              </Button>
            ))}
          </div>
          <div className={styles.tip} style={{ marginTop: '20px' }}>
            <div className={'flex gap-1'}>
              <img className={'w-4 h-4'} src={helpChachong} alt=""/>
              <span className={'text-[14px] text-[#333]'}>
                论文查重报告怎么传?
              </span>
            </div>
          </div>
          <div className={styles.btnBox}>
            {LUNWEN_LIST.map((it, idx) => (
              <Button
                key={idx}
                className={styles.btn}
                onClick={() => showHelpDownload(it)}
              >
                {it.btn}
              </Button>
            ))}
          </div>
          <div className={styles.tip} style={{ marginTop: '20px' }}>
            <div className={'flex gap-1'}>
              <img className={'w-4 h-4'} src={helpJiangchong} alt=""/>
              <span className={'text-[14px] text-[#333]'}>
              查询降重记录
            </span>
            </div>
          </div>
          <div className={styles.btnBox}>
            <Button className={styles.btn} onClick={onQuery}>
              查询降重记录
            </Button>
          </div>
        </div>
      </div>
      {/*案例展示部分*/}
      <div className={cx(styles.card, styles.secondRow)}>
        <div className={styles.title}>案例展示</div>
        <div className={styles.subtitle}>
          案例一：高性能混凝土的工程应用与性能评估
        </div>
        <div className={styles.anli}>
          <div className={styles.anliItem}>
            <Image
              height={333}
              src="https://modeai.oss-cn-hangzhou.aliyuncs.com/img1.png"
            />
            <div className={styles.anliTitle}>青秧去AI痕迹</div>
          </div>
          <div className={styles.anliItem}>
            <Image
              height={333}
              src="https://modeai.oss-cn-hangzhou.aliyuncs.com/img2.png"
            />
            <div className={styles.anliTitle}>【知W】AICG检测结果</div>
          </div>
        </div>

        <div className={styles.subtitle}>案例二：一种智能车定位系统设计</div>
        <div className={styles.anli}>
          <div className={styles.anliItem}>
            <Image
              height={333}
              src="https://modeai.oss-cn-hangzhou.aliyuncs.com/img3.png"
            />
            <div className={styles.anliTitle}>青秧去AI痕迹</div>
          </div>
          <div className={styles.anliItem}>
            <Image
              height={333}
              src="https://modeai.oss-cn-hangzhou.aliyuncs.com/img4.png"
            />
            <div className={styles.anliTitle}>【知W】AICG检测结果</div>
          </div>
        </div>
      </div>
    </div>
  )
})
